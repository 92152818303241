import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import { useEffect } from 'react';
import { useState } from 'react';



const LineChart = ({heading, route, type}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    
    const [resData, setResData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [chartData, setChartData] = useState({
        labels:[],
        datasets: [
            {
                label: 'Active Customer 1',
                data: [],
                borderColor: '#BED4CA',
                backgroundColor: '#27704D',
            },
        ],
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
            },
        },
    };

    function getMonths(){
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let today = new Date();
        let d;
        let month=[];

        for(var i = 5; i >= 0; i -= 1) {
            let m = today.getMonth()
            d = new Date(today.getFullYear(), m - i, 1);
            month.push(monthNames[d.getMonth()]);
        }
        return month
    }

    async function getData() {
        common.loader(true);
        await authAxios({
            method: "GET",
            url:route ,
        }).then((res) => {
            let resData = res?.data?.data
            setResData(resData || [])
            let cData = chartData
            cData.labels = getMonths()
            let datasets = []
            datasets.push(resData?.monthSixTotal || 0)
            datasets.push(resData?.monthFiveTotal || 0)
            datasets.push(resData?.monthFourTotal || 0)
            datasets.push(resData?.monthThreeTotal || 0)
            datasets.push(resData?.monthTwoTotal || 0)
            datasets.push(resData?.monthOneTotal || 0)

            cData.datasets[0].data = datasets

            setChartData(cData)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error);
            // common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        route && getData()
    }, [route]);
    

  return (
    <>
        <div className='chartlinewrp pt-4 pb-4'>
            <div className="chartlineconwrp">
                <div className="linecharttxt no">No. of Active Vendor</div>
                <div className="chartlinecon">
                {isLoading ? 'Loading' : <Line options={options} data={chartData} />}
                    <div className="linecharttxt">Months</div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LineChart