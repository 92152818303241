import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import ConfirmModal from '../../components/modals/confirmModal';
import authAxios from '../../services/authAxios';
import common from '../../services/common';


export default function UserList() {
    const [resData, setResData] = useState([]);

    async function getData() {
        common.loader(true);
        await authAxios({
            method: "GET",
            url: `/c/employee/employeeList`,
        }).then((res) => {
            setResData(res?.data?.data || {})
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <>
            <div className="userlistmainwrp">

                <div className="dashheadercon mycarthd">
                    <div className="dashheadtext "><h1>User List</h1></div>
                    <div className="dashstorelink ml-3"><Link to='/create-user'>Add User</Link></div>

                </div>

                <div className="prolisttableWRP">
                    <table className="table">
                        <thead className="prolistthead">
                            <tr>
                                <th className="align-middle">  Employee Name </th>
                                <th className="align-middle">Role Type</th>
                                <th className="align-middle">Email</th>
                                <th className="align-middle">Mobile Number</th>
                                <th className="align-middle">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resData?.map((d,k)=>(
                                <tr key={k}>
                                    <td className="align-middle activename" data-th="Employee Name  -">{d?.name}</td>
                                    <td className="align-middle" data-th="Role Type  -">
                                        {d?.role ==='dr' && 'Director'}
                                        {d?.role ==='sm' && 'Store Manager'}
                                        {d?.role ==='rm' && 'Relationship Manager'}
                                    </td>
                                    <td className="align-middle" data-th="Email  -">{d?.email}</td>
                                    <td className="align-middle" data-th="Mobile No  -">{d?.phone}</td>
                                    <td className="align-middle" data-th="Action  -"> <div className="actionimg">
                                        <Link to={`/edit-user/${d?._id}`}> <img className='img-fluid' src="/assets/images/edit.svg" alt="image" /></Link>
                                        <ConfirmModal 
                                            title="Are you sure ?"
                                            method="GET"
                                            renderHtml={<img className='img-fluid' src="/assets/images/delete.svg" alt="image" />} 
                                            url={`/c/employee/delete/${d?._id}`} 
                                            action={()=>getData()} 
                                            submitBtnName="Confirm"
                                        />
                                    </div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>
            </div>

        </>
    )
}

