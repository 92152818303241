
import React, { Suspense, useContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/loader/loader';

import SignIn from '../components/login/SignIn';
import SignUp from '../components/login/signUp';
import VerifyOTP from '../components/login/VerifyOTP';
import NotFound from '../components/notFound/notFound';
import { UserContext } from '../context/theme';
import AboutUs from '../pages/aboutUs';
import ContactUs from '../pages/contactUs';
import Home from '../pages/customerTheme/home';
import ViewStore from '../pages/dashboard/viewStore';
import Faq from '../pages/faq';
import FaqFooter from '../pages/faqFooter';
import PrivacyPolicy from '../pages/privacyPolicy';
import TermCondition from '../pages/termCondition';
import FooterUnAuth from './footerUnAuth';
import HeaderUnAuth from './headerUnAuth';

function UnAuth() {
  const context = useContext(UserContext)
  
  return (
    <>
      <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} pageLogin`}>
        <div className="login-wrapper d-flex">
          <div className="saledeskWRP">
          <Loader />
            <ToastContainer />
            {/* <div className="all-content-wrapper"> */}
            {/* <TopNavbar/>  */}
            <HeaderUnAuth />
            <Suspense fallback={'loading'}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/verifyOTP" element={<VerifyOTP />} />
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/terms-conditions" element={<TermCondition />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faqs" element={<Faq />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
            <FooterUnAuth />
          </div>
        </div>
      </div>
      {/* </div> */}
    </>

  );
}

export default UnAuth;