import React, { useState } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';
import authAxios from '../../services/authAxios';


export default function ActiveCustomerFilter({navigatePath, resetPath}) {
    // const [startDate, setStartDate] = useState();
    // const [endDate, setEndDate] = useState();
    const [filter, setFilter] = useState(false)
    const [resDataNOB, setResDataNOB] = useState([])
    const [resDataCity, setResDataCity] = useState([])
    const [resDataPC, setResDataPC] = useState([])
    const navigate = useNavigate()
    const history = useLocation()

    const formik = useFormik({
        initialValues: {
            natureOfBusiness: "",
            primaryCategory: "",
            city: "",

        },
        validationSchema: Yup.object({
            
        }),

        onSubmit: values => {
            let urlValues = common.getActiveFilter();
            urlValues.page = 1
            urlValues.natureOfBusiness = values.natureOfBusiness  || ''
            urlValues.primaryCategory = values.primaryCategory  || ''
            urlValues.city = values.city  || ''
            const queryString = new URLSearchParams(urlValues).toString();
            setFilter(false)
            navigate(`/${navigatePath}?` + queryString)
        },
    });

    function loadNatureOfBusiness() {
        UnauthAxios({
            method: "GET",
            url: `/c/dropdowns/natureOfBusiness`,
        }).then((res) => {
            setResDataNOB(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    function loadPrimaryCategory() {
        authAxios({
            method: "GET",
            url: `/c/dropdowns/primaryCategory`,
        }).then((res) => {
            setResDataPC(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    function loadCity() {
        authAxios({
            method: "GET",
            url: `/c/manageVendors/city`,
        }).then((res) => {
            setResDataCity(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    useEffect(()=>{
        let urlValues = common.getActiveFilter();
        formik.setFieldValue('natureOfBusiness', urlValues?.natureOfBusiness || '')
        formik.setFieldValue('city', urlValues?.city || '')
	},[history])
    
    useEffect(()=>{
        loadNatureOfBusiness()
        loadPrimaryCategory();
        loadCity()
	},[])



    return (
        <>
            <div className="filterwrpprolist">
                <ul>
                    <li className={filter  ? "active" : ""}>
                    <div onClick={() =>setFilter(false)} className='filtebkbox'></div>
                        {filter ? (
                            <div className="prolistnav ml-lg-3 ml-md-2 ml-sm-2 ml-2" onClick={() => setFilter(false)}><img  className=' img-fluid ' src="assets/images/listmenu.svg" alt="image" /></div>

                        ) : (
                            <div className="prolistnav ml-lg-3 ml-md-2 ml-sm-2 ml-2" onClick={() => setFilter(true)}><img  className=' img-fluid ' src="assets/images/filterchange.svg" alt="image" /></div>

                        )}                        <div className='filterprolist'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="categorycmodelhead">Filter</div>
                                <div className="loginsinphone catemodal">
                                    
                                    <div className="filtercategory mb-3">
                                        <select {...formik.getFieldProps("primaryCategory")} className="form-select" aria-label="Default select example">
                                            <option value="">Select Primary Category</option>
                                            {resDataPC?.map((d, k) => (
                                                <option value={d?._id} key={k}>{d?.title}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage formik={formik} name="primaryCategory" />
                                    </div>
                                    <div className="filtercategory mb-3">
                                        <select {...formik.getFieldProps("natureOfBusiness")} className="form-select" aria-label="Default select example">
                                            <option value="">Select Nature of Business</option>
                                            {resDataNOB?.map((d, k) => (
                                                <option value={d?._id} key={k}>{d?.title}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage formik={formik} name="natureOfBusiness" />
                                    </div>
                                    <div className="filtercategory">
                                        <select {...formik.getFieldProps("city")} className="form-select" aria-label="Default select example">
                                            <option value=''>City</option>
                                            {resDataCity?.map((d, k) => (
                                                <option value={d} key={k}>{d}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage formik={formik} name="city" />
                                    </div>


                                </div>
                                <div className="filterprolistbtncon">
                                    <div className="loginsignbtn filterreset"><button type='button' className='theme-btn-2'
                                            onClick={()=>{
                                                formik.resetForm()
                                                navigate(resetPath);
                                                setFilter(false)
                                            }}
                                        >Reset</button></div>
                                    <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-2'>Apply</button></div>
                                </div>

                            </form>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
