import React, { useContext, useEffect } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../components/formik/errorMessage';
import common from '../services/common';
import { UserContext } from '../context/theme';
import { useNavigate } from 'react-router-dom';
import UnauthAxios from '../services/unauthAxios';
import { toast } from 'react-toastify';


export default function ContactUs() {
    const { userInfo } = useContext(UserContext)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            companyName: "",
            userId: "",
            businessEmail: "",
            subject: "",
            description: "",
        },
        validationSchema: Yup.object({
            companyName: Yup.string().required("Please enter company name"),
            userId: Yup.string(),
            businessEmail: Yup.string().email('Please enter valid email').required("Please enter email"),
            subject: Yup.string().required("Please enter subject"),
            description: Yup.string().required("Please enter description"),
        }),

        onSubmit: values => {
            submitForm(values)
        },
    });
    const submitForm = async (values) => {
        common.loader(true);
        await UnauthAxios({
            method: "POST",
            url: `/c/contactUs/add`,
            data: values,
        }).then((res) => {
            formik.resetForm()
            formik.setFieldValue('companyName', userInfo?.companyName || '')
            formik.setFieldValue('businessEmail', userInfo?.primaryEmail || '')
            formik.setFieldValue('userId', userInfo?.userId || '')
            toast.success(res?.data?.message)
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }

    useEffect(() => {
        if (userInfo?._id) {
            formik.setFieldValue('companyName', userInfo?.companyName || '')
            formik.setFieldValue('businessEmail', userInfo?.primaryEmail || '')
            formik.setFieldValue('userId', userInfo?.userId || '')
        }
    }, [userInfo])
    return (
        <>
            <div className="logincontentwrp contWrpPge">

                <div className='contPageWrp'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className="dashmainWRP contact">
                                    <div className="helpdeskmainheadcon">
                                        <h1>Contact us</h1>
                                    </div>
                                    <form onSubmit={formik.handleSubmit}>

                                        <div className="helpdesktabcon editticket">
                                            <div className="contactFormbx">
                                                <div className='row justify-content-between'>
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="loginsinphone sign">
                                                            <div className='signupinphead mb-2'>Company Name</div>
                                                            <input {...formik.getFieldProps("companyName")} type="text" className="form-control" placeholder="Enter Company Name" onChange={(e) => formik.setFieldValue("companyName", common.inpAlphabet(e.target.value))} />
                                                            <ErrorMessage formik={formik} name="companyName" />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="loginsinphone sign">
                                                            <div className='signupinphead mb-2'>Vendor/Customer ID</div>
                                                            <input {...formik.getFieldProps("userId")} type="text" className="form-control" placeholder="Enter Vendor/Customer ID" />
                                                            <ErrorMessage formik={formik} name="userId" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="loginsinphone sign">
                                                            <div className='signupinphead mb-2'>Work Email/Business Email</div>
                                                            <input {...formik.getFieldProps("businessEmail")} type="text" className="form-control" placeholder="Enter Work Email/Business Email" />
                                                            <ErrorMessage formik={formik} name="businessEmail" />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="loginsinphone sign">
                                                            <div className='signupinphead mb-2'>Subject</div>
                                                            <input {...formik.getFieldProps("subject")} type="text" className="form-control" placeholder="Enter Subject" />
                                                            <ErrorMessage formik={formik} name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="loginsinphone bodernone sign">
                                                            <div className='signupinphead mb-2'>Description</div>
                                                                <div className='loginsingphobor'>
                                                                    <textarea {...formik.getFieldProps("description")} rows="3" placeholder="Write Description"></textarea>
                                                                </div>
                                                            <ErrorMessage formik={formik} name="description" />

                                                        </div>
                                                    </div>
                                                    <div className="col-12">

                                                        <div className="loginsignbtn"><button type='submit' className='theme-btn-1'>Send</button></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
