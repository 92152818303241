import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap';
import ContactInfo from '../../components/customer/contactInfo';
import LogisticAddress from '../../components/customer/logisticAddress';
import OfficeAddress from '../../components/customer/officeAddress';
import WareHouseAddress from '../../components/customer/warehouseAddress';


export default function CustomerSales({userInfo,type}) {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [address, setAddress] = useState(true)
    const [moreAddress, setMoreAddress] = useState(true)
     
    return (
        <>

            <div className="cuspromoterWRp">
                
                <ContactInfo data={userInfo?.salesContactInfo?.salesContactInfo} />
                <OfficeAddress data={userInfo?.salesContactInfo?.officeAddresses} />
                <WareHouseAddress data={userInfo?.salesContactInfo?.warehouseAddresses} />
                <LogisticAddress data={userInfo?.salesContactInfo?.logisticsAddresses} />
                

                {/* {!open2 && (
                    <div className="col-lg-12">
                        <div className="blurarrowcon mt-3">  <img className='img-fluid' src="/assets/images/blurdownarrow.svg" alt="image" onClick={() => setOpen2(!open)} /></div>
                    </div>
                )} */}

                {/* <Collapse in={open}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="viewstoretabwrp mt-5">
                                    <div className="cusnamehead cusname mt-3 mb-2">Office Address </div>
                                    <div className="cuspromoterlist wdt w-100">
                                        <div className="cusnamehead wdt">Address 1</div>
                                        <span >:</span>
                                        <div className="cusnamehead cusname w-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eleifend sagittis rutrum. Fusce id feugiat justo, a semper ante.</div>
                                    </div>
                                    <div className="cuspromoterlist wdt w-100">
                                        <div className="cusnamehead wdt">Address 2</div>
                                        <span >:</span>
                                        <div className="cusnamehead cusname w-100">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eleifend sagittis rutrum. Fusce id feugiat justo, a semper ante.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="blurarrowcon rotatimg mt-3">  <img className='img-fluid' src="assets/images/blurdownarrow.svg" alt="image" onClick={() => setMoreAddress(true)} /></div>
                            </div>
                        </div>
                    </div>
                </Collapse> */}






            </div>
        </>
    )
}
