import React, { useEffect, useState } from 'react'
import ImageDownload from '../components/common/ImageDownload';
import common from '../services/common';
import UnauthAxios from '../services/unauthAxios';

export default function AboutUs() {
    const [resData, setResData] = useState({});

    async function getData() {
        common.loader(true);
        await UnauthAxios({
            method: "GET",
            url: `/c/information/about/getAll`,
        }).then((res) => {
            setResData(res?.data?.data || {})
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <>
            <div className="aboutWRPmain">
                <div className="container">
                    <div className='row '>
                        <div className='col-12'>
                            <div className="termsleafwrp">
                                <img className='img-fluid mr-2' src="./assets/images/onlyleaf.svg" alt="image" />
                                <div className="termsheadtex"><h1>{resData?.section?.heading}</h1></div>
                                <span className='ml-3'></span>
                            </div>
                        </div>
                    </div>

                    <div className='aboutparaimgwrp'>
                        <div className='row'>
                            <div className=' col-12'>
                                <div className="aboutWRPCONmainWRP">
                                    <div className="aboutWRPCONmain">
                                        <div className="aboutWRPmaincon">
                                            <div className="aboutsmallpara ">
                                                <div className="aboutmaonimg">
                                                    <ImageDownload id={resData?.section?.image} />
                                                </div>
                                                <div className='editorWrp ' dangerouslySetInnerHTML={{ __html: resData?.section?.description1 }} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="termsparawrp">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.description2 }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
