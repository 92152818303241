import React, { useEffect, useState } from 'react';

import Piechart from './piechart';
import LineChart from './lineChart';
import authAxios from '../../services/authAxios';


// 
function Chart() {
    const [resData, setResData] = useState(0);

    async function getData() {
        await authAxios({
            method: "GET",
            url:`/c/reportsAndAnalytics/totalVendors` ,
        }).then((res) => {
            let resData = res?.data?.data
            setResData(resData)
        }).catch((error) => {
            console.log(error);
            // common.error(error)
        });
    }
    useEffect(() => {
        getData()
    }, []);
    return <>
        <div className="chartpielinewrp">
            <div className='dashheadercon'>
                <div className='dashheadtext'>
                    <h1>Reports & Analytics</h1>
                </div>
            </div>
            <div className='piechartwrp'>
                <div className="row justify-content-between">
                    <div className="col-lg-4 col-md-4">
                        <Piechart heading={'Vendors of Top 5 Cities'} total={resData} route={`/c/reportsAndAnalytics/vendorsOfTopCities`} type="city"  />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <Piechart heading={'Vendors of Top 5 States'} total={resData} route={`/c/reportsAndAnalytics/vendorsOfTopStates`} type="state"  />
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <Piechart heading={'Fav. Products of Top 5 Vendors'} route={`/c/reportsAndAnalytics/favouriteProductsOfTopVendors`} type="category" />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <LineChart heading={'No. of Active Customer'} route={`/c/reportsAndAnalytics/graphData`} type="activeCustomer" />
            </div>
        </div>
    </>;
}
export default Chart;