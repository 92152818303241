import React from 'react'
import Moment from 'react-moment'

const DateFormate = ({children}) => {
  return (
    <>
        <Moment format='DD/MM/YYYY'>{children}</Moment>
    </>
  )
}

export default DateFormate