import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FilterNewOldCheck from '../../components/filterDateField/filterNewOldCheck';
import FilterActiveCustomer from '../../components/filterDateField/filterActiveCustomer';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import SearchFormik from '../../components/common/searchFormik';
import PageModule from '../../components/pagination/pagination';
import { useEffect } from 'react';
import ConfirmModal from '../../components/modals/confirmModal';
import View from '../../components/filterDateField/view';
import { UserContext } from '../../context/theme';
import WishlistBtn from '../../components/store/wishlistBtn';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function ActiveCustomer() {
    const {getStoreCounts, counts, employData} = useContext(UserContext)
    // const [selectToggle, setSelectToggle] = useState(false)
    let navigate= useNavigate();
    const history = useLocation()
    const commonData = common.getActiveFilter()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: ""
    }
    const getPageData = async(isCheckPage) => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''
        postData.filters = {
            city:'',
            natureOfBusiness:'',
        }
        if(commonData?.natureOfBusiness){
            postData.filters.natureOfBusiness = commonData?.natureOfBusiness || ''
        }
        if(commonData?.primaryCategory){
            postData.filters.primaryCategory = commonData?.primaryCategory || ''
        }
        if(commonData?.city){
            postData.filters.city = commonData?.city || ''
        }
        if(commonData?.sorting){
            postData.sorting = commonData?.sorting || ''
        }

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/manageVendors/connectedVendors`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            resData = resData?.map((d)=>{
                let obj = d?.connectedVendors?.vendor
                obj.isChecked = false;
                return obj
            })


            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount
            })
            getStoreCounts()
            if(isCheckPage){
                let isNavChange = common.paginAfterChange('getActiveFilter', 10, res?.data?.data?.length)
                if(isNavChange) {
                    navigate(isNavChange)
                }
            }
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getActiveFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/active-vendor?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
    },[history])

    const handleChecked =(status, id) => {
        let datas = pageData?.list || []
        datas?.map((d)=>{
            if(d?._id === id){
                d.isChecked = !status
                // setSelectToggle(true)
            }
            return d
        })
        setPageData({
            ...pageData,
            list:datas,
        }) 
    }
    
    const handleToggleChecked =() => {
        // setSelectToggle(!selectToggle)
        let datas = pageData?.list || []
        let stats = pageData?.list?.filter((d)=>d.isChecked === false)?.length > 0 ? true : false
        datas?.map((d)=>{
            // console.log(d)
            d.isChecked = stats
            return d
        })
        setPageData({
            ...pageData,
            list:datas,
        }) 
    }

    // console.log(pageData)

    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon ">
                    <div className="dashheadtext resmode"><h1>Active Vendors ({counts?.activeVendorsCount || 0})</h1></div>
                    <div className="dashheaadbtncon active">
                        <div className="activecusresfilter revers">
                            
                                {/* <img className='img-fluid' src="assets/images/delete.svg" alt="image" /> */}
                                {pageData?.list?.filter((d)=>d.isChecked === true)?.length >= 2 ? (
                                    <div className="prolistnavdelete ml-lg-0 ml-md-0 ml-sm-2">
                                        {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (
                                            <ConfirmModal 
                                                title="Are you sure ?"
                                                method="POST"
                                                postData={{email:pageData?.list?.filter((d)=>d.isChecked === true)?.map((d)=>{return d?.primaryEmail})}}
                                                renderHtml={<img className='image-fluid mr-2' src="/assets/images/delete.svg" alt="image" />} 
                                                url={`/c/manageVendors/unlinkVendors`} 
                                                action={()=>{
                                                    getPageData(true) 
                                                    getStoreCounts() 
                                                }} 
                                                submitBtnName="Confirm"
                                            />
                                        )}
                                        
                                    </div>
                                ): (
                                    <div className="dashstorelink">
                                        {/* <a onClick={()=>handleToggleChecked()}>Select All</a> */}
                                    </div>
                                )}
                        </div>
                        <div className="activecusresfilter">
                            <div className="prolistnavinp">
                                <img className='image-fluid' src="/assets/images/search.svg" alt="" />
                                <SearchFormik name="getActiveFilter" onChange={(values)=>{
                                    let search = common.getActiveFilter();
                                    search.page = 1
                                    search.searchString = values.searchString
                                    const queryString = new URLSearchParams(search).toString();
                                    navigate(`/active-vendor?` + queryString)
                            }}  />
                            </div>
                            <FilterNewOldCheck navigatePath={"active-vendor"} resetPath="/active-vendor" name="getActiveFilter" />
                            <FilterActiveCustomer navigatePath={"active-vendor"} resetPath="/active-vendor" />
                        </div>

                    </div>
                </div>

                <div className="prolisttableWRP">
                    <table className="table">
                        <thead className="prolistthead">
                            <tr>
                                <th className="align-middle">
                                    <div className="checkinp">
                                    {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (<input type="checkbox" className="form-check-input" checked={pageData?.list?.filter((d)=>d.isChecked === false)?.length > 0 ? false : (pageData?.list?.length > 0 ? true : false)} onChange={()=>handleToggleChecked()}  />)}
                                        <div> Picture   </div>
                                    </div>
                                </th>
                                <th className="align-middle">Company Name</th>
                                <th className="align-middle">Nature of Business</th>
                                <th className="align-middle">Primary Category</th>
                                <th className="align-middle">City </th>
                                <th className="align-middle">Rating </th>
                                <th className="align-middle">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td className="align-middle" data-th="Picture  -">
                                    <div className="checkinp">
                                        <input type="checkbox" />
                                        <div className='tablechecktxt'>  <img className='image-fluid' src="assets/images/girlimg.svg" alt="image" />
                                        </div>
                                    </div>
                                </td>
                                <td className="align-middle activename" data-th="Company Name"> <Link to="/profile" >Saledesk PVT</Link></td>
                                <td className="align-middle" data-th="Nature of Business  -">Retailer</td>
                                <td className="align-middle" data-th="Mobile Number  -">9998887770</td>
                                <td className="align-middle" data-th="City  -">Jaipur</td>

                                <td className="align-middle" data-th="Action  -"> <div className="actionimg">
                                    <img className='image-fluid mr-1' src="assets/images/delete.svg" alt="image" />
                                </div></td>

                            </tr> */}

                            {pageData?.list?.map((d,k)=>(
                                <tr key={k}> 
                                    <td className="align-middle" data-th="Picture  -">
                                        <div className="checkinp">
                                        {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (<input type="checkbox" className="form-check-input"  checked={d?.isChecked || false} onChange={()=>handleChecked(d?.isChecked, d?._id)}  />)}
                                            <div className='tablechecktxt'>
                                                <img className='img-fluid' src={d?.profileImage[0]?.fullUrl || '/assets/images/default_user.jpg'} alt="image" />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle activename" data-th="Company Name"> 
                                        <Link to={`/vendor/view/${d?._id}`} >{d?.companyName}</Link>
                                    </td>
                                    <td className="align-middle" data-th="Nature of Business  -">{d?.natureOfBusiness[0]?.title}</td>
                                    <td className="align-middle" data-th="Primary Category  -"><View data={d?.primaryCategory?.map((d)=>d?.title)} index={k} /></td>
                                    <td className="align-middle" data-th="City  -">{d?.city}</td>
                                    <td className="align-middle" data-th="Rating  -">
                                        {d?.averageRatings > 0 ? (
                                            <div className="d-flex align-items-center"><img src="/assets/images/singlestar.svg" alt="img" /><div> {d?.averageRatings} </div> </div>
                                        ):(
                                            <div className="d-flex align-items-center">-</div>
                                        )}
                                    </td>

                                    <td className="align-middle" data-th="Action  -"> 
                                        <div className="actionimg">
                                            <WishlistBtn 
                                                addUrl={`/c/manageVendors/favourite/${d?._id}`}
                                                removeUrl={`/c/manageVendors/removeFavourite/${d?._id}`}
                                                type="vendor"
                                                productData={d} action={()=>{
                                                    getPageData()
                                                    getStoreCounts()
                                            }}
                                            isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)}
                                            />
                                            {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (
                                                <ConfirmModal
                                                    title="Are you sure ?"
                                                    method="GET"
                                                    renderHtml={<img className='image-fluid ml-1' src="/assets/images/delete.svg" alt="image" />} 
                                                    url={`/c/manageVendors/unlinkVendor/${d?.primaryEmail}`} 
                                                    action={()=>{
                                                        getPageData(true)
                                                        getStoreCounts()
                                                    }} 
                                                    submitBtnName="Confirm"
                                                />
                                            )}
                                            
                                            {/* <img className='image-fluid mr-1' src="assets/images/delete.svg" alt="image" /> */}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>
                {pageData?.totalItemsCount > 10 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div>

        </>
    )
}

