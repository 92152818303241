import React from 'react'

export default function CustomerAbout({userInfo,type}) {
    return (
        <>
            <div className="cuspromoterWRp">
                <div className="row">
                    <div className="col-12">
                        <div className="cuspromoterlist abtlist">
                            <div className="cusnamehead about">Company Description</div>
                            <span className='abotspan'>:</span>
                            <div className='cusnamehead cusname editorWrp' dangerouslySetInnerHTML={{ __html:userInfo?.aboutusId?.description || 'N/A' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
