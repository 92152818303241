import React from 'react'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import CustomerAbout from './customerAbout';
import CustomerBusiness from './customerBusiness';
import CustomerGallary from './customerGallary';
import CustomerPromoter from './customerPromoter';
import CustomerSales from './customerSales';

export default function ProfileCustomer() {
    const [key, setKey] = useState('promoter');

    return (
        <>
            <div className="dashmainWRP profilecus">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Customer Profile</h1></div>

                </div>
                <div className="dashmainCON">
                    <div className="cusprofileWRP">
                        <img className='img-fluid ' src="assets/images/cusprofileimg.svg" alt="image" />
                        <div className="cusprofileimginp mb-3">
                            <div className="dashprofiletext cusprofile">jD
                                <input className='cusprofileinp' type="file" />
                            </div>
                            <Link to="/review-rating"> 
                                <div className="dashprofilerating viewrate"> 3.5 <img src="assets/images/singlestar.svg" className='mr-2' alt="img" /><span>10 Reviewss</span> </div>
                            </Link>
                        </div>
                    </div>

                    <div className="profiletavWRP mr">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="promoter" title="Promoter Profile">
                                <CustomerPromoter />
                            </Tab>
                            <Tab eventKey="business" title="Business Profile">
                                <CustomerBusiness />
                            </Tab>
                            <Tab eventKey="sales" title="Sales Contact" >
                                <CustomerSales />
                            </Tab>
                            <Tab eventKey="abouts" title="Abouts Us " >
                                <CustomerAbout />
                            </Tab>
                            <Tab eventKey="company" title="Company Gallery  " >
                                <CustomerGallary />
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>
        </>
    )
}
