import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authAxios from "../services/authAxios";
// import Layout from "../layout/layout";
// import Login from "../pages/login";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);
    const [globalLayout, setGlobalLayout] = useState(true);
    const [Theme, setTheme] = useState("theme-1");
    const [ThemeColor, setThemeColor] = useState("");
    const [MiniSidebar, setMiniSidebar] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [breadcrumbs, setBreadcrumbs] = useState({});
    const [counts, setCounts] = useState({});
    const [cartData, setCartData] = useState([]);
    const [routeHistory, setRoutHistory] = useState([]);
    const [employData, setEmpolyData] = useState({});
    const [socket, setSocket] = useState({});
    const [sideMenuActive, setSideMenuActive] = useState(false)
    const [isConnected, setIsConnected] = useState(false)
    const [notificationData, setNotificationData] = useState([])
    const reset = () => {
        localStorage.clear();
        setSocket({})
        setGlobalLayout(true)
        setCartData([]);
        setRoutHistory([]);
        setNotificationData([]);
        setEmpolyData({});
        setUserInfo({});
        setCounts({});
        setBreadcrumbs({});
        setAuth(false);
        setIsConnected(false);
        navigate("/");
    };

    function getProfile() {
        // common.loader(true);
        authAxios({
            method: "GET",
            url: `/c/profile`,
        }).then((res) => {
            let resData = res?.data?.data || {};
            setUserInfo(resData);
        }).catch((error) => {
            console.log(error)
        });
    // common.loader(false);
    }
    function getEmployee(id) {
        // common.loader(true);
        let postId = id || localStorage.getItem('employeeId')
        if(postId && postId != 'null'){
            authAxios({
                method: "GET",
                url: `/c/user/employeeDetails/${postId}`,
            }).then((res) => {
                let resData = res?.data?.data || {};
                setEmpolyData(resData);
            }).catch((error) => {
                console.log(error)
            });
        }
    // common.loader(false);
    }
    const  getCart = () => {
        // common.loader(true);
        authAxios({
          method: "GET",
          url: `/c/cart`,
        }).then((res) => {
            let resData = res?.data?.data?.products || [];
            setCartData(resData)
        }).catch((error) => {
            console.log(error);
        });
        // common.loader(false);
      }
    const  getStoreCounts = () => {
        // common.loader(true);
        authAxios({
          method: "GET",
          url: `/c/counts`,
        })
          .then((res) => {
            let resData = res?.data?.data || {};
            setCounts(resData)
          })
          .catch((error) => {
            console.log(error);
          });
        // common.loader(false);
      }
    function refreshtoken() {
        authAxios({
            method: "GET",
            url: `/c/user/tokenRefresh`,
        })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {
        let intrVal;
        if (auth) {
            getCart();
            getProfile();
            getStoreCounts();
            getEmployee();
            intrVal = setInterval(() => {
                refreshtoken();
            }, 60*1000);
        }
        return () => clearInterval(intrVal);
    }, [auth]);

    useEffect(() => {
        /* let thm = localStorage.getItem('theme') || "theme-1";
    let thmClr = localStorage.getItem('themeColor'); */
        let authLocal = localStorage.getItem("token");
        /* setTheme(thm);
    setThemeColor(thmClr); */
        setAuth(authLocal);
    }, []);
    return (
        <UserContext.Provider
            value={{
                ThemeColor,
                Theme,
                auth,
                MiniSidebar,
                reset,
                getCart,
                getStoreCounts,
                counts, setCounts,
                getEmployee,
                socket, setSocket,
                employData, setEmpolyData,
                globalLayout, setGlobalLayout,
                routeHistory, setRoutHistory,
                cartData, setCartData,
                sideMenuActive, setSideMenuActive,
                isConnected, setIsConnected,
                notificationData, setNotificationData,
                getProfile,
                setThemeColor,
                setTheme,
                setAuth,
                setMiniSidebar,
                userInfo,
                setUserInfo,
                breadcrumbs,
                setBreadcrumbs,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
