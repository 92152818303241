const pageList = [
    {name:'About Us', url:'/about-us'},
    {name:'Home', url:'/contact-us'},
    // {name:'Sign Up / Sign In', url:'/sign-in'},
    // {name:'OTP Verify', url:'/verifyOTP'},
    {name:'Cart', url:'/my-cart'},
    {name:'Notification', url:'/notification'},
    {name:'FAQS', url:'/faqs'},
    {name:'Terms & Conditions', url:'/terms-conditions'},
    {name:'Privacy Policy', url:'/privacy-policy'},
    {name:'Dashboard', url:'/dashboard'},
    {name:'Quick Tutorials', url:'/quick-tutorial'},
    {name:'Active Vendors', url:'/active-vendor'},
    {name:"Vendor's Requests", url:'/vendor-request'},
    {name:'Discover Vendors', url:'/discover-vendor'},
    {name:'My Favorite Vendors', url:'/favorite/vendor'},
    {name:'My Favorite Products', url:'/favorite/product'},
    {name:'My Favorite Catalogue', url:'/favorite/catalogue'},
    {name:'User List', url:'/user-list'},
    {name:'Create User & Roles', url:'/create-user'},
    {name:'Reports & Analytics', url:'/chart'},
    {name:'Help', url:'/help'},
    {name:'Ticket View', url:'/helpTicketView'},
    {name:'Profile', url:'/profile'},
    {name:'Raise Ticket', url:'/raiseTicket/'},
    {name:'Vendor Profile', url:'/vendor/view/'},
    {name:'Reviews & Rating', url:'/review-rating/'},
    {name:'Product List', url:'/vendor/product/list/'},
    {name:'Product List', url:'/vendor/catalogue/'},
    {name:'Product View', url:'/vendor/product/view/'},
]

export default pageList;