import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useParams } from 'react-router-dom';
import CustomerAbout from '../profileCustomer/customerAbout';
import CustomerBusiness from '../profileCustomer/customerBusiness';
import CustomerGallary from '../profileCustomer/customerGallary';
import CustomerPromoter from '../profileCustomer/customerPromoter';
import CustomerSales from '../profileCustomer/customerSales';
import * as Yup from "yup"
import { useFormik } from 'formik';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import ReviewModal from '../../components/modals/review';
import WishlistBtn from '../../components/store/wishlistBtn';
import { UserContext } from '../../context/theme';


export default function ViewStore() {
    
    const {vendorId} = useParams()
    const [key, setKey] = useState('promoter');
    const [vendorInfo, setVendorInfo] = useState({})
    const [vendorConnected, setVendorConnected] = useState(false)
    const {employData} = useContext(UserContext)

    const getPageData = async () => {
        common.loader(true);
        await authAxios({
            method: "GET",
            url: `/c/manageVendors/vendorProfile/${vendorId}`,
        }).then((res) => {
            let resData = res?.data?.data;
            setVendorConnected(resData?.isConnected || false)
            setVendorInfo(resData)
        })
        .catch((error) => {
            common.error(error);
        });
        common.loader(false);
    };

    useEffect(()=>{
        vendorId && getPageData()
    },[vendorId])

    console.log(vendorInfo?.isConnected)

    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Vendor Profile</h1></div>
                    <div className="dashheaadbtncon">
                        
                        {((employData?.role === 'sm' ||employData?.role === 'dr' || employData?.role === undefined)) ? (
                            <>
                                <div className="dashstorelink ml-3"><Link to={`/vendor/product/list/${vendorId}`}>Products</Link></div>
                                {vendorInfo?.isConnected ? (
                                    <div className="dashstorelink ml-3"><Link to={`/vendor/catalogue/${vendorId}`}>Catalogue</Link></div>
                                ):(
                                    <div className="dashstorelink disable-btn ml-3"><a>Catalogue</a></div>
                                )}
                                
                            </>
                        ) : (
                            <>
                                <div className="dashstorelink disable-btn ml-3"><a>Products</a></div>
                                <div className="dashstorelink disable-btn ml-3"><a>Catalogue</a></div>
                            </>
                        )}
                        
                    </div>
                </div>
                <div className="dashmainCON">

                    <div className="cusprofileWRP">
                        <div className='cusproImgBx'>
                            <div className='cusproImgBxCON'>
                                <div className='viewStorImg'>
                                    <img className='img-fluid ' src={vendorInfo?.aboutusId?.image?.fullUrl || "/assets/images/aboutimg.svg"} alt="image" />
                                </div>
                            {vendorConnected  ? (
                                <>
                                    <WishlistBtn
                                        addUrl={`/c/manageVendors/favourite/${vendorInfo?._id}`}
                                        removeUrl={`/c/manageVendors/removeFavourite/${vendorInfo?._id}`}
                                        productData={vendorInfo} 
                                        isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)}
                                        type="vendor"
                                        renderHtml={<button className="lickBtn" type="button"><iconify-icon icon="subway:hurt"></iconify-icon></button>}
                                        action={()=>getPageData()}
                                    />
                                    
                                </>
                            ) : (
                                <>
                                    <button className="lickBtn disable-btn" type="button"><iconify-icon icon="subway:hurt"></iconify-icon></button>
                                </>
                            )}
                            </div>
                        </div>
                        <div className="cusprofileimginpcon">
                            <div className="cusprofileimginp mb-3">
                            <div className="dashprofiletext cusprofile">
                                    {vendorInfo?.profileImage?.fullUrl ? (
                                        <img src={vendorInfo?.profileImage?.fullUrl || "/assets/images/singlestar.svg"} className='img-fluid' alt="img" />
                                    ) : (
                                        common.getFirstLetter(vendorInfo?.contactName)
                                    )}
                                </div>
                                {vendorInfo?.totalReviews > 0 ? (
                                    <Link to={`/review-rating/${vendorId}`}> <div className="dashprofilerating viewrate"> {vendorInfo?.averageRatings} <img src="/assets/images/singlestar.svg" className='mr-2' alt="img" /> <span>{vendorInfo?.totalReviews} Reviews</span> </div></Link>
                                ):(
                                    <div className="dashprofilerating viewrate">No Review</div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                    <div className="profiletavWRP viewProFileWrp">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="venderProTabs"
                        >
                            <Tab eventKey="promoter" title="Promoter Profile">
                                <CustomerPromoter  userInfo={vendorInfo} type="vendor" vendorConnected={vendorConnected} />
                            </Tab>
                            <Tab eventKey="business" title="Business Profile">
                                {vendorConnected && (
                                    <CustomerBusiness userInfo={vendorInfo} type="vendor" />
                                )}
                            </Tab>
                            <Tab eventKey="sales" title="Sales Contact" >
                                {vendorConnected && (
                                    <CustomerSales userInfo={vendorInfo} type="vendor" />
                                )}
                            </Tab>
                            
                            <Tab eventKey="abouts" title="Abouts Us " >
                                <CustomerAbout userInfo={vendorInfo} type="vendor" />
                            </Tab>
                            <Tab eventKey="company" title="Company Gallery  " >
                                <CustomerGallary userInfo={vendorInfo} type="vendor" />
                            </Tab>
                        </Tabs>

                    </div>
                    <div className="profilereviewbt">
                    {
                        (vendorConnected &&  (employData?.role === 'dr' || employData?.role === undefined)) ? (
                            !vendorInfo?.ratingsGiven && (
                                <ReviewModal
                                    url={`/c/manageVendors/ratingReview/${vendorInfo?._id}`}
                                    productData={vendorInfo} 
                                    type="vendor"
                                    action={()=>getPageData()} 
                                />
                            )
                            
                            // <button type='submit' className=''>Give Review & Rating</button>
                        ) : (
                            <button type='button' className='btn-default disable-btn'>Give Review & Rating</button>
                        )}
                        
                    </div>
                    
                </div>
            </div>
        </>
    )
}
