import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import {REACT_APP_REDIRECT_URL} from "../services/api-url"

export default function HeaderUnAuth() {
    const [filter, setFilter] = useState(true)
    const [sideMenuActive, setSideMenuActive] = useState(false)
    const Tabbing = (type) => {
        let newTabval = type == filter ? "" : type;
        setFilter(newTabval);
    };

    const history = useLocation()
    useEffect(()=>{
        setSideMenuActive(false)
        document.body.className = document.body.className.replace("overflowHidn", "");
    },[history])

    const toggleSidebar = ()=>{
        setSideMenuActive(!sideMenuActive)
        if(sideMenuActive){
            document.body.className = ""
        }else{
            document.body.className = "overflowHidn"
        }
        
    }
    return (
        <>
            <div className="saledesksignupWRP">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            <div className="navwrp">
                                <nav className="navbar">
                                    <div className="seldestnavimg">
                                        <Link to='/'>  <img src="/assets/images/logofinal.svg" alt="image" /></Link>
                                    </div>
                                    <div className="saledesknavlink">
                                        <ul>
                                            <li className='hoverlink'><Link to="/about-us">About Us</Link></li>
                                            <li className='hoverlink bg'><a target="_blank" href={REACT_APP_REDIRECT_URL} >Become a Seller</a></li>
                                            <li className='hoverlink'><Link to="/contact-us">Contact Us</Link></li>
                                            <li className='navbtnli'><Link to="/sign-in" className='theme-btn-1'>Sign In</Link></li>
                                        </ul>
                                    </div>
                                </nav>                                               
                                <div className="menushowwrp">
                                    <div className='navmenumainwrp' onClick={() =>toggleSidebar()}>
                                        
                                        {sideMenuActive ? (
                                            <img className='iconmenu nav' src="/assets/images/clsoe.svg" alt="image" />
                                        ) :(
                                            <img className='iconmenu nav' src="/assets/images/menuicon.svg" alt="image" />
                                        )}
                                        
                                        
                                    </div>
                                    {/* <div className='navmenumainwrp' onClick={() => setSidebarActive(!sidebarActive)}>
                                        <span><img onClick={() => Tabbing("search")} className='iconmenu' src="/assets/images/menuicon.svg" alt="image" /></span>
                                    </div>
                                    <div className={`homemenuWRP bar ${sidebarActive ? 'active' : ''}`} onClick={() => setSidebarActive(!sidebarActive)}>
                                    </div> */}
                  <div className={`leftSideBrBackGrnd ${sideMenuActive?'active':''}`} onClick={() =>toggleSidebar()}></div>
                                    <div className={`homemenusidewrp  ${sideMenuActive?'active':''}`}>
                                  
                                        <div className="saledesknavlinkwrp">
                                            {/* <img className='multimenu' src="assets/images/multimenu.png" alt="image" /> */}
                                            <div className="saledesknavlink collapse navbar-collapse" id="navbarSupportedContent">
                                                <ul>
                                                    <li className='hoverlink'><Link to="/about-us">About Us</Link></li>
                                                    <li className='hoverlink bg'><a target="_blank" href={REACT_APP_REDIRECT_URL} >Become a Seller</a></li>
                                                    <li className='hoverlink'><Link to="/contact-us">Contact Us</Link></li>
                                                    <li className='navbtnli'><Link to="/sign-in" className='theme-btn-1'>Sign In</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
