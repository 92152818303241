import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import FilterNewOldCheck from '../../components/filterDateField/filterNewOldCheck'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProductListFilter from '../../components/modals/productListFilter';
import ProductListSendInquiry from '../../components/modals/productListSendInquiry';
import StoreBox from '../../components/store/StoreBox';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import PageModule from '../../components/pagination/pagination';
import SearchFormik from '../../components/common/searchFormik';
import CartBtn from '../../components/store/cartBtn';
import WishlistBtn from '../../components/store/wishlistBtn';
import { UserContext } from '../../context/theme';

export default function StoreProductList() {
    const [item, setIsActive] = useState(false);
    const {vendorId} = useParams()
    const history = useLocation()
    const navigate = useNavigate()
    const [cartImg, setCartImg] = useState(true)
    const [categoryList, setCategoryList] = useState([])
    const [brandData, setBrandData] = useState([])
    const [charetorLimit, setCharetorLimit] = useState(10)
    const {employData, isConnected, getCart} = useContext(UserContext)

    const [key, setKey] = useState('promoter');

    const commonData = common.getProductFilter()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 12,
        totalItemsCount: 0,
        maxPrice: 100000,
    });
    let postData = {
        page: "1",
        perPage: "12",
        searchString: ""
    }

    const getCategory = async () => {
		await authAxios({
			method: "GET",
			url: `/c/manageVendors/categories/${vendorId}`
		}).then((res) => {
			setCategoryList(res?.data?.data || [])
		}).catch((error) => {
			common.error(error)
		});
	}

    const getPageData = async(catId) => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''
        postData.vendorId = vendorId || ''
        postData.categoryId = commonData?.categoryId || null
        postData.sorting = commonData?.sorting || 'new'
        postData.filters = {}

        if(commonData?.label){
            postData.filters.label = commonData?.label || ''
        }
        if(commonData?.typeOfAvailability){
            postData.filters.typeOfAvailability = commonData?.typeOfAvailability || ''
        }
        
        if(commonData?.brandName){
            postData.filters.brandName = commonData?.brandName || ''
        }
        // if(commonData?.status){
        //     postData.filters.status = commonData?.status || ''
        // }
        if(commonData?.minOrderQt){
            postData.filters.minOrderQt = commonData?.minOrderQt || ''
        }
        if(commonData?.originCountry){
            postData.filters.originCountry = commonData?.originCountry || ''
        }
        if(commonData?.priceRange){
            postData.filters.priceRange = commonData?.priceRange || ''
        }
        // if(commonData?.primaryCategory){
        //     postData.filters.primaryCategory = commonData?.primaryCategory || ''
        // }
        // if(commonData?.city){
        //     postData.filters.city = commonData?.city || ''
        // }
        // if(commonData?.sorting){
        //     postData.sorting = commonData?.sorting || ''
        // }

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/products/pagin`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount,
                maxPrice:res?.data?.maxPrice
            })
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getProductFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          console.log(queryString);
          navigate(`/vendor/product/list/${vendorId}?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
        // console.log(categoryList);
    },[history, categoryList])

    useEffect(()=>{
        getCategory()
    },[])

    
      useEffect(()=>{
        let w = window.innerWidth - 250 -(30 + 20*5 +63)
        w = w / 5
        w = parseInt(w)
        const charWidth = parseFloat(24) * 0.5;
        const maxChars = Math.floor(w / charWidth);

        if(w < 10){
            w = 10
        }
        setCharetorLimit(maxChars)
    })
    const handleCategory = (id)=>{
        let search = common.getProductFilter();
        search.categoryId = id
        search.page = 1
        const queryString = new URLSearchParams(search).toString();
        navigate(`/vendor/product/list/${vendorId}?${queryString}`);
    }
   

    return (
        <>
            <div className="activecusWRP">
                <div className="dashheadercon active">
                    <div className="dashheadtext "><h1>Product List</h1></div>
                    <div className="dashheaadbtncon">
                        <div className="prolistnavinp">
                            <img className='image-fluid ' src="/assets/images/search.svg" alt="" />
                            <SearchFormik name="getProductFilter" onChange={(values)=>{
                                    let search = common.getProductFilter();
                                    search.page = 1
                                    search.searchString = values.searchString
                                    const queryString = new URLSearchParams(search).toString();
                                    navigate(`/vendor/product/list/${vendorId}?` + queryString)
                            }}  />
                        </div>
                        <FilterNewOldCheck  navigatePath={`vendor/product/list/${vendorId}`} resetPath={`/vendor/product/list/${vendorId}`} name="getProductFilter" />
                        <ProductListFilter brandData={brandData} maxPrice={pageData?.maxPrice} navigatePath={`vendor/product/list/${vendorId}`} resetPath={`/vendor/product/list/${vendorId}`} />
                    </div>
                </div>
                {/* <div className="ratingwrp ">
                    <div className="ratingcon store">
                        <div className="ratimgimgwrp store  d-flex">
                            <div className="ratimgimgcon ">
                                <img className='image-fluid mr-3' src="assets/images/imgbackground.svg" alt="image" />
                                <div className="ratingimgheadcon">
                                    <div className="storeprontxt ">Nature of Business</div>
                                    <div className="ratingimghead">Store Name</div>
                                    <div className="ratingimgpara mb-1">2 Block, MI Road, Jaipur, Rajasthan 302001</div>
                                    <div className="ratingimgpara">Wellness Gifts | Gourmet Hampers | Electronic Gadgets & Home Appliances |<br /> Wellness Gifts | Gourmet Hampers | Electronic Gadgets & Home Appliances...
                                    </div>
                                </div>
                            </div>
                            <div className="storeproratecon">
                                <div className="storeprocountcon mt-2">
                                    <div className="storeprocount">200 Product</div>
                                    <div className="storeprocount mb-3">20 Catalouge</div>
                                </div>
                                <div className="storeprologo mb-3">
                                    <img className='image-fluid mr-3' src="assets/images/nike.svg" alt="image" />
                                    <img className='image-fluid mr-3' src="assets/images/adidas.svg" alt="image" />
                                    <img className='image-fluid ' src="assets/images/imgtiger.svg" alt="image" />
                                </div>
                                <Link to="/review-rating"> <div className="dashprofilerating mb-0"><img src="assets/images/singlestar.svg" alt="img" /> 4 <span>10 Reviews</span> </div></Link>
                            </div>
                        </div>
                    </div>
                </div> */}
                <StoreBox action={(d)=>setBrandData(d)} />
                {/* <div className="procatecuswrp ">
                    <ul>
                        <li> <div className="catecustxtpro">Category 1 </div></li>
                        <li> <div className="catecustxtpro">Category 1 </div></li>
                        <li> <div className="catecustxtpro">Category 1 </div></li>
                        <li> <div className="catecustxtpro">Category 1 </div></li>
                        <li> <div className="catecustxtpro">Category 1 </div></li>
                        <li> <div className="catecustxtpro rescus">More <img className="img-fluid" src="assets/images/selectdrp.svg" alt="image"/></div></li>
                    </ul>
                </div> 
                <div className="storeprocard ">
                    <div className="row justify-content-between">
                        <div className="col-lg-3">
                            <div className="aboutproimgwrp mb-3">
                                <div className="aboutproimgcon store mb-1">
                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                    <div className="storepropopular">Popular</div>
                                    <div className="favcataimg">
                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                    </div>
                                </div>
                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                <div className="storebtn">
                                    <ProductListSendInquiry />
                                    {cartImg ? (
                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                    ) : (
                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                
                <div className="listcustomer prodectlistviewTabs">
                    <div className='productTabsCategy'>
                        <div className='categroylistWrp desktopView'>
                        <div onClick={()=>setIsActive(current => !current)} className={`homemenuWRP bar trant ${item?'active':''}`}></div>
                            <ul className='topfiveCate'>
                                <li className={commonData?.categoryId === '' ? 'active': ''}><a onClick={()=>handleCategory('')}>All</a></li>
                                {categoryList?.map((d,k)=>(
                                    k < 5 && (
                                    <li key={k} className={commonData?.categoryId === d?._id ? 'active': ''}>
                                        <a onClick={()=>handleCategory(d?._id)} title={d?.name}>
                                            {d?.name?.length > charetorLimit ? d?.name?.substring(0, charetorLimit)+'...' : d?.name}
                                        </a>
                                    </li>
                                    )
                                ))}                                
                            </ul>
                            <div className="tabsMoreBx">
                                {categoryList?.length > 5 && (
                                    <>
                                        <h3 onClick={()=>setIsActive(current => !current)}>More <span><iconify-icon icon="material-symbols:keyboard-arrow-down"></iconify-icon></span></h3>
                                        <ul className={`dropcategryTabs ${item?'active':''}`}>                            
                                            {categoryList?.map((d,k)=>(
                                                k >= 5 && (
                                                    <li key={k}><a onClick={()=>{
                                                        handleCategory(d?._id)
                                                        setIsActive(current => !current)
                                                    }}>{d?.name}</a></li>
                                                )
                                            ))}
                                        </ul>                            
                                    </>
                                )}                        
                            </div>
                        </div>
                        <div className='categroylistWrp mobilView'>
                        <div onClick={()=>setIsActive(current => !current)} className={`homemenuWRP bar trant ${item?'active':''}`}></div>
                            <ul className='topfiveCate'>
                                <li className={commonData?.categoryId === '' ? 'active': ''}><a onClick={()=>handleCategory('')}>All</a></li>
                                {categoryList?.map((d,k)=>(
                                    k < 0 && (
                                    <li key={k} className={commonData?.categoryId === d?._id ? 'active': ''}><a onClick={()=>handleCategory(d?._id)}>{d?.name}</a></li>
                                    )
                                ))}                                
                            </ul>
                            <div className="tabsMoreBx">
                                {categoryList?.length > 1 && (
                                    <>
                                        <h3  onClick={()=>setIsActive(current => !current)}>More <span><iconify-icon icon="material-symbols:keyboard-arrow-down"></iconify-icon></span></h3>
                                        <ul className={`dropcategryTabs ${item?'active':''}`}>                            
                                            {categoryList?.map((d,k)=>(
                                                k >= 0 && (
                                                    <li key={k}><a onClick={()=>{
                                                        handleCategory(d?._id)
                                                        setIsActive(current => !current)
                                                    }}>{d?.name}</a></li>
                                                )
                                            ))}
                                        </ul>                            
                                    </>
                                )}                        
                            </div>
                        </div>
                        <div className="storeprocardlist ">
                            <div className="row ">
                                {pageData?.list?.map((d,k)=>(
                                    <div className="col-lg-3 col-md-4 col-sm-6" key={k}>
                                    <div className="aboutproimgwrp">
                                        <div className="aboutproimgcon store mb-1">
                                            <Link to={`/vendor/product/view/${d?._id}`}><img className='image-fluid storebigimg' src={d?.images[0]?.fullUrl || "/assets/images/backimgbig.svg"} alt="image" /></Link>
                                            {d?.label?.title && (
                                                <div className="storepropopular">{d?.label?.title}</div>
                                            )}
                                            
                                            <div className="favcataimg">
                                                <WishlistBtn productData={d} action={()=>{
                                                        // let search = common.getProductFilter();
                                                        // const queryString = new URLSearchParams(search).toString();
                                                        // navigate(`/vendor/product/list/${vendorId}?` + queryString)
                                                        getPageData()
                                                    }}
                                                isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)&&(isConnected)}
                                                />
                                            </div>
                                        </div>
                                        <div className='storeProductListContBx'>
                                            {d?.totalReviews > 0 ? (
                                                <Link to={`/review-rating/${d?._id}`}> <div className="dashprofilerating storepro"> <div> {d?.averageRatings} <img src="/assets/images/singlestar.svg" alt="img" />  </div><span>{d?.totalReviews} Reviews</span> </div></Link>
                                            ):(
                                                <a> <div className="dashprofilerating storepro"><span>No Reviews</span> </div></a>
                                            )}
                                            
                                            <Link to={`/vendor/product/view/${d?._id}`}><div className="storeproheadtxt">{d?.title}</div></Link>
                                            <div className="storepromoq mb-1">MOQ - <span>{d?.minOrderQt} | </span>SKU - <span>{d?.sku}</span></div>
                                            <div className="storepromoq mb-1">
                                                Price - {d?.mrp > 0 && <span className="mrpPrice litmrp">₹{d?.mrp || 0} </span>}
                                                <span>₹{d?.basicPrice} (
                                                    {d?.priceType === 'negotiable' && 'Negotiable'}
                                                    {d?.priceType === 'fixed' && 'Fixed'}
                                                    )</span>
                                                    
                                            </div>
                                            <div className="storepromoq mb-1">
                                                {d?.typeOfAvailability === 'onDemand' && 'On Demand Manufacturing'}
                                                {d?.typeOfAvailability === 'ready' && 'Readily Stock'}
                                            </div>
                                            <div className="storepromoq mb-1">GST - <span>{d?.gst?.value}% | </span>HSN - <span>{d?.hsn}</span></div>
                                            <div className="storebtn">
                                                {/* <Link to="#" ><div>Send Inquiry</div></Link> */}
                                                <ProductListSendInquiry productData={d}  action={()=>{
                                                    // let search = common.getProductFilter();
                                                    // const queryString = new URLSearchParams(search).toString();
                                                    // navigate(`/vendor/product/list/${vendorId}?` + queryString)
                                                    getPageData()
                                                }}
                                                isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (isConnected) }
                                                />
                                                <CartBtn productData={d}  action={()=>{
                                                    // let search = common.getProductFilter();
                                                    // const queryString = new URLSearchParams(search).toString();
                                                    // navigate(`/vendor/product/list/${vendorId}?` + queryString)
                                                    getPageData()
                                                    getCart()
                                                }}
                                                isDisabled={(isConnected)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                ))}
                            </div>
                        </div>
                        
                        {/* <div className="col-lg-3" key={k}>
                                    <div className="aboutproimgwrp mb-3">
                                        <div className="aboutproimgcon store mb-1">
                                            <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                            <div className="storepropopular">Popular</div>
                                            <div className="favcataimg">
                                                <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                            </div>
                                        </div>
                                        <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                        <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                        <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                        <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                        <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                        <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                        <div className="storebtn">
                                            <ProductListSendInquiry />
                                            {cartImg ? (
                                                <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                            ) : (
                                                <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                            )}
                                        </div>
                                    </div>
                                </div> */}
                        
                        {/* <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=""

                        >
                            <Tab eventKey="promoter" title="Category 1">
                                <div className="storeprocardlist ">
                                    <div className="row ">
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="business" title="Category 2">
                                <div className="storeprocardlist ">
                                    <div className="row ">
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="sales" title="Category 3" >
                                <div className="storeprocardlist ">
                                    <div className="row ">
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="abouts" title="Category 4 " >
                                <div className="storeprocardlist ">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="company" title="Category 5">
                                <div className="storeprocardlist ">
                                    <div className="row ">
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="aboutproimgwrp mb-3">
                                                <div className="aboutproimgcon store mb-1">
                                                    <Link to="/vendor/product/view/:productId"> <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />  </Link>
                                                    <div className="storepropopular">Popular</div>
                                                    <div className="favcataimg">
                                                        <img className='image-fluid ' src="assets/images/favgreen.svg" alt="image" />
                                                    </div>
                                                </div>
                                                <Link to="/review-rating"> <div className="dashprofilerating storepro"> <div> 4 <img src="assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link>
                                                <Link to="/vendor/product/view/:productId"> <div className="storeproheadtxt">Product Name</div></Link>
                                                <div className="storepromoq mb-1">MOQ - <span>50 | </span>MOQ - <span>626265dw</span></div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storepromoq mb-1">On Demand Manufacturing  </div>
                                                <div className="storepromoq mb-1">Price - <span>₹2,500 (Negotiable)</span></div>
                                                <div className="storebtn">
                                                    <ProductListSendInquiry />
                                                    {cartImg ? (
                                                        <img onClick={() => setCartImg(false)} className='img-fluid' src="assets/images/greencart.svg" alt="image" />

                                                    ) : (
                                                        <img onClick={() => setCartImg(true)} className='img-fluid' src="assets/images/cartwhite.svg" alt="image" />

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs> */}
                    </div>                  

                </div>
                {pageData?.totalItemsCount > 12 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
