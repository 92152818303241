import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

const VideoPlayerPopup = ({ id, name }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <button className="btn-primary" onClick={() => setOpen(true)}>{name}</button>
      <ModalVideo channel='youtube' autoplay={true} isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
      <div className="youimgcon">
        <img className='img-fluid' onClick={() => setOpen(true)} src="assets/images/youtube.svg" alt="image" />
      </div>
    </>
  )
}

export default VideoPlayerPopup