import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Moment from 'react-moment';
import { useLocation, useNavigate } from 'react-router-dom';
import PageModule from '../components/pagination/pagination';
import authAxios from '../services/authAxios';
import common from '../services/common';
import { UserContext } from '../context/theme';

export default function Notification() {
    const { socket, setNotificationData } = useContext(UserContext)
    const {id} = socket
    const calendarStrings = {
        lastDay : '[Yesterday at] LT',
        sameDay : 'LT',
        nextDay : '[Tomorrow at] LT',
        lastWeek : '[Last] dddd [at] LT',
        nextWeek : 'dddd [at] LT',
        sameElse : 'L'
    };
    let navigate= useNavigate();
    const history = useLocation()
    const commonData = common.getFilter()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: ""
    }
    const getPageData = async() => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/panelNotifications/pagin`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            markAsSeenNoti(resData)
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount
            })
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    const markAsSeenNoti = async(data) => {
        let dataNofication = JSON.parse(JSON.stringify(data));
        dataNofication = dataNofication?.filter((d)=>d?.seen === false)?.map((d)=>d?._id) || []
        let newpostdata = {
            notificationIds:dataNofication
        }
        if(dataNofication?.length > 0){
            common.loader(true);
            await authAxios({
                method: "POST",
                url: `/c/panelNotifications/markAsSeen`,
                data: newpostdata
            }).then((res)=>{
                if(id){
                    socket.emit("panelNotifications", {  });
                    socket.on('panelNotifications', (data) => {
                        setNotificationData(data || [])
                    });
                }
                getPageData()
            }).catch((error) => {
                common.error(error);
            })
            common.loader(false);
        }
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getActiveFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/notification?${queryString}`);
        }
    }
    useEffect(()=>{
        getPageData()
    },[history])


    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Notification</h1>  </div>
                </div>
                <div className="ratingwrp noti">
                    {pageData?.list?.map((d,k)=>(
                        <div className="ratingcon" key={k}>
                            <div className="ratimgimgwrp notifcWrp d-flex">
                                <div className="ratimgimgcon">
                                    
                                    <div className="ratingimgheadcon p-0 d-flex mb-2 p-0">
                                        {!d?.seen && (
                                            <img className='image-fluid notifiTic mr-2' src="/assets/images/greendot.svg" alt="image" />
                                        )}
                                        <div className="ratingimghead"><h2>{d?.title}</h2></div>
                                    </div>
                                    <div className="ratingimgpara p">{d?.content}</div>
                                </div>
                                <div className="notitimezone mb-2">
                                    <Moment calendar={calendarStrings}>{d?.createdAt}</Moment>
                                </div>

                            </div>                            
                        </div>
                    ))}
                    {pageData?.totalItemsCount > 10 && (
                        <div className="pagienwrparp">
                            <PageModule
                                totalItems={pageData?.totalItemsCount}
                                itemsPerPage={pageData?.itemsCountPerPage}
                                currentPage={pageData?.activePage}
                                range={3}
                                theme="paging-4"
                                pageChange={(page) => {
                                    pageHasChanged(page);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
