import { convert } from 'html-to-text';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import UnauthAxios from '../services/unauthAxios';


export default function FaqFooter() {
    const [faqs, setFaqs] = useState([]);
    function getData() {
        // common.loader(true);
        UnauthAxios({
            method: "GET",
            url: `/c/information/faqs/getAll`,
        }).then((res) => {
            setFaqs(res?.data?.data || []);
        }).catch((error) => {
            common.error(error)
        });
        // common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <>
        <div className="logincontentCON terms">
                <div className="faqaccorcon pt-0 m-0 faqgrpbx">
                    <Accordion>
                        {faqs.map((faq,k) => (
                            k < 5 && (
                            <Accordion.Item eventKey={k} key={k}>
                                <Accordion.Header className='faqacchead '><p>{faq?.question}</p></Accordion.Header>
                                <Accordion.Body className='faqpara'>
                                    <div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: faq?.answer }} />
                                    
                                </Accordion.Body>
                            </Accordion.Item>
                            )
                        ))}
                    </Accordion>
                </div>               
            </div>
        </>
    )
}
