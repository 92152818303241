import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import apiFunc from '../../services/api';
import ROOT_URL from '../../services/api-url';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import AsyncSelect from 'react-select/async';
import UnauthAxios from '../../services/unauthAxios';

const SearchCity = (props) => {
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])
    
    const{value, name, onBlur, onChange, remove,category} = props;
    const { Option } = components;
    const getTags = (e) => {
    let values = e || ''
        let postValue = {
            searchString:values || '',
        }
        return authAxios({
            method:'POST',
            url:'/c/counts/vendorCities',
            data:postValue
        }).then((res)=>{
            let resData = res?.data?.data;
                resData = resData?.map((data)=>{
                let firRes = {
                    label:data,
                    value:data,
                };
                return firRes
            })
            setOptions(resData);

            return resData;
        }).catch((error) => {
            // common.error(error);
        })

        
    }
    const loadOptions = (value, callback) => {
        getTags(value).then((res)=>{
            callback(res);
        })
    };
    const handleChange = (e) => {
         onChange({
            target:{
                name:name,
                value:e.value
            }
         });
        setSelected(e);
    }
    const assignValue = async(value)=>{
        let data = await getTags(value);
        let slOpt = data?.filter((d)=>(d.label == value))
        setSelected(slOpt);
    }

    useEffect(()=>{
        if(value){
            assignValue(value)
        }else{
            setSelected([])
        }
        
    },[value])
    // const IconOption = props => (
    //     <Option {...props}>
    //       <span className='qsnNotype'>{props.data.questId} </span>  
    //       {props.data.label}
    //     </Option>
    // );
  return (
    <>
        <AsyncSelect
            defaultOptions
            placeholder="Select City"
            value={selected}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.value}
           loadOptions={loadOptions}
            onBlur={(e)=>onBlur(e)} onChange={(e)=>handleChange(e)}
            // components={{ Option: IconOption }}
      />
    </>
  )
}

export default SearchCity