import React from 'react'
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import common from '../../services/common';

export default function CustomerPromoter({userInfo, type, vendorConnected}) {
    const [open, setOpen] = useState(false);
    
    return (
        <>
            <div className="cuspromoterWRp">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='cusprometerGrp'>                           
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Contact Name</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">{userInfo?.contactName || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Pincode</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">{userInfo?.pinCode || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Designation</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">
                                    {userInfo?.designationType === 'Other' ? (
                                        userInfo?.designationOther || 'N/A'
                                    ) : (
                                        userInfo?.designation?.title || 'N/A'
                                    )}
                                </div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">City</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">{userInfo?.city || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Business Email</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">
                                    {vendorConnected ? (userInfo?.primaryEmail || 'N/A') : (common.protectEmail(userInfo?.primaryEmail) || 'N/A')}
                                </div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">State</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">{userInfo?.state || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Alternative Email</div>
                                <span >:</span>
                                <div className="cusnamehead cusname">
                                    {vendorConnected ? (userInfo?.alternateEmail || 'N/A') : (common.protectEmail(userInfo?.alternateEmail) || 'N/A')}
                                </div>
                            </div>                             
                            {vendorConnected && (
                                !open && (
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead cusname" onClick={() => setOpen(!open)}><span>View More</span></div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
                {vendorConnected && (
                    <Collapse in={open}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cusnamehead cusname mt-3 mb-1">Promoter’s Contact</div>
                        </div>
                        <div className="col-lg-12">
                            <div className="cusprometerGrp">
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Primary Number</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.primaryMobileNo || 'N/A'}</div>
                                </div>                            
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Landline Number</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.landLineNo || 'N/A'}</div>
                                </div>
                                {userInfo?.secondaryMobileNo?.map((d,k)=>(
                                    d !== undefined && (
                                        <div className="cuspromoterlist" key={k}>
                                            <div className="cusnamehead">
                                                {k===0 && 'Secondary Number'}
                                                {k===1 && 'Third Number'}
                                                {k===2 && 'Fourth Number'}
                                            </div>
                                            <span >:</span>
                                            <div className="cusnamehead cusname">{d || 'N/A'}</div>
                                        </div>
                                    )
                                ))}
                                <div className="cuspromoterlist">
                                {open && (                                    
                                    <div className="cusnamehead cusname" onClick={() => setOpen(!open)}><span>View Less</span></div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </Collapse>
                )}
            </div>
        </>
    )
}
