import React, { Suspense, useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import Loader from "../components/loader/loader";
import { UserContext } from "../context/theme";
import SiderMenu from "./siderMenu";
import Header from "./header";
import FooterUnAuth from "../unAuth/footerUnAuth";
import ContactUs from "../pages/contactUs";
import TermCondition from "../pages/termCondition";
import PrivacyPolicy from "../pages/privacyPolicy";
import ActiveSubcription from "../pages/dashboard/activeSubcription";
import QuickTutorial from "../pages/dashboard/quickTutorial";
import ProfileCustomer from "../pages/profileCustomer/profileCustomer";
import ReviewRating from "../pages/reviewRating";
import ViewStore from "../pages/dashboard/viewStore";
import StoreProductList from "../pages/dashboard/storeProductList";
import StoreProductView from "../pages/dashboard/storeProductView";
import AboutUs from "../pages/aboutUs";
import Faq from "../pages/faq";
import Notification from "../pages/notification";
import CreateUser from "../pages/userRole/createUser";
import Chart from "../pages/reports/chart";
import DiscoverVendor from "../pages/manageVendor/discoverVendor";
import ActiveVendor from "../pages/manageVendor/activeVendor";
import VendorRequest from "../pages/manageVendor/vendorRequest";
import Redirect from "./redirect";
import FavVendor from "../pages/rating/favVendor";
import FavProduct from "../pages/rating/favProduct";
import FavCatalogue from "../pages/rating/favCatalogue";
import UserList from "../pages/userRole/userList";
import { socketConnectionURL } from "../services/api-url";
import io from "socket.io-client";

const Home = React.lazy(() => import("../pages/customerTheme/home"));
const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"));
const Store = React.lazy(() => import("../pages/manageVendor/store"));
const Categories = React.lazy(() => import("../pages/manageVendor/categories"));
const Products = React.lazy(() => import("../pages/manageVendor/products"));
const ProductCatalouge = React.lazy(() => import("../pages/manageVendor/productCatalouge"));
const SingleUpload = React.lazy(() => import("../pages/manageVendor/singleUpload"));
const BulkUpload = React.lazy(() => import("../pages/manageVendor/bulkUpload"));
const ProductList = React.lazy(() => import("../pages/manageVendor/productList"));
const ProductCatalougeList = React.lazy(() => import("../pages/manageVendor/productCatalougeList"));
const Profile = React.lazy(() => import("../pages/profile/profile"));
const Help = React.lazy(() => import("../pages/helpdesk/help"));
const HelpTicketView = React.lazy(() => import("../pages/helpdesk/helpTicketView"));
const HelpTicketEdit = React.lazy(() => import("../pages/helpdesk/raiseTicket"));
const StoreProductCatalogue = React.lazy(() => import("../pages/dashboard/storeProductCatalogue"));
const MyCart = React.lazy(() => import("../pages/mycart/cart"));
// const FaqFooter = React.lazy(() => import("../pages/faqFooter"));


const DashLayout = ({ Component }) => {
    const context = useContext(UserContext);
    const {auth, setSocket, userInfo} = context
    const authLocal = localStorage.getItem("token");
    useEffect(() => {
        if(auth && authLocal != undefined){
            const connection = io(`${socketConnectionURL}?type=customer&token=${authLocal}`, { transports: ["websocket", "polling"] });
            // console.log(connection)
            setSocket(connection || {})
            
            connection.on("connect", () => {
                // setIsConnected(true);
                // socket.emit("joinRoom", { ticketNumber: "" });
                // console.log(socket.id);
            });

            connection.on("disconnect", () => {
                // setIsConnected(false);
            });
            return () => {
                connection.off("connect");
                connection.off("disconnect");
            };
        }

        
    }, [auth, authLocal]);
    
    return (
        <>
            <div
                className={`page-wrapper 
					${context.Theme} 
					${context.ThemeColor} 
					${context.MiniSidebar ? "mini-sidebar" : ""}      
				`}
            >
                <ToastContainer />
                <Header />
                <div className="all-content-wrapper">
                    <SiderMenu />
                    <div className="mainContent">
                        <div className="container-fluid">
                            <Loader />

                            <Routes>
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/store" element={<Store />} />
                                <Route path="/categories" element={<Categories />} />
                                <Route path="/product" element={<Products />} />
                                <Route path="/productCatalouge" element={<ProductCatalouge />} />
                                <Route path="/bulkUpload" element={<BulkUpload />} />
                                <Route path="/singleUpload" element={<SingleUpload />} />
                                <Route path="/productList" element={<ProductList />} />
                                <Route path="/productcatalougelist" element={<ProductCatalougeList />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/help" element={<Help />} />
                                <Route path="/helpTicketView/:ticketId" element={<HelpTicketView />} />
                                <Route path="/raiseTicket" element={<HelpTicketEdit />} />
                                <Route path="/active-subcription" element={<ActiveSubcription />} />
                                <Route path="/quick-tutorial" element={<QuickTutorial />} />
                                <Route path="/active-vendor" element={<ActiveVendor />} />
                                <Route path="/profile-customer" element={<ProfileCustomer />} />
                                <Route path="/review-rating/:ratingId" element={<ReviewRating />} />
                                <Route path="/vendor/view/:vendorId" element={<ViewStore />} />
                                <Route path="/vendor/product/list/:vendorId" element={<StoreProductList />} />
                                <Route path="/vendor/product/view/:productId" element={<StoreProductView />} />
                                <Route path="/create-user" element={<CreateUser />} />
                                <Route path="/edit-user/:userId" element={<CreateUser />} />
                                {/* <Route path="/faq" element={<Faq />} /> */}
                                <Route path="/notification" element={<Notification />} />
                                <Route path="/chart" element={<Chart />} />
                                <Route path="/discover-vendor" element={<DiscoverVendor />} />
                                <Route path="/vendor-request" element={<VendorRequest />} />
                                <Route path="/vendor/catalogue/:vendorId" element={<StoreProductCatalogue />} />
                                <Route path="/favorite/vendor" element={<FavVendor />} />
                                <Route path="/favorite/product" element={<FavProduct />} />
                                <Route path="/favorite/catalogue" element={<FavCatalogue />} />
                                <Route path="/user-list" element={<UserList />} />
                                <Route path="/my-cart" element={<MyCart />} />
                                {/* <Route path="/faqs" element={<FaqFooter />} /> */}
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                    </div>
                </div>
                <FooterUnAuth />
            </div>
        </>
    );
};
const MainLayout = ({ Component }) => {
    const context = useContext(UserContext);
    return (
        <>
            <div className={`page-wrapper ${context.Theme} ${context.ThemeColor} pageLogin`}>
                <div className="login-wrapper">
                    <ToastContainer />
                    <Loader />
                    {/* <div className="all-content-wrapper"> */}
                    {/* <TopNavbar/>  */}
                    <Header />
                    <SiderMenu />
                    <div className="saledeskWRP">
                        
                        <Routes>
                            <Route path="/" element={<Home />} />
                                <Route path="/about-us" element={<AboutUs />} />
                                    <Route path="/contact-us" element={<ContactUs />} />
                                        <Route path="/terms-conditions" element={<TermCondition />} />
                                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                            <Route path="/faqs" element={<Faq />} />
                                        <Route path="/sign-in" element={<Redirect to="/dashboard" />} />
                                    <Route path="/verifyOTP" element={<Redirect to="/dashboard" />} />
                                <Route path="/signUp" element={<Redirect to="/dashboard" />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        {/* </div> */}
                    </div>
                    <FooterUnAuth />
                </div>
            </div>
        </>
    );
};
function Layout() {
    const [layoutType, setLayoutType] = useState(false);
    const { setGlobalLayout } = useContext(UserContext);
    const { pathname } = useLocation();
    const publicPath = ["/", "/about-us", "/contact-us", "/terms-conditions", "/privacy-policy", "/faqs", "/sign-in", "/verifyOTP", "/signUp"];
    useEffect(() => {
        let check = publicPath.includes(pathname);
        setGlobalLayout(check)
        setLayoutType(check);
    }, [pathname]);
    return (
        <>
            <Suspense fallback={<Loader />}>{layoutType ? <MainLayout /> : <DashLayout />}</Suspense>
        </>
    );
}

export default Layout;