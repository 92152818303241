import React from 'react'
import { subDays, addYears } from 'date-fns';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { UserContext } from '../../context/theme';

export default function ProductListSendInquiry({action , productData, renderHtml, isDisabled}) {
    const {userInfo} = useContext(UserContext)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if(isDisabled){
            setShow(true);
        }else{
            setShow(false);
        }
        
    }

    const formik = useFormik({
        initialValues: {
            requiredQuantity: "",
            estimateDeliveryDate: "",
            inquiryNote: ""

        },
        validationSchema: Yup.object({
            requiredQuantity: Yup.string().required("Please enter Required Quantity"),
            estimateDeliveryDate: Yup.string().required("Please select Delivery Date"),
            inquiryNote: Yup.string().required("Please enter Inquiry Note"),           
        }),

        onSubmit: values => {
            // {
            //     "productId": "639c1bf53e5a6608b91e3361",
            //     "productName": "Red Shoes",
            //     "companyName": "M&H Trading",
            //     "date": "2022-12-30T04:00:19.751+00:00",
            //     "requiredQuantity": "12",
            //     "estimateDeliveryDate": "2023-01-12T04:00:19.751+00:00",
            //     "inquiryNote": "How to negotiate the price?"
            // }
            addToInquiry(values)
        },
    });
    // console.log(productData);
    const addToInquiry = async (values) => {
        let postData = values
        values.productId = productData?._id
        values.productName = productData?.title
        values.companyName = userInfo?.companyName
        values.date = new Date()
        common.loader(true)
		await authAxios({
			method: "POST",
			url: `/c/productInquiry`,
            data:postData
		}).then((res) => {
            formik.resetForm()
            setShow(false)
			toast.success(res?.data?.message || [])
            if(action){
              action()
            }
		}).catch((error) => {
			common.error(error)
		});
        common.loader(false)
	}
    return (
        <>
           
            <div className='sendBtn' onClick={handleShow} >
                {renderHtml ? (
                    <div className={`${!isDisabled ? 'disabled' : ''}`}>
                        {renderHtml}
                    </div>
                ):(
                    <button className={`theme-btn-1 ${!isDisabled ? 'disabled' : ''}`}>Send Inquiry</button>
                )}
            </div>
                <Modal className='categorymodel' show={show} onHide={handleClose}>

                    <Modal.Body>
                        {/* <div className='filterprolist'> */}
                        <form onSubmit={formik.handleSubmit}>
                            <div className="categorycmodelhead">Send Inquiry</div>
                            <div className="loginsinphone ">
                                <div className="filtercategory mb-3">
                                    <div className="loginsinphonewrp sign">
                                        <input {...formik.getFieldProps("requiredQuantity")} type="text" className="form-control" placeholder="Required Quantity" onChange={(e) => formik.setFieldValue("requiredQuantity", common.numberReplace(e.target.value))} />
                                        <ErrorMessage formik={formik} name="requiredQuantity" />
                                    </div>
                                </div>
                                <div className="filtercategory mb-3">
                                    <div className="loginsinphonewrp sign calendrbx">
                                        <iconify-icon icon="material-symbols:calendar-month"></iconify-icon>
                                        <DatePicker
                                            {...formik.getFieldProps("estimateDeliveryDate")}
                                            selected={formik?.values?.estimateDeliveryDate && new Date(formik?.values?.estimateDeliveryDate)}
                                            onChange={(date) => {
                                                formik.setFieldValue("estimateDeliveryDate", new Date(date || new Date()));
                                            }}                                            
                                            minDate={subDays(new Date(), -1)}
                                            maxDate={addYears(new Date(), 100)}
                                            showYearDropdown={10}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Est. Delivery Date"
                                                                                     
                                        />
                                    <ErrorMessage formik={formik} name="estimateDeliveryDate" />
                                    </div>
                                </div>
                                <div className="filtercategory mb-3">
                                    <div className="loginsinphonewrp sign">
                                    <div className="textAryaBox">
                                        <textarea {...formik.getFieldProps("inquiryNote")} rows="3" placeholder="Inquiry Note"></textarea>
                                    </div>   
                                    <ErrorMessage formik={formik} name="inquiryNote" />
                                    </div>
                                </div>

                                <div className="filterprolistbtncon justify-content-center mb-0">
                                    <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Send</button></div>
                                </div>
                            </div>

                        </form>
                        {/* </div> */}
                    </Modal.Body>

                </Modal>
        </>
    )
}
