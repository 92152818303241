import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductListSendInquiry from "../../components/modals/productListSendInquiry";
import ReviewModal from "../../components/modals/review";
import CartBtn from "../../components/store/cartBtn";
import WishlistBtn from "../../components/store/wishlistBtn";
import { UserContext } from "../../context/theme";
import authAxios from "../../services/authAxios";
import common from "../../services/common";
import NotFound from "../../components/notFound/notFound";

export default function StoreProductView() {
    const [showMore, setShowMore] = useState(false);
    const { productId } = useParams();
    const [resData, setResData] = useState({});
    const [resDataRelated, setResDataRelated] = useState([]);
    const [currentImage, setCurrentImage] = useState();
    const [isConnected, setVendorConnected] = useState(false);
    const {employData, getCart} = useContext(UserContext)
    const getVendorInfo = async (vendorId) => {
        common.loader(true);
        await authAxios({
            method: "GET",
            url: `/c/manageVendors/vendorProfile/${vendorId}`,
        }).then((res) => {
            let resData = res?.data?.data;
            setVendorConnected(resData?.isConnected || false)
        })
        .catch((error) => {
            common.error(error);
        });
        common.loader(false);
    };
    const getData = async (id) => {
        await authAxios({
            method: "GET",
            url: `/c/products/get/${id}`,
        })
            .then((res) => {
                let resss = res?.data?.data || {};
                setResData(resss);
                resss?.userId?._id && getVendorInfo(resss?.userId?._id)
                setCurrentImage({
                    url: resss?.images[0]?.fullUrl || "",
                    type: "image",
                });
            })
            .catch((error) => {
                console.log(error);
                common.error(error);
            });
    };
    const getDataRelated = async (id) => {
        await authAxios({
            method: "GET",
            url: `/c/products/similarProducts/${id}`,
        }).then((res) => {
            let resss = res?.data?.data || [];
            setResDataRelated(resss)
        })
        .catch((error) => {
            console.log(error);
            // common.error(error);
        });
    };
    const productViewCount = async (id) => {
        await authAxios({
            method: "GET",
            url: `/c/products/viewProduct/${id}`,
        })
            .then((res) => {
                getData(productId)
            })
            .catch((error) => {
                console.log(error);
                // common.error(error);
            });
    };

    useEffect(() => {
        productId && getData(productId);
        productId && getDataRelated(productId);
    }, [productId]);

    useEffect(() => {
        productId && productViewCount(productId);
    }, [productId]);
    // console.log(resDataRelated);
    if(resData?.isDeleted !== false){
        return <NotFound />
    }

    return (
        <>
            <div className="activecuswrp view p-0">
                <div className="dashheadercon mycarthd">
                    <div className="dashheadtext resmode">
                        <h1>Product</h1>
                    </div>
                    <div className="dashstorelink ml-lg-3 ml-md-3 ml-sm-0 ml-0">
                        <Link to={`/vendor/product/list/${resData?.userId?._id}`}>Product List</Link>
                    </div>
                </div>
                <div className="storeproviewwrp ">
                    <div className="row justify-content-between">
                        <div className="col-lg-6 col-md-6">
                            <div className="storeproviewcon">
                                <div className="aboutproimgcon profile mb-1">
                                    {currentImage?.type === "image" && <img className="img-fluid" src={currentImage?.url || '/assets/images/backimgbig.svg'} alt="image" />}

                                    {currentImage?.type === "youtube" && <iframe width="560" height="315" src={`https://www.youtube.com/embed/${currentImage?.url}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}

                                    {resData?.label?.title && <div className="storepropopular">{resData?.label?.title}</div>}
                                </div>
                                <div className="productgallimmgcon view">
                                    {resData?.images?.map((d, k) => (
                                        <div
                                            className="storeimgcon"
                                            key={k}
                                            onClick={() =>
                                                setCurrentImage({
                                                    type: "image",
                                                    url: d?.fullUrl || "",
                                                })
                                            }
                                        >
                                            <img className="img-fluid" src={d?.fullUrl} alt="image" />
                                        </div>
                                    ))}
                                    {resData?.videoUrls?.map((d, k) => (
                                        d &&
                                        <div
                                            className="storeimgcon"
                                            key={k}
                                            onClick={() =>
                                                setCurrentImage({
                                                    type: "youtube",
                                                    url: d?.split("?v=")[1] || "",
                                                })
                                            }
                                        >
                                            <div className="yutubThem"><img className="img-fluid" src="/assets/images/youtubeplay.svg" alt="#"></img></div>
                                            <img className="img-fluid" src={`https://img.youtube.com/vi/${d?.split("?v=")[1]}/0.jpg`} alt="image" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="storeproviewcon pd">
                                <div className="viewimgpro">
                                    <WishlistBtn  productData={resData} action={()=>getData(productId)} isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (isConnected)} />
                                    {/* <img className="img-fluid" src="/assets/images/disableheart.svg" alt="image" /> */}
                                </div>
                                <div className="viewcategoryname">{resData?.categoryId?.name}</div>
                                <div className="viewcategoryname pd">
                                    <h1>{resData?.title}</h1>
                                </div>
                                <div className="viewcategoryname">{resData?.userId?.companyName}</div>

                                <div className="viewratingcon">
                                    {resData?.totalReviews > 0 ? (
                                        <Link to={`/review-rating/${resData?._id}`}>
                                            {" "}
                                            <div className="dashprofilerating view">
                                                {" "}
                                                {resData?.averageRatings} <img src="/assets/images/singlestar.svg" alt="img" /> <span>{resData?.totalReviews} Reviews</span>{" "}
                                            </div>
                                        </Link>
                                    ):(
                                        <div className="dashprofilerating view">No Review </div>
                                    )}
                                    
                                    {
                                        isConnected ? (
                                        !resData?.ratingsGiven && (
                                            <ReviewModal
                                                url={`/c/products/ratingReview/${resData?._id}`}
                                                productData={resData} 
                                                type="product"
                                                action={()=>getData(productId)} 
                                            />
                                        )):(
                                            <button type='button' className='givReviwBtn' variant="primary" ><div className='viewgivetxt'>Give Review Rating</div></button>
                                        )
                                    }
                                    {/* <div className="viewgivetxt">Give Review Rating</div> */}
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Brand Name</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{!resData?.brand?.brandName ? 'Generic':resData?.brand?.brandName}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Type of Product Availability</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.typeOfAvailability ==="onDemand" && "On Demand Manufacturing"}
                                            {resData?.typeOfAvailability ==="ready" && "Readily Stock"}
                                        </div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Stock Status</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.stockStatus}
                                        </div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    {(
                                        resData?.stockStatus === "Ready Stock" || 
                                        resData?.stockStatus === "Limited Stock" || 
                                        resData?.stockStatus === "Made to Order"
                                    ) && (
                                        <div className="cuspromoterlist w-100">
                                            <div className="cusnamehead ">Available Qty.</div>
                                            <span>:</span>
                                            <div className="cusnamehead cusname">{resData?.availableQt || '0'} pcs.</div>
                                        </div>
                                    )}
                                    {(
                                        resData?.stockStatus === "Arrive in Days"
                                    ) && (
                                        <div className="cuspromoterlist w-100">
                                            <div className="cusnamehead ">Number of days.</div>
                                            <span>:</span>
                                            <div className="cusnamehead cusname">{resData?.arriveInDays || '0'} days.</div>
                                        </div>
                                    )}

                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                            <div className="cusnamehead ">Minimum Order Quanity</div>
                                            <span>:</span>
                                            <div className="cusnamehead cusname">{resData?.minOrderQt} Unit</div>
                                        </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Country of Origin</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.originCountry === "madeInIndia" && 'Made In India'}   
                                            {resData?.originCountry === "imported" && 'Imported'}   
                                        </div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">SKU</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.sku}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead">HSN Code</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.hsn}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100 mb-2">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">GST</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.gst?.value || 0}%</div>
                                    </div>
                                </div>
                                
                               
                                
                                <div className="cusnamehead  storViewFull">Price (Exclusive of GST)</div>
                                <div className="viewpricepro">
                                {resData?.mrp > 0 && <span className="mrpPrice">₹{resData?.mrp || 0} </span>}
                                <span className="basicPrice">₹{resData?.basicPrice}</span>  
                                <span className="cusnamehead">({resData?.priceType === 'negotiable' && 'Negotiable'}{resData?.priceType === 'fixed' && 'Fixed'})</span>
                                </div>                             
                                {/* <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead">HSN Code</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.hsn}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">SKU</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.sku}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Minimum Order Quanity</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.minOrderQt} Unit</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Brand Name</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.brand?.brandName}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Country of Origin</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.originCountry === "madeInIndia" && "Made In India"}
                                            {resData?.originCountry === "imported" && "Imported"}
                                        </div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Type of Product Availability</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.typeOfAvailability === "onDemand" && "On Demand Manufacturing"}
                                            {resData?.typeOfAvailability === "ready" && "Readily Stock"}
                                        </div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Stock Status</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.stockStatus}</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Available Qty.</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.availableQt} pcs.</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">GST</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{resData?.gst?.value || 0}%</div>
                                    </div>
                                </div>
                                <div className="cuspromotercon w-100">
                                    <div className="cuspromoterlist w-100">
                                        <div className="cusnamehead ">Description</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">
                                            {resData?.description}
                                            <br /> <span>View More</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cusnamehead mb-2">Price (Exclusive of GST)</div>
                                <div className="viewpricepro">
                                    ₹{resData?.basicPrice}{" "}
                                    <span className="cusnamehead">
                                        ({resData?.priceType === "negotiable" && "Negotiable"}
                                        {resData?.priceType === "fixed" && "Fixed"})
                                    </span>
                                </div> */}
                                <div className="viewprobigbtn storProdViewBtn">
                                    <ProductListSendInquiry productData={resData} renderHtml={
                                        <button type="button">Send Inquiry</button>
                                    } action={()=>getData(productId)}
                                    
                                    isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (isConnected)}
                                     />
                                    <CartBtn productData={resData} renderHtml={
                                        <button type="button">{resData?.addedToCart ? 'Remove from cart' : 'Add to cart'}</button>
                                    } action={()=>{
                                        getData(productId)
                                        getCart()
                                    }}
                                    isDisabled={(isConnected)}
                                      />
                                </div>
                                <div className="cuspromotercon w-100 mt-4">
                                    <div className="cuspromoterlist d-block w-100 p-0">
                                    <div className="cusnamehead w-100 pb-2">Description :</div>
                                        {/* <span>:</span> */}
                                        <div className="cusnamehead cusname withDescris w-100 p-0">
                                            {showMore ? 
                                                (
                                                    <div className='cusnamehead cusname withDescris editorWrp p-0' dangerouslySetInnerHTML={{ __html:resData?.description || 'N/A' }} />
                                                ) : (
                                                    <>
                                                        <div className='cusnamehead cusname withDescris editorWrp p-0 d-flex' dangerouslySetInnerHTML={{ __html:`${common.trim(resData?.description)?.substring(0, 100)}${resData?.description?.length > 100 ? '...' :''}` || 'N/A' }} />
                                                    </>
                                                )}
                                                {/* `${resData?.description?.substring(0, 100)}${resData?.description?.length > 100 ? '...' :''}` */}
                                                {/* {resData?.description} */}
                                                {resData?.description?.length > 100 && (
                                                    <div><span onClick={() => setShowMore(!showMore)}>{showMore ? "View less" : "View more"}</span></div>
                                                )}
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashheadercon active mt-3">
                    <div className="dashheadtext resmode">
                        <h1>Similar Products</h1>
                    </div>
                </div>
                <div className="listcustomer prodectlistviewTabs mt-0">
                    <div className='productTabsCategy'>
                        <div className="storeprocardlist ">
                            <div className="row ">
                                {resDataRelated?.map((d,k)=>(
                                    <div className="col-lg-3 col-md-4 col-sm-6" key={k}>
                                    <div className="aboutproimgwrp">
                                        <div className="aboutproimgcon store mb-1">
                                            <Link to={`/vendor/product/view/${d?._id}`}><img className='image-fluid storebigimg' src={d?.images[0]?.fullUrl || "/assets/images/backimgbig.svg"} alt="image" /></Link>
                                            {d?.label?.title && (
                                                <div className="storepropopular">{d?.label?.title}</div>
                                            )}
                                            
                                            <div className="favcataimg">
                                                <WishlistBtn productData={d} action={()=>{
                                                    getDataRelated(productId)
                                                    }}
                                                isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)&&(isConnected)}
                                                />
                                            </div>
                                        </div>
                                        <div className='storeProductListContBx'>
                                            {d?.totalReviews > 0 ? (
                                                <Link to={`/review-rating/${d?._id}`}> <div className="dashprofilerating storepro"> <div> {d?.averageRatings} <img src="/assets/images/singlestar.svg" alt="img" />  </div><span>{d?.totalReviews} Reviews</span> </div></Link>
                                            ):(
                                                <a> <div className="dashprofilerating storepro"><span>No Reviews</span> </div></a>
                                            )}
                                            
                                            <Link to={`/vendor/product/view/${d?._id}`}><div className="storeproheadtxt">{d?.title}</div></Link>
                                            <div className="storepromoq mb-1">MOQ - <span>{d?.minOrderQt} | </span>SKU - <span>{d?.sku}</span></div>
                                            <div className="storepromoq mb-1">
                                                Price - 
                                                {d?.mrp > 0 && <span className="mrpPrice litmrp">₹{d?.mrp || 0} </span>} 
                                                <span>₹{d?.basicPrice} (
                                                    {d?.priceType === 'negotiable' && 'Negotiable'}
                                                    {d?.priceType === 'fixed' && 'Fixed'}
                                                    )</span>
                                            </div>
                                            <div className="storepromoq mb-1">
                                                {d?.typeOfAvailability === 'onDemand' && 'On Demand Manufacturing'}
                                                {d?.typeOfAvailability === 'ready' && 'Readily Stock'}
                                            </div>
                                            <div className="storepromoq mb-1">GST - <span>{d?.gst?.value}% | </span>HSN - <span>{d?.hsn}</span></div>
                                            <div className="storebtn">
                                                {/* <Link to="#" ><div>Send Inquiry</div></Link> */}
                                                <ProductListSendInquiry productData={d}  action={()=>{
                                                    getDataRelated(productId)
                                                }}
                                                isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (isConnected) }
                                                />
                                                <CartBtn productData={d}  action={()=>{
                                                    getDataRelated(productId)
                                                    getCart()
                                                }}
                                                isDisabled={(isConnected)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                ))}
                            </div>
                        </div>
                        
                        
                    </div>

                </div>
            </div>
            
        </>
    );
}
