import React, { useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import View from '../../components/filterDateField/view';
import Fav from '../../components/fav';
import common from '../../services/common';
import SearchFormik from '../../components/common/searchFormik';
import authAxios from '../../services/authAxios';
import PageModule from '../../components/pagination/pagination';
import WishlistBtn from '../../components/store/wishlistBtn';
import { UserContext } from '../../context/theme';



export default function FavVendor() {
    const navigate = useNavigate()
    const history = useLocation()
    const {employData, getStoreCounts} = useContext(UserContext)
    const commonData = common.getFavVendor()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: ""
    }
    const getPageData = async(isCheckPage) => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/manageVendors/favourite/pagin`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount
            })
            getStoreCounts()
            if(isCheckPage){
                let isNavChange = common.paginAfterChange('getFavVendor', 10, res?.data?.data?.length)
                if(isNavChange) {
                    navigate(isNavChange)
                }
            }
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getFavVendor();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/favorite/vendor?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
    },[history])



    return (
        <>
            <div className="dashheadermainWRP">
                <div className="dashmainWRP ">
                    <div className="dashheadercon ">
                        <div className="dashheadtext mb-2"><h1>My Favorite Vendors</h1></div>
                        <div className="prolistnavinp">
                            <img className='image-fluid' src="/assets/images/search.svg" alt="" />
                            <SearchFormik name="getFavVendor" onChange={(values)=>{
                                    let search = common.getFavVendor();
                                    search.page = 1
                                    search.searchString = values.searchString
                                    const queryString = new URLSearchParams(search).toString();
                                    navigate(`/favorite/vendor?` + queryString)
                            }}  />
                        </div>
                    </div>
                </div>
                <div className="prolisttableWRP">
                    <table className="table">
                        <thead className="prolistthead">
                            <tr>
                                <th className="align-middle">
                                    <div className="checkinp">
                                        {/* <input type="checkbox" className="form-check-input"/> */}
                                        <div className='tablechecktxtpic'> Picture   </div>
                                    </div>
                                </th>
                                <th className="align-middle">Company Name</th>
                                <th className="align-middle">Nature of Business</th>
                                <th className="align-middle">Primary Category</th>
                                <th className="align-middle">City </th>
                                <th className="align-middle">Rating </th>
                                <th className="align-middle">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageData?.list?.map((d,k)=>(
                                <tr key={k}>
                                    <td className="align-middle" data-th="Picture  -">
                                        <div className="checkinp">
                                            <div className='tablechecktxt'>
                                                <img className='img-fluid' src={d?.favouriteVendors?.profileImage[0]?.fullUrl || '/assets/images/default_user.jpg'} alt="image" />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle activename" data-th="Company Name  -">
                                        <Link to={`/vendor/view/${d?.favouriteVendors?._id}`} >{d?.favouriteVendors?.companyName}</Link>
                                    </td>
                                    <td className="align-middle" data-th="Nature of Business  -">{d?.favouriteVendors?.natureOfBusiness[0]?.title}</td>
                                    <td className="align-middle " data-th="Primary Category  -">
                                        <View data={d?.favouriteVendors?.primaryCategory?.map((d)=>d.title)} />
                                    </td>
                                    <td className="align-middle" data-th="City  -">{d?.favouriteVendors?.city}</td>
                                    <td className="align-middle" data-th="Rating  -">
                                        
                                        {d?.favouriteVendors?.averageRatings > 0 ? (
                                            <div className="d-flex align-items-center"><img src="/assets/images/singlestar.svg" alt="img" /><div className="ml-2"> {d?.favouriteVendors?.averageRatings} </div> </div>
                                        ):(
                                            <div className="d-flex align-items-center">-</div>
                                        )}
                                    </td>

                                    <td className="align-middle" data-th="Action  -">
                                        <div className="actionimg">
                                            <WishlistBtn 
                                                addUrl={`/c/manageVendors/favourite/${d?.favouriteVendors?._id}`}
                                                removeUrl={`/c/manageVendors/removeFavourite/${d?.favouriteVendors?._id}`}
                                                type="vendor"
                                                isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)}
                                                productData={d?.favouriteVendors} action={()=>{
                                                    getPageData(true)
                                            }}/>
                                        </div>
                                    </td>

                                </tr>
                            ))}

                            {/* <tr>
                                <td className="align-middle">
                                    <div className="checkinp">
                                        <div className='tablechecktxt'>  <img className='image-fluid' src="assets/images/girlimg.svg" alt="image" />
                                        </div>
                                    </div>
                                </td>
                                <td className="align-middle activename"> <Link to="/view-store">Saledesk PVT</Link></td>
                                <td className="align-middle">Retailer</td>
                                <td className="align-middle ">
                                    <View />
                                </td>
                                <td className="align-middle">Jaipur</td>
                                <td className="align-middle">
                                    <div className="d-flex align-items-center"><img src="assets/images/singlestar.svg" alt="img" /><div className="ml-2"> 4 </div> </div>
                                </td>

                                <td className="align-middle">
                                    <div className="actionimg">
                                        <Fav />
                                    </div>
                                </td>

                            </tr> */}
                        </tbody>
                    </table>
                </div>
                {pageData?.totalItemsCount > 10 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                // console.log(page)
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
