import React from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from 'react-router-dom';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import { toast } from 'react-toastify';


export default function ConfirmModal ({renderHtml,postData,  action, url, title, message, method,submitBtnName,cancelBtnName }) {
    const [show, setShow] = useState(false);
    const deleteGallery = async (id) => {
        common.loader(true);
        await authAxios({
            method: method || "POST",
            url: url,
            data:postData
        }).then((res) => {
            if(action){
                action();
            }
            toast.success(res?.data?.message);
            setShow(false)
        }).catch((error) => {
            common.error(error);
        });
        common.loader(false);
    };

    return (
        <>
           
            <a variant="primary" onClick={()=>setShow(true)} >
                {renderHtml}
            </a>
            <div className="dashheaadbtncon">
                <Modal className='categorymodel' show={show} onHide={()=>setShow(true)}>

                    <Modal.Body>
                        <div className='sussesbx'>
                            <div className='modlIcon'><iconify-icon icon="material-symbols:check"></iconify-icon></div>
                            <div className="categorycmodelhead">{title ? title : "Are you sure ?"}</div>
                            <div className="loginsinphone ">
                                <p>{message}</p>
                                <div className="filterprolistbtncon justify-content-center">
                                    <div className="loginsignbtn filterreset">
                                        <button type='button' onClick={()=>setShow(false)} className='theme-btn-1'>{cancelBtnName ? cancelBtnName : "Cancel"}</button>
                                        <button type='button' onClick={()=>deleteGallery()} className='theme-btn-2'>{submitBtnName ? submitBtnName : "Delete"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
