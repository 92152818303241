import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../formik/errorMessage';
import ReactInputMask from 'react-input-mask';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import authAxios from '../../services/authAxios';
import common from '../../services/common';
import { useNavigate } from 'react-router-dom';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function ProductListFilter({navigatePath, resetPath, brandData, maxPrice}) {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [filterApplied, setIsFilterApplied] = useState(false);
    const [priceMax, setPriceMax] = useState(0)
    const [productLabel, setProductLabel] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        let prc = Number(maxPrice) > 0 ? Number(maxPrice) : 100000
        setPriceMax(prc)
    },[show, maxPrice])

    
    const formik = useFormik({
        initialValues: {
            label: "",
            typeOfAvailability: "",
            brandName: "",
            status: "",
            minOrderQt: "",
            originCountry: "",
            priceRange: [0, 100000],

        },
        validationSchema: Yup.object({
        }),

        onSubmit: values => {
            let min = values?.priceRange[0]
            let max = values?.priceRange[1]
            let search = common.getProductFilter();
            search.page = 1
            search.status = values.status || ''
            search.priceRange = min + '-'+ max  || ''
            search.label = values?.label  || ''
            search.typeOfAvailability = values?.typeOfAvailability  || ''
            search.brandName = values?.brandName  || ''
            search.minOrderQt = values?.minOrderQt  || ''
            search.originCountry = values?.originCountry  || ''
            const queryString = new URLSearchParams(search).toString();
            if(search?.label || search?.typeOfAvailability || search?.brandName || search?.minOrderQt || search?.originCountry || search?.status || (values?.priceRange[0] > 0) || (values?.priceRange[1] < priceMax)){
                setIsFilterApplied(true)
            }else{
                setIsFilterApplied(false)
            }
            navigate(`/${navigatePath}?` + queryString)
            setShow(false)
        },
    });
    useEffect(()=>{
        let values = common.getProductFilter();
        formik.setFieldValue('label', values?.label || '')
        formik.setFieldValue('typeOfAvailability', values?.typeOfAvailability || '')
        formik.setFieldValue('brandName', values?.brandName || '')
        formik.setFieldValue('minOrderQt', values?.minOrderQt || '')
        formik.setFieldValue('originCountry', values?.originCountry || '')
        formik.setFieldValue('status', values?.status || '')
        let priceRange = values?.priceRange?.split('-')

        if(priceRange?.length <= 1){
            priceRange =[0,priceMax]
        }else{
            priceRange = priceRange?.map((d)=>Number(d))
            priceRange[1] = priceRange[1] <= priceMax ? priceRange[1] : priceMax
        }

        formik.setFieldValue('priceRange',  priceRange )
        if(values?.label || values?.typeOfAvailability || values?.brandName || values?.minOrderQt || values?.originCountry || values?.status || (priceRange[0] > 0) || (priceRange[1] < priceMax)){
            setIsFilterApplied(true)
        }else{
            setIsFilterApplied(false)
        }
	},[show, history, priceMax])

    const getProductLabel = async () => {
		await authAxios({
			method: "GET",
			url: `/c/dropdowns/productLabel`
		}).then((res) => {
			setProductLabel(res?.data?.data || [])
		}).catch((error) => {
			common.error(error)
		});
	}
    useEffect(()=>{
        getProductLabel()
    },[])

    return (
        <>
            {/* <div className="dashheaadbtncon"> */}
                <button className="prodlistfilter" onClick={handleShow}>
                    <div className="filterwrpprolist">
                    <div className="prolistnav ml-lg-3 ml-md-2 ml-sm-2 ml-2">
                        {show ? (
                            <img  className=' img-fluid ' src="/assets/images/listmenu.svg" alt="image" />
                        ): (
                            <img  className=' img-fluid ' src="/assets/images/filterchange.svg" alt="image" />
                        )}  
                        {filterApplied && <span className={`${filterApplied ? 'reddot':''}`}></span>}
                    </div>
                        {/* <div className="prolistnav ml-2">
                            <img className='image-fluid filterclickprolist' src="/assets/images/listmenu.svg" alt="image" />
                            {filterApplied && <span className={`${filterApplied ? 'reddot':''}`}></span>}
                        </div> */}
                    </div>

                </button>
                <Modal className='categorymodel' show={show} onHide={handleClose}>

                    <Modal.Body>
                        {/* <div className='filterprolist'> */}
                        <form onSubmit={formik.handleSubmit}>
                            <div className="categorycmodelhead">Filter</div>
                            <div className="loginsinphone ">
                                <div className="filtercategory mb-3">
                                    <select {...formik.getFieldProps("label")} className="form-select" aria-label="Default select example">
                                        <option value="">Select Product Label</option>
                                        {productLabel?.map((d,k)=>(
                                            <option key={k} value={d?._id}>{d?.title}</option>
                                        ))}
                                        
                                    </select>
                                    <ErrorMessage formik={formik} name="label" />
                                </div>
                                <div className="filtercategory mb-3">
                                    <select    {...formik.getFieldProps("typeOfAvailability")} className="form-select" aria-label="Default select example">
                                        <option value="">Select Type of Product Availiblity </option>
                                        <option value="onDemand">On Demand Manufacturing</option>
                                        <option value="ready">Readily Stock</option>
                                    </select>
                                    <ErrorMessage formik={formik} name="typeOfAvailability" />
                                </div>
                                <div className="filtercategory mb-3">
                                    <select    {...formik.getFieldProps("brandName")} className="form-select" aria-label="Default select example">
                                        <option value="">Select Branding</option>
                                        <option value="noneBrand">Generic Brand</option>
                                        {brandData?.map((d,k)=>(                                           
                                            <option key={k} value={d?._id}>{d?.brandName}</option>
                                        ))}
                                        
                                    </select>
                                    <ErrorMessage formik={formik} name="brandName" />
                                </div>
                                <div className="filtercategory mb-3">
                                    <select    {...formik.getFieldProps("originCountry")} className="form-select" aria-label="Default select example">
                                        <option value="">Select Country of Origin </option>
                                        <option value="madeInIndia">Made In India</option>
                                        <option value="imported">Imported</option>
                                    </select>
                                    <ErrorMessage formik={formik} name="originCountry" />
                                </div>
                                {/* <div className="filtercategory mb-3">
                                    <select    {...formik.getFieldProps("status")} className="form-select" aria-label="Default select example">
                                        <option value="">Select Product Status</option>
                                        <option value={true}>Active</option>
                                        <option value={false}>Inactive</option>
                                    </select>
                                    <ErrorMessage formik={formik} name="status" />
                                </div> */}
                                <div className="filtercategory mb-3">
                                    <div className="loginsinphonewrp sign">
                                        {/* <div className="loginsinphone"> */}
                                        <ReactInputMask mask="9999999999" maskChar={null} {...formik.getFieldProps("minOrderQt")} type="text" className="form-control" placeholder="Min. Order Quantity" />
                                        <ErrorMessage formik={formik} name="minOrderQt" />
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="prolistrange mb-5">
                                    <div className="prorangetext">Price Range</div>
                                    <div className='priceRangeWrp'>
                                            <div className='rangeBoxwrp'>
                                                <div className='rangeInput'>
                                                    <input type="text" readOnly value={`₹ ${formik.values.priceRange[0]}`} />
                                                </div>
                                                <div className='rangeInput'>
                                                    <input type="text" readOnly value={`₹ ${formik.values.priceRange[1]}`} />
                                                </div>
                                            </div>
                                        <Range
                                            min={0}
                                            max={priceMax}
                                            // step={formik.values.priceRange[1] > 1000 ? parseInt(formik.values.priceRange[1]/100) : 10}
                                            value={formik.values.priceRange}
                                            // tipFormatter={(value) => `${value}!`}
                                            onChange={(e)=>formik.setFieldValue('priceRange', e || [0, priceMax])}
                                        />
                                    </div>
                                </div>

                                <div className="filterprolistbtncon">
                                    <div className="loginsignbtn filterreset"><button type='button' onClick={()=>{
                                        navigate(resetPath)
                                        setIsFilterApplied(false)
                                        setShow(false)
                                        formik.resetForm()
                                    }} className='theme-btn-2'>Reset</button></div>
                                    <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Apply</button></div>
                                </div>
                            </div>

                        </form>
                        {/* </div> */}
                    </Modal.Body>

                </Modal>
            {/* </div> */}
        </>
    )
}
