import React, { useContext, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from '../context/theme';



function SiderMenu() {
    const history = useLocation()
    const { reset, counts, employData, sideMenuActive, setSideMenuActive } = useContext(UserContext)
    useEffect(()=>{
        setSideMenuActive(false)
        document.body.className = document.body.className.replace("overflowHidn", "");
    },[history])


    const toggleSidebar = ()=>{
        setSideMenuActive(!sideMenuActive)
        if(sideMenuActive){
            document.body.className = ""
        }else{
            document.body.className = "overflowHidn"
        }        
    }
    return (
        <>
            <div className={`left-sidebar-wrapper ${sideMenuActive?'active':''}`}>
                <div className={`leftSideBrBackGrnd ${sideMenuActive?'active':''}`} onClick={() =>toggleSidebar()}></div>
                <nav className="sidebar">
                    <div className="left-nav-menu">
                        <DropDownMenu>
                            <Menu path="/dashboard" title="Dashboard" icon="radix-icons:dashboard" iconifyIcon="true" />
                            <Dropdown title="Manage Vendors" icon="material-symbols:manage-accounts-outline" iconifyIcon="true">
                                {/* <Menu path="/store" title="Store" iconifyIcon="true" />
                                <Menu path="/categories" title="Categories" iconifyIcon="true" />
                                <Menu path="/product" title="Product" iconifyIcon="true" />
                                <Menu path="/productCatalouge" title="Product Catalouge" iconifyIcon="true" /> */}
                                <Menu path="/active-vendor" title={`Active Vendor (${counts?.activeVendorsCount || 0})`} iconifyIcon="true" />

                                {(employData?.role !=='sm' || employData?.role === 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                    <Menu path="/vendor-request" title={`Vendor Request (${counts?.vendorsRequestsCount || 0})`} iconifyIcon="true" />
                                )}
                                {(employData?.role !=='sm' || employData?.role === 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                    <Menu path="/discover-vendor" title={`Discover Vendors`} iconifyIcon="true" />
                                )}
                                
                                
                            </Dropdown>
                            {(employData?.role ==='sm' || employData?.role !== 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Dropdown title="My Favorites" icon="material-symbols:favorite-outline" iconifyIcon="true">
                                    <Menu path="/favorite/vendor" title={`Vendors (${counts?.favouriteVendorsCount || 0})`} iconifyIcon="true" />
                                    <Menu path="/favorite/product" title={`Products (${counts?.favouriteProductsCount || 0})`} iconifyIcon="true" />
                                    <Menu path="/favorite/catalogue" title={`Catalogue (${counts?.favouriteProductCataloguesCount || 0})`} iconifyIcon="true" />
                                </Dropdown>
                             )}
                             {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/user-list" title="Manage User & Roles" icon="material-symbols:manage-accounts-outline-rounded" iconifyIcon="true" />
                            )}
                            {(employData?.role !=='sm' || employData?.role === 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/chart" title="Reports & Analytics" icon="ri:bar-chart-2-line" iconifyIcon="true" />
                            )}
                            
                            {/* <Dropdown title="Manage Vendor" icon="clarity:store-line" iconifyIcon="true">
                                <Menu path="/store" title="Store" iconifyIcon="true" />
                                <Menu path="/categories" title="Categories" iconifyIcon="true" />
                                <Menu path="/product" title="Product" iconifyIcon="true" />
                                <Menu path="/productCatalouge" title="Product Catalouge" iconifyIcon="true" />
                                <Menu path="/active-vendor" title="Active Vendor" iconifyIcon="true" />
                                <Menu path="/vendor-request" title="Vendor Request" iconifyIcon="true" />
                                <Menu path="/discover-vendor" title="Discover Vendors" iconifyIcon="true" />
                            </Dropdown> */}
                            {/* {(employData?.role !=='sm' || employData?.role === 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/chart" title="Reports & Analytics" icon="bx:bar-chart-alt-2" iconifyIcon="true" />
                            )} */}
                            {(employData?.role !=='sm' || employData?.role === 'rm' || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/help" title="Help" icon="carbon:help" iconifyIcon="true" />
                            )}
                            {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/faqs" title="FAQ" icon="wpf:faq" iconifyIcon="true" />
                            )}
                            {((employData?.role !=='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined) && (
                                <Menu path="/profile" title="Profile" icon="gg:profile" iconifyIcon="true" />
                            )}
                            
                            
                            
                            {/* <Menu path="/active-subcription" title="My Subscription " icon="fa:diamond" iconifyIcon="true" /> */}
                            <li><a className="collapse has_menu " onClick={() => reset()}><span><iconify-icon icon="mdi:logout"></iconify-icon></span><span className="menu_txt">Sign Out </span></a></li>
                        </DropDownMenu>
                    </div>
                </nav>
            </div>
        </>
    );
}
export default SiderMenu;