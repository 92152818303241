import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from "yup";
import apiFunc from "./api";
import millify from "millify";
import authAxios from "./authAxios";
import moment from "moment";

const common = {
    milifying: (milifyData) => {
        return millify(milifyData, {
            units: ["", "K", "M", "B", "T", "P", "E"],
            space: true,
        });
    },
    loader: (type) => {
        if (type) {
            document.body.className = "loading_page";
        } else {
            document.body.className = document.body.className.replace("loading_page", "");
        }
    },
    
    base64Mime: (encoded) => {
        var result = null;
        if (typeof encoded !== "string") {
            return result;
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        if (mime && mime.length) {
            result = mime[1];
        }
        return result;
    },
    base64ReadFile: (base64String) => {
        var nonBlob = base64String == undefined ? 0 : base64String.length;
        var filetype = common.base64Mime(base64String);
        var datass = { size: nonBlob / 1000, type: filetype };
        return datass;
    },
    previewURL: (file) => {
        let URL = "";
        if (file != "") {
            URL = window.URL.createObjectURL(file);
        }
        return URL;
    },
    mineTypeValidate: (value) => {
        if (value == undefined || value == null) {
            return false;
        }
        let fileType = value.type;
        return (
            value && (fileType === "image/jpeg" || fileType === "image/bmp" || fileType === "image/png") /* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
        );
    },
    fileSizeValidate: (value, size) => {
        if (value == undefined || value == null) {
            return false;
        }
        let fileSize = value.size;
        if (!fileSize) {
            fileSize = 2;
        }
        let mb = fileSize * 1024;
        return fileSize <= mb;
    },
    getMiles: (i) => {
        return (i * 0.000621371192).toFixed(1);
    },
    coupanTypeDiscount: (obj) => {
        let ctype = obj.couponType || 0;
        let price = obj.price || 0;
        let discount = obj.discount || 0;
        let minAmount = obj.minAmount || 0;
        let disUpto = obj.disUpto || 0;
        let disRate = 0;
        if (ctype == "FLAT PERCENT") {
            disRate = price >= minAmount ? (price * discount) / 100 : disRate;
        } else if (ctype == "FLAT PERCENT UPTO") {
            disRate = price >= minAmount ? (price * discount) / 100 : disRate;
            disRate = disRate <= disUpto ? disRate : disUpto;
            // console.log("FLAT PERCENT UPTO", disRate);
        } else if (ctype == "CASH DISCOUNT UPTO") {
            disRate = price >= minAmount ? discount : disRate;
            disRate = disRate <= disUpto ? disRate : disUpto;
            // console.log("CASH DISCOUNT UPTO", disRate);
        }
        return parseFloat(disRate.toFixed(2));
    },
    isMobile: (num) => {
        var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
        return isphone;
    },
    coordinateLocal: async () => {
        let coordataL;
        function asignData(data) {
            coordataL = data;
        }
        await navigator.geolocation.getCurrentPosition(function (position) {
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            let jsonCoords = {
                lat: lat,
                lng: lng,
            };
            jsonCoords = JSON.stringify(jsonCoords);
            asignData(jsonCoords);
            localStorage.setItem("geoLocal", jsonCoords);
            // console.log('coordataL', localStorage.getItem('geoLocal'))
            // reactLocalStorage.set('geoLocal', jsonCoords);
        });
        return coordataL;
    },
    creditCardType: (cardType) => {
        let imageUrl;
        cardType = cardType.toLowerCase();
        switch (cardType) {
            case "visa":
                imageUrl = "card-logo-visa.svg";
                break;
            case "mastercard":
                imageUrl = "card-logo-mastercard.svg";
                break;
            case "american-express":
                imageUrl = "card-logo-amex.svg";
                break;
            default:
                imageUrl = "card-logo-unknown.svg";
        }
        return imageUrl;
    },
    trim : (d)=>{
        if(d==undefined || d == null ||d==''){
          return
        }
        return d.replace( /(<([^>]+)>)/ig, ' ')
      },
    imageValidate: (extention, size, msg) => {
        extention = extention || ["image/jpg", "image/jpeg", "image/png"];
        msg = msg || "You need to attach image";
        size = size || 100 * 1024 * 1024;
        let image = Yup.mixed()
            .required(msg)
            // .test("fileSize", "The image is too large", (value) => {
            //     if (value == undefined || value == null) {
            //         return false;
            //     }
            //     return value && value?.size <= size;
            // })
            .test("type", `Only the following formats are accepted: ${extention?.join(', ')}`, (value) => {
                if (value == undefined || value == null) {
                    return false;
                }
                let fileType = value.type;
                return value && extention.indexOf(fileType) != -1;
            });
        return image;
    },
    uploadImage: async (values) => {
        //console.log("values",values)
        let data;
        common.loader(true);
        const formData = new FormData();
        formData.append("coverImage", values.userImage);
        // formData.append("h", 300);
        // formData.append("w", 300);
        data = await authAxios({
            method: "POST",
            url: `/c/upload`,
            data: formData,
        })
            .then((res) => {
                return res.data.data._id;
            })
            .catch((error) => {
                common.error(error);
            });
        common.loader(false);
        return data;
    },
    error: (error) => {
        let err = error?.request?.response;
        let message;
        if (err) {
            message = JSON.parse(error.request.response)?.message;
            toast.error(message);
        }
        return message;
    },

    generateQOID: async () => {
        let resData = null;
        resData = await apiFunc
            .getOptionId()
            .then((res) => {
                return res.data.data;
            })
            .catch((error) => {
                common.error(error);
            });

        return resData;
    },
    bufferToBase64: (arr) => {
        //arr = new Uint8Array(arr) if it's an ArrayBuffer
        let buffer = btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ""));
        return "data:image/png;base64," + buffer;
    },
    getImage: async (id) => {
        if (id) {
            let data;
            data = await apiFunc
                .getImage(id)
                .then((res) => {
                    return common.bufferToBase64(res.data.data.Body.data);
                })
                .catch((error) => {
                    common.error(error);
                });
            return data;
        }
    },
    aadharValidate: (num) => {
        let regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
        return regexp.test(num);
    },
    isAlphabet: (event) => {
        // console.log(event);
        return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
    },
    inpAlphabet: (e) => {
        let realphate = e.replace(/[^A-Za-z\s]/gi, "");
        return realphate;
    },

    alphaNumber: (e) => {
        let alprealphate = e.replace(/[^\w\s]/gi, "");
        return alprealphate;
    },
    numberReplace: (e) => {
        let alprealphate = e.replace(/[^\d.-]+/g, '');
        return alprealphate;
    },
    getFirstLetter: (str) => {
        if (str) {
            let matches = str.match(/\b(\w)/g);
            let strJoin = matches.join("");
            let letters = strJoin.charAt(0);
            let letters2 = strJoin.charAt(1);
            return letters+letters2;
        }
    },
    protectEmail : (user_email) => {
        if(user_email){
            var avg, splitted, part1, part2;
            splitted = user_email.split("@");
            part1 = splitted[0];
            avg = part1.length / 2;
            part1 = part1.substring(0, (part1.length - avg));
            part2 = splitted[1];
            return part1 + "...@" + part2;
        }
    },
    getInviteFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let natureOfBusiness = params.get("natureOfBusiness");
        let city = params.get("city");
        let primaryCategory = params.get("primaryCategory");
        // let sorting = params.get("sorting");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        newPostData.natureOfBusiness = natureOfBusiness || ''
        newPostData.city = city || ''
        newPostData.primaryCategory = primaryCategory || ''
        // newPostData.sorting = sorting || 'new'
        
    
        return newPostData
    },
    getActiveFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let natureOfBusiness = params.get("natureOfBusiness");
        let primaryCategory = params.get("primaryCategory");
        let city = params.get("city");
        let sorting = params.get("sorting");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        newPostData.natureOfBusiness = natureOfBusiness || ''
        newPostData.primaryCategory = primaryCategory || ''
        newPostData.city = city || ''
        newPostData.sorting = sorting || 'new'
        
    
        return newPostData
      },
      getRequestFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let natureOfBusiness = params.get("natureOfBusiness");
        let primaryCategory = params.get("primaryCategory");
        let city = params.get("city");
        let sorting = params.get("sorting");
        let startDate = params.get("startDate");
        let endDate = params.get("endDate");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        newPostData.natureOfBusiness = natureOfBusiness || ''
        newPostData.primaryCategory = primaryCategory || ''
        newPostData.city = city || ''
        newPostData.sorting = sorting || 'new'
        newPostData.startDate = startDate || ''
        newPostData.endDate = endDate || ''
        
    
        return newPostData
      },
      
    getProductFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let sorting = params.get("sorting") || ""
        let categoryId = params.get("categoryId") || ""
        let primaryCategory = params.get("primaryCategory") || ""
        let label = params.get("label") || ""
        let typeOfAvailability = params.get("typeOfAvailability") || ""
        let brandName = params.get("brandName") || ""
        let status = params.get("status") || ""
        let minOrderQt = params.get("minOrderQt") || ""
        let originCountry = params.get("originCountry") || ""
        let priceRange = params.get("priceRange") || ""
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        newPostData.sorting = sorting || 'new'
        newPostData.categoryId = categoryId || ''
        newPostData.label = label || '' 
        newPostData.typeOfAvailability = typeOfAvailability || '' 
        newPostData.brandName = brandName || '' 
        newPostData.status = status || '' 
        newPostData.minOrderQt = minOrderQt || '' 
        newPostData.originCountry = originCountry || '' 
        newPostData.priceRange = priceRange || '' 
        newPostData.primaryCategory = primaryCategory || '' 
        return newPostData
    },
    getCatalougeFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let sorting = params.get("sorting") || ""
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        newPostData.sorting = sorting || 'new' 
        return newPostData
    },
    getFavVendor : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        return newPostData
    },
    getFavCatelouge : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        return newPostData
    },
    getReviewRating : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        return newPostData
    },
    getFilter : () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let newPostData = {}
        newPostData.page = page || 1
        newPostData.perPage = 10
        newPostData.searchString = searchString || ''
        return newPostData
    },
    dateFormate : (formate, date) => {
        let newdate = moment(date).format(formate || 'YYYY-MM-DD')
        return newdate
    },
    rangMinDate: (date) => {
        if(date){
            let newdate = new Date(date);
            return newdate;
        }else{
            return new Date('2023-05-15')
        }
    },
    rangMaxDate: (date) => {
        if(date){
            let newdate = new Date(date);
            return newdate;
        }else{
            return new Date()
        }
    },
    paginAfterChange: (filter, page, count) => {
        if(count == 0){
            let search = common?.[filter]();
            search.page = search.page > 1 ? (Number(search.page) - 1) : 1
            const queryString = new URLSearchParams(search).toString();
            console.log(queryString);
            return `${window.location.pathname}?${queryString}`
            // navigate();
        }
    },
};

export default common;
