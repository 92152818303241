import React, { useState } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UnauthAxios from '../../services/unauthAxios';
import authAxios from '../../services/authAxios';
import { useEffect } from 'react';
import common from '../../services/common';
import ManageVendorFilter from '../../components/manage-vendor/manageVendorFilter';
import SearchFormik from '../../components/common/searchFormik';
import PageModule from '../../components/pagination/pagination';
import ManageVendorCard from '../../components/manage-vendor/manageVendorCard';
import { useContext } from 'react';
import { UserContext } from '../../context/theme';


export default function DiscoverVendor() {
    const navigate = useNavigate()
    const history = useLocation()
    const {getStoreCounts, counts} = useContext(UserContext)
    const commonData = common.getInviteFilter()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: ""
    }
    const getPageData = async() => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''
        postData.filters = {
            city:'',
            natureOfBusiness:'',
        }
        if(commonData?.natureOfBusiness){
            postData.filters.natureOfBusiness = commonData?.natureOfBusiness || ''
        }
        if(commonData?.city){
            postData.filters.city = commonData?.city || ''
        }
        if(commonData?.primaryCategory){
            postData.filters.primaryCategory = commonData?.primaryCategory || ''
        }

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/manageVendors/discover`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                activePage:Number(commonData?.page),
                list:resData,
                totalItemsCount:res?.data?.totalCount
            })
            getStoreCounts()
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getActiveFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/discover-vendor?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
    },[history])


    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Discover Vendors ({pageData?.totalItemsCount})</h1>  </div>
                    <div className="dashheaadbtncon">
                        <div className="prolistnavinp">
                            <img className='image-fluid' src="assets/images/search.svg" alt="" />
                            <SearchFormik name="getInviteFilter" onChange={(values)=>{
                                    let search = common.getInviteFilter();
                                    search.page = 1
                                    search.searchString = values.searchString
                                    const queryString = new URLSearchParams(search).toString();
                                    navigate(`/discover-vendor?` + queryString)
                            }}  />
                        </div>
                        
                        <ManageVendorFilter navigatePath={"discover-vendor"} resetPath="/discover-vendor" />
                    </div>
                </div>
                <div className="ratingwrp">
                    {pageData?.list?.map((d,k)=>(
                        <ManageVendorCard data={d} key={k} action={()=>getPageData()} />
                    ))}
                </div >
                {pageData?.totalItemsCount > 10 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div >

        </>
    )
}

