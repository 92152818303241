import React from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
import { toast } from 'react-toastify';
import authAxios from '../../services/authAxios';
import common from '../../services/common';

 

export default function ReviewModal ({url, action, renderHtml, productData, type}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formik = useFormik({
        initialValues: {
            rating: "",
            review: ""
        },
        validationSchema: Yup.object({
            rating: Yup.string().required("Please select rating"),           
            review: Yup.string().required("Please write the review and select the rating"),           
        }),

        onSubmit: values => {
            submitReview(values)
        },
    });
    const submitReview = async (data) => {
        common.loader(true)
        await authAxios({
            method: "POST",
            url: url,
            data:data
        }).then((res) => {
            toast.success(res?.data?.message || [])
            setShow(false)
            if(action){
                action(res)
            }
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false)
    }
// const [rating, setRating] = useState(0)
//   const handleRating = (rate) => {
//     setRating(rate)
//   }
//   const onPointerEnter = () => console.log('Enter')
//   const onPointerLeave = () => console.log('Leave')
//   const onPointerMove = (value, index) => console.log(value, index)

    return (
        <>
           {renderHtml ? (
                <span onClick={()=>setShow(true)}>{renderHtml}</span>
            ):(
                <button type='button' className='givReviwBtn' variant="primary" onClick={()=>setShow(true)} ><div className='viewgivetxt'>Give Review Rating</div></button>
            )}
            
            <div className="dashheaadbtncon">
                <Modal className='categorymodel' show={show} onHide={()=>setShow(false)}>

                    <Modal.Body>
                        <div className='sussesbx'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="categorycmodelhead">Give Review Rating</div>
                                <div className="loginsinphone ">
                                    <div className="filtercategory mb-3">
                                        <div className="loginsinphonewrp sign ratinbx">
                                            <Rating
                                                onClick={(rate)=>formik.setFieldValue('rating', rate)}
                                                // onPointerEnter={onPointerEnter}
                                                // onPointerLeave={onPointerLeave}
                                                // onPointerMove={onPointerMove}
                                            />  
                                        </div>
                                    </div>
                                    <div className="filtercategory mb-3">
                                        <div className="loginsinphonewrp sign">
                                            <div className='textAryaBox reviTexarya'>
                                                <textarea {...formik.getFieldProps("review")} rows="2" placeholder="Write review"></textarea>
                                            </div>
                                            <ErrorMessage formik={formik} name="review" />
                                        </div>
                                    </div>

                                    <div className="filterprolistbtncon justify-content-center">
                                        <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Submit</button></div>
                                    </div>
                                </div>

                            </form>                           
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
