import React from 'react'
import { Link } from 'react-router-dom'

export default function ActiveSubcription() {
    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Manage Subscription</h1></div>
                </div>
                <div className="activesubmainwrp">
                    <div className="activesubwrp">
                        <div className="activesubcon">
                            <div className="activeplansubtxt">Active -  Premium Plan 1 Year </div>
                            <div className="activeplanprice">₹15,813</div>
                        </div>
                        <div className="activesubcon">
                            <div className="activesubreman">Remaining Days -265 Days</div>
                            <div className="activesubrenew mt-5 pb-3"><Link to="/store" >Renew</Link></div>
                        </div>
                    </div>
                    <div className="activesubulwrp">
                        <div className="activesubul mb-3">
                            <img className='mr-3' src="assets/images/smallgreenright.svg" alt="image" />
                            <div className="actiesubultxt">Unlimited Customer Invite</div>
                        </div>
                        <div className="activesubul mb-3">
                            <img className='mr-3' src="assets/images/smallgreenright.svg" alt="image" />
                            <div className="actiesubultxt">Unlimited Customer Invite</div>
                        </div>
                        <div className="activesubul mb-3">
                            <img className='mr-3' src="assets/images/smallgreenright.svg" alt="image" />
                            <div className="actiesubultxt">Unlimited Customer Invite</div>
                        </div>
                        <div className="activesubul">
                            <img className='mr-3' src="assets/images/smallgreenright.svg" alt="image" />
                            <div className="actiesubultxt">Unlimited Customer Invite</div>
                        </div>
                    </div>
                </div>
                <div className="activesunnotewrp">
                    <div className="activenotesubtxt mb-1">Note -  <span >To avail unlimited services purchase the subscription plan. Without purchasing “Subscription Plan” you can only avail following- </span></div>
                    <div className='activenotspan'> 1. 20 Customer Invite <br /> 2. 20 Customers Request Accept <br /> 3. 100 Product Upload <br /> 4. 10 Product Catalogue Upload</div>
                </div>
                <div className="dashheadtext sub mb-3"><h1>History</h1></div>
                <div className="prolisttableWRP">
                    <table className="table">
                        <thead className="prolistthead">
                            <tr>

                                <th className="align-middle">Plan Name</th>
                                <th className="align-middle">Purchase Date</th>
                                <th className="align-middle">End Date</th>
                                <th className="align-middle">Duration</th>
                                <th className="align-middle">Amount</th>
                                <th className="align-middle">Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-middle"> Plan Name 1</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">360 Days</td>
                                <td className="align-middle"> ₹15000</td>
                                <td className="align-right activetabledownl"> Download</td>
                            </tr>
                            <tr>
                                <td className="align-middle"> Plan Name 1</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">360 Days</td>
                                <td className="align-middle"> ₹15000</td>
                                <td className="align-right activetabledownl"> Download</td>
                            </tr>
                            <tr>
                                <td className="align-middle"> Plan Name 1</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">360 Days</td>
                                <td className="align-middle"> ₹15000</td>
                                <td className="align-right activetabledownl"> Download</td>
                            </tr>
                            <tr>
                                <td className="align-middle"> Plan Name 1</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">12-05-2022</td>
                                <td className="align-middle">360 Days</td>
                                <td className="align-middle"> ₹15000</td>
                                <td className="align-right activetabledownl"> Download</td>
                            </tr>
                        </tbody>
                    </table>


                </div>

            </div>
        </>
    )
}
