import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { UserContext } from '../../context/theme';
import authAxios from '../../services/authAxios';
import common from '../../services/common';

const WishlistBtn = ({addUrl,removeUrl, action, renderHtml, productData, type, isDisabled}) => {
  const [favourite, setFavourite] = useState(false)
  const {userInfo, getStoreCounts} = useContext(UserContext)
  const addToWishlist = async (id) => {
    if(isDisabled){
      common.loader(true)
      await authAxios({
        method: "GET",
        url: addUrl || `/c/products/favourite/${id}`
      }).then((res) => {
        toast.success(res?.data?.message || [])
        if(action){
          action()
        }
        getStoreCounts()
      }).catch((error) => {
        common.error(error)
      });
      common.loader(false)
    }
	}
  const removeToWishlist = async (id) => {
    if(isDisabled){
      common.loader(true)
      await authAxios({
        method: "GET",
        url: removeUrl || `/c/products/removeFavourite/${id}`
      }).then((res) => {
        toast.success(res?.data?.message || [])
        if(action){
          action()
        }
        getStoreCounts()
      }).catch((error) => {
        common.error(error)
      });
      common.loader(false)
    }
	}

  useEffect(()=>{
    let isFav;
    if(type == 'vendor'){
      let fav = productData?.favoriteCustomers || []
      let usrId = userInfo?._id
      isFav = fav.includes(usrId)
    }else{
      let fav = productData?.favourites || []
      let usrId = userInfo?._id
      isFav = fav.includes(usrId)
    }
    setFavourite(isFav)
  },[productData, userInfo])

  return (
    <>
    {/* <div className='wishlistImg'> */}
        {renderHtml ? (
            favourite ? (
              <span onClick={()=>removeToWishlist(productData?._id)} className="active">{renderHtml}</span>
            ) : (
              <span onClick={()=>addToWishlist(productData?._id)} className="deactive">{renderHtml}</span>
            )
        ):(
          favourite ? (
            <img className='image-fluid' onClick={()=>removeToWishlist(productData?._id)} src={`/assets/images/${isDisabled ? 'favgreen.svg':'favlightDisable.svg'}`} alt="image" />
          ):(
            <img className='image-fluid' onClick={()=>addToWishlist(productData?._id)} src={`/assets/images/${isDisabled ? 'favlight.svg':'favlightDisable.svg'}`} alt="image" />
          )
            
        )}
        {/* <img className='image-fluid ' src="/assets/images/favgreen.svg" alt="image" /> */}
        {/* </div> */}
    </>
  )
}

export default WishlistBtn