import React from 'react'
import { useState } from 'react';
import { Collapse } from 'react-bootstrap'

const ContactInfo = ({data}) => {
    const [open, setOpen] = useState(false);
  return (
    <>
            <div className="row">
                {data?.map((d,k)=>(
                    k < 2 && (
                        <div className="col-lg-6" key={k}>
                            <div className="cuspromoterlistwrp d-flex" >
                                <div className="cusnamehead cusname headBxTx sale mb-2">Sales Contact Info {k+1}</div>
                            </div>
                            <div className='saleContGrp'>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Contact Name</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.contactName || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Designation</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.designation?.title || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Mobile No</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.primaryMobileNo || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Whatsapp No</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.whatsappNo || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Landline No</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.landlineNo || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Business Email</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{d?.businessEmail || 'N/A'}</div>
                                </div>
                            </div>
                        </div>
                    )
                ))}
            </div>

        
        <div className="col-lg-12">
            <div className="blurarrowcon mt-3">  
                {!open && data?.length > 2 && (
                    <img className='img-fluid' src="/assets/images/blurdownarrow.svg" alt="image" onClick={() => setOpen(!open)} />
                    )}
                </div>
        </div>

        <Collapse in={open}>
            <div className="viewstoretabwrp mt-3">
                    <div className="row">
                    {data?.map((d,k)=>(
                        k >= 2 && (
                            <div className="col-lg-6" key={k}>
                                <div className="cuspromoterlistwrp d-flex" >
                                    <div className="cusnamehead cusname headBxTx sale mb-2">Sales Contact Info {k+1}</div>
                                </div>
                                <div className='saleContGrp'>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Contact Name</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.contactName || 'N/A'}</div>
                                    </div>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Designation</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.designation?.title || 'N/A'}</div>
                                    </div>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Mobile No</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.primaryMobileNo || 'N/A'}</div>
                                    </div>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Whatsapp No</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.whatsappNo || 'N/A'}</div>
                                    </div>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Landline No</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.landlineNo || 'N/A'}</div>
                                    </div>
                                    <div className="cuspromoterlist">
                                        <div className="cusnamehead">Business Email</div>
                                        <span>:</span>
                                        <div className="cusnamehead cusname">{d?.businessEmail || 'N/A'}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                        <div className="col-lg-12">
                            <div className="blurarrowcon rotatimg mt-3">  <img className='img-fluid' src="/assets/images/blurdownarrow.svg" alt="image" onClick={() => setOpen(!open)} /></div>
                        </div>
                    </div>
            </div>
        </Collapse>
    </>
  )
}

export default ContactInfo