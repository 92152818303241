import React, { useEffect, useState } from 'react'
import common from '../services/common';
import UnauthAxios from '../services/unauthAxios';

export default function TermCondition() {
    const [resData, setResData] = useState({});

    async function getData() {
        common.loader(true);
        await UnauthAxios({
            method: "GET",
            url: `/c/information/terms/getAll`,
        }).then((res) => {
            setResData(res?.data?.data || {})
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            <div className="termsparawrp terms">
                <div className="container">
                    <div className="termsleafwrp custerms">
                        <img className='img-fluid mr-2' src="./assets/images/onlyleaf.svg" alt="image" />
                        <div className="termsheadtex"><h1>{resData?.heading}</h1></div>
                        <span className='ml-3'></span>
                    </div>
                    <div className="termsparawrp pt-lg-0 pt-md-0 pt-sm-0 pt-0 pb-lg-0 pb-md-0 pb-sm-0 pb-0">
                        <div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData.section }} />
                    </div>
                </div>
            </div>
        </>
    )
}
