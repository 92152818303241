let ROOT_URL = "";
let IMAGE_URL = "";
let socketConnectionURL = "";
let REACT_APP_REDIRECT_URL = "";
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL = "https://api.saledesk.io";
  REACT_APP_REDIRECT_URL = "https://vendor.saledesk.io"
  // socketConnectionURL = "http://localhost:7000"
  IMAGE_URL = "";
} else {
  ROOT_URL = "https://api.saledesk.io/api/v1";
  socketConnectionURL= "https://api.saledesk.io",
  REACT_APP_REDIRECT_URL = "https://vendor.saledesk.io"
  // socketConnectionURL = "http://localhost:7000"
  IMAGE_URL = "";
}
export { IMAGE_URL, socketConnectionURL, REACT_APP_REDIRECT_URL };
export default ROOT_URL;