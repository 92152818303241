import React from 'react'
import { useState } from 'react';

export default function View({data}) {
    const [imgView, setImgView] = useState(true)
    const [view, setView] = useState(true)


    const TabbingView = (type) => {
        let newTabval = type == view ? "" : type;
        setView(newTabval);
    };
    return (
        <>
            {data?.length > 0 && (
                <div className="viewdropwrp">
                    <ul>
                        <li className={view == "search" ? "active" : ""}>
                            <span className="prolistnav "> <div className='viewtxtdrop'>View</div> </span>
                            <div className='cusviewcon'>
                                {Array.isArray(data) && (<p>{data?.join(' | ')}</p>)}
                                {!Array.isArray(data) && (<p>{data}</p>)}
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </>
    )
}
