import React, { useContext } from 'react'
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import ReactInputMask from 'react-input-mask';
import ProductListSendInquiry from '../../components/modals/productListSendInquiry';
import PageModule from '../../components/pagination/pagination';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import { useEffect } from 'react';
import SearchFormik from '../../components/common/searchFormik';
import WishlistBtn from '../../components/store/wishlistBtn';
import CartBtn from '../../components/store/cartBtn';
import FavFilters from '../../components/filterDateField/filterFavProduct';
import { UserContext } from '../../context/theme';

export default function FavProduct() {
    const navigate = useNavigate()
    const history = useLocation()
    const {employData, getStoreCounts, getCart} = useContext(UserContext)
    const commonData = common.getProductFilter()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 12,
        totalItemsCount: 0,
        maxPrice: 100000,
    });
    let postData = {
        page: "1",
        perPage: "12",
        searchString: ""
    }
    const getPageData = async(isCheckPage) => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''
        postData.filters = {}
        console.log('commonData', commonData);
        // if(commonData?.primaryCategory){
        //     postData.filters.categoryId = commonData?.primaryCategory
        // }
        if(commonData?.typeOfAvailability){
            postData.filters.typeOfAvailability = commonData?.typeOfAvailability
        }
        if(commonData?.minOrderQt){
            postData.filters.minOrderQt = commonData?.minOrderQt
        }
        if(commonData?.priceRange){
            postData.filters.priceRange = commonData?.priceRange
        }

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/products/pagin/favourite`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount?.totalCount,
                maxPrice:res?.data?.maxPrice
            })
            getStoreCounts()
            if(isCheckPage){
                let isNavChange = common.paginAfterChange('getProductFilter', 10, res?.data?.data?.length)
                if(isNavChange) {
                    navigate(isNavChange)
                }
            }
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getProductFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/favorite/product?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
    },[history])

    return (
        <>
            <div className="dashheadercon active">
                <div className="dashheadtext "><h1>My Favorite Products</h1></div>
                <div className="dashheaadbtncon">
                    <div className="prolistnavinp ml-3">
                        <img className='img-fluid ' src="/assets/images/search.svg" alt="" />
                        <SearchFormik name="getProductFilter" onChange={(values)=>{
                            let search = common.getProductFilter();
                            search.page = 1
                            search.searchString = values.searchString
                            const queryString = new URLSearchParams(search).toString();
                            navigate(`/favorite/product?` + queryString)
                    }}  />
                    </div>
                    <FavFilters maxPrice={pageData?.maxPrice} navigatePath={"favorite/product"} resetPath="/favorite/product" />
                    
                </div>
            </div>
            <div className="storeprocard">
                    <div className="row">
                        {pageData?.list?.map((d,k)=>(
                            <div className="col-lg-3 col-md-4 col-sm-6" key={k}>
                                <div className="aboutproimgwrp mt-0 mb-3">
                                    <div className="aboutproimgcon store mb-2">
                                    <Link to={`/vendor/product/view/${d?._id}`}><img className='image-fluid storebigimg' src={d?.images[0]?.fullUrl || "/assets/images/backimgbig.svg"} alt="image" /></Link>
                                        {d?.label?.title && (
                                            <div className="storepropopular">{d?.label?.title}</div>
                                        )}
                                        <div className="favPordutroundLogo">
                                            {d?.userId?.profileImage?.fullUrl ? (
                                                <Link to={`/vendor/view/${d?.userId?._id}`}>
                                                    <img className='img-fluid' src={d?.userId?.profileImage?.fullUrl || ''} alt='#'/>
                                                </Link>
                                            ):(
                                                <Link to={`/vendor/view/${d?.userId?._id}`}>
                                                    <div className='favPordName'>{common.getFirstLetter(d?.userId?.contactName)}</div>
                                                </Link>
                                            )}
                                        </div>
                                        <div className="favcataimg">
                                            <WishlistBtn productData={d} 
                                            isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)}
                                            action={()=>{
                                                // let search = common.getProductFilter();
                                                // const queryString = new URLSearchParams(search).toString();
                                                // navigate(`/favorite/product?` + queryString)
                                                getPageData(true)
                                            }}/>
                                        </div>
                                    </div>
                                    {d?.totalReviews > 0 ? (
                                        <Link to={`/review-rating/${d?._id}`}> <div className="dashprofilerating storepro"> <div> {d?.averageRatings} <img src="/assets/images/singlestar.svg" alt="img" />  </div><span>{d?.totalReviews} Reviews</span> </div></Link>
                                    ):(
                                        <a> <div className="dashprofilerating storepro"><span>No Reviews</span> </div></a>
                                    )}
                                    {/* <Link to={`/review-rating/${d?._id}`}> <div className="dashprofilerating storepro"> <div> 4 <img src="/assets/images/singlestar.svg" alt="img" />  </div><span>10 Reviews</span> </div></Link> */}
                                    
                                    <Link to={`/vendor/product/view/${d?._id}`}><div className="storeproheadtxt">{d?.title}</div></Link>
                                        <div className="storepromoq mb-1">MOQ - <span>{d?.minOrderQt} | </span>SKU - <span>{d?.sku}</span></div>
                                        <div className="storepromoq mb-1">
                                            Price - {d?.mrp > 0 && <span className="mrpPrice litmrp">₹{d?.mrp || 0} </span>}
                                            <span>₹{d?.basicPrice} (
                                                {d?.priceType === 'negotiable' && 'Negotiable'}
                                                {d?.priceType === 'fixed' && 'Fixed'}
                                                )</span>
                                        </div>
                                        <div className="storepromoq mb-1">
                                            {d?.typeOfAvailability === 'onDemand' && 'On Demand Manufacturing'}
                                            {d?.typeOfAvailability === 'ready' && 'Readily Stock'}
                                        </div>
                                        <div className="storepromoq mb-1">GST - <span>{d?.gst?.value}% | </span>HSN - <span>{d?.hsn}</span></div>
                                        <div className="storebtn">
                                            {/* <Link to="#" ><div>Send Inquiry</div></Link> */}
                                            <ProductListSendInquiry productData={d}  action={()=>{
                                                // let search = common.getProductFilter();
                                                // const queryString = new URLSearchParams(search).toString();
                                                // navigate(`/favorite/product?` + queryString)
                                                getPageData()
                                            }}
                                            isDisabled={true}
                                            />
                                            <CartBtn productData={d}  action={()=>{
                                                // let search = common.getProductFilter();
                                                // const queryString = new URLSearchParams(search).toString();
                                                // navigate(`/favorite/product?` + queryString)
                                                getPageData()
                                                getCart()
                                            }}
                                            isDisabled={true}
                                            // isDisabled={(isConnected)}
                                            />
                                        </div>
                                </div>
                            </div>
                        ))}
                        
                        
                    </div>
                {pageData?.totalItemsCount > 12 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
