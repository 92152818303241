import React, { useEffect } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import common from '../../services/common';
import ReactInputMask from 'react-input-mask';
import authAxios from '../../services/authAxios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateUser() {
    const {userId} = useParams()
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            role: "",

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter employee name"),
            email: Yup.string().email("Please enter a valid email address").required("Please enter a email address"),
            phone: Yup.string().min(10, 'Please enter a valid mobile number').max(10, 'Please enter a valid mobile number').required("Please enter mobile number"),
            role: Yup.string().required("Please select role type"),
        }),

        onSubmit: values => {
            submitForm(values)
        },
    });
    const submitForm = async (values) => {
        common.loader(true);
        let url = userId ? `/c/employee/edit/${userId}` : `/c/employee`
        await authAxios({
            method: "POST",
            url: url,
            data: values,
        }).then((res) => {
            formik.resetForm()
            toast.success(res?.data?.message)
            navigate('/user-list')
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }


    const getUser = async () => {
        common.loader(true);
        await authAxios({
            method: "GET",
            url: `/c/employee/get/${userId}`,
        }).then((res) => {
            let resData = res?.data?.data || {}
            formik.setFieldValue('name', resData?.name || '')
            formik.setFieldValue('email', resData?.email || '')
            formik.setFieldValue('phone', resData?.phone || '')
            formik.setFieldValue('role', resData?.role || '')
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }

    useEffect(()=>{
        userId && getUser()
    },[userId])
    return (
        <>
            <div className="dashmainWRP">
                <div className="helpdeskmainheadcon role">
                    <h1>{userId ? 'Edit User & Roles' : 'Create User & Roles'}</h1>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="helpdesktabcon editticket">
                        <div className="helpdeskmainheadcon">
                            <h1 className='p-0 mb-4 ml-3'>{userId ? 'Edit ' : 'Add '} User</h1>
                        </div>
                        <div className="container-fluid">
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-md-6 col-sm-12">

                                    <div className="loginsinphone sign">
                                        <div className='signupinphead mb-2'>Employee Name</div>
                                        <input {...formik.getFieldProps("name")} type="text" className="form-control" placeholder="Enter Employee Name" onChange={(e) => formik.setFieldValue("name", common.inpAlphabet(e.target.value))} />
                                        <ErrorMessage formik={formik} name="name" />
                                    </div>

                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                    <div className="loginsinphone sign">
                                        <div className='signupinphead mb-2'>Email</div>
                                        <input {...formik.getFieldProps("email")} type="email" className="form-control" placeholder="Enter Email" />
                                        <ErrorMessage formik={formik} name="email" />

                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                    <div className="loginsinphone sign">
                                        <div className='signupinphead mb-2'>Mobile Number</div>
                                        <ReactInputMask mask="9999999999" maskChar={null} {...formik.getFieldProps("phone")} type="text" className="form-control" placeholder="Enter Mobile Number" />
                                        <ErrorMessage formik={formik} name="phone" />

                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                    <div className="loginsinphone sign">
                                        <div className='signupinphead mb-2'>Role Type</div>
                                        <select {...formik.getFieldProps("role")} className="form-select" aria-label="Default select example">
                                            <option value="">Select Role Type</option>
                                            <option value="dr">Director </option>
                                            <option value="sm">Store Manager </option>
                                            <option value="rm">Relationship Manager </option>
                                        </select>
                                        <ErrorMessage formik={formik} name="role" />

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="loginsignbtn"><button type='submit' className='theme-btn-1'>Submit</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}
