import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ErrorMessage } from "../../components/formik/errorMessage";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import FilterNewOldCheck from "../../components/filterDateField/filterNewOldCheck";
import Fav from "../../components/fav";
import View from "../../components/filterDateField/view";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchFormik from "../../components/common/searchFormik";
import common from "../../services/common";
import authAxios from "../../services/authAxios";
import { useEffect } from "react";
import ConfirmModal from "../../components/modals/confirmModal";
import ActiveRequestFilter from "../../components/filterDateField/filterRequestCustomer";
import DateFormate from "../../components/common/dateFormate";
import { useContext } from "react";
import { UserContext } from "../../context/theme";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function VendorRequest() {
    const {getStoreCounts, counts} = useContext(UserContext)
    let navigate = useNavigate();
    const history = useLocation();
    const commonData = common.getRequestFilter();
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: "",
    };
    const getPageData = async (isCheckPage) => {
        postData.page = commonData?.page || 1;
        postData.searchString = commonData?.searchString || "";
        postData.filters = {}
        if(commonData?.startDate){
            postData.filters.from = common.dateFormate('YYYY-MM-DD', commonData?.startDate) || ''
            postData.filters.to = common.dateFormate('YYYY-MM-DD', commonData?.endDate) || ''
        }
        if(commonData?.natureOfBusiness){
            postData.filters.natureOfBusiness = commonData?.natureOfBusiness || ''
        }
        if(commonData?.primaryCategory){
            postData.filters.primaryCategory = commonData?.primaryCategory || ''
        }
        if(commonData?.city){
            postData.filters.city = commonData?.city || ''
        }
        if(commonData?.sorting){
            postData.sorting = commonData?.sorting || ''
        }

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/manageVendors/vendorRequests`,
            data: postData,
        })
            .then((res) => {
                let resData = res?.data?.data;
                resData = resData?.map((d) => {
                    let obj = d?.from;
                    obj.isChecked = false;
                    obj.requestId = d?._id;
                    obj.requestCreatedAt = d?.createdAt
                    return obj;
                });
                getStoreCounts()
                setPageData({
                    ...pageData,
                    list: resData,
                    activePage:Number(commonData?.page),
                    totalItemsCount: res?.data?.totalCount,
                });
                if(isCheckPage){
                    let isNavChange = common.paginAfterChange('getRequestFilter', 10, res?.data?.data?.length)
                    if(isNavChange) {
                        navigate(isNavChange)
                    }
                }
            })
            .catch((error) => {
                common.error(error);
            });
        common.loader(false);
    };

    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
                ...pageData,
                activePage: pageNumber,
                list: [],
            });
            let search = common.getRequestFilter();
            search.page = pageNumber
            const queryString = new URLSearchParams(search).toString();
            navigate(`/vendor-request?${queryString}`);
        }
    }

    useEffect(() => {
        getPageData();
    }, [history]);

    const handleChecked = (status, id) => {
        let datas = pageData?.list || [];
        datas?.map((d) => {
            if (d?._id === id) {
                d.isChecked = !status;
                // setSelectToggle(true)
            }
            return d;
        });
        setPageData({
            ...pageData,
            list: datas,
        });
    };

    const handleToggleChecked = () => {
        // setSelectToggle(!selectToggle)
        let datas = pageData?.list || [];
        let stats = pageData?.list?.filter((d) => d.isChecked === false)?.length > 0 ? true : false;
        datas?.map((d) => {
            // console.log(d);
            d.isChecked = stats;
            return d;
        });
        setPageData({
            ...pageData,
            list: datas,
        });
    };

    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext ">
                        <h1>Vendor's Requests ({counts?.vendorsRequestsCount || 0})</h1>
                    </div>
                    <div className="dashheaadbtncon">
                        {/* <div className="prolistnavdelete mr-3">
                            <img className="image-fluid" src="assets/images/biggreenright.svg" alt="image" />
                        </div>
                        <div className="prolistnavdelete">
                            <img className="image-fluid" src="assets/images/bigredcross.svg" alt="image" />
                        </div> */}
                        {pageData?.list?.filter((d) => d.isChecked === true)?.length >= 2 ? (
                            <div className="prolistnavdelete mr-3">
                                <ConfirmModal
                                    title="Are you sure ?"
                                    method="POST"
                                    postData={{invitationIds:pageData?.list?.filter((d) => d.isChecked === true)?.map((d) => {return d?.requestId;})}}
                                    renderHtml={<img className="image-fluid mr-2" src="/assets/images/biggreenright.svg" alt="image" />}
                                    url={`/c/manageVendors/acceptInvitations`}
                                    action={() => {
                                        getPageData();
                                        getStoreCounts();
                                    }}
                                    submitBtnName="Confirm"
                                />
                                <ConfirmModal
                                    title="Are you sure ?"
                                    method="POST"
                                    postData={{invitationIds:pageData?.list?.filter((d) => d.isChecked === true)?.map((d) => {return d?.requestId;})}}
                                    renderHtml={<img className="image-fluid" src="/assets/images/bigredcross.svg" alt="image" />}
                                    url={`/c/manageVendors/rejectInvitations`}
                                    action={() => {
                                        getPageData();
                                        getStoreCounts();
                                    }}
                                    submitBtnName="Confirm"
                                />
                            </div>
                        ) : (
                            <div className="prolistnavdelete">
                                {/* <a onClick={() => handleToggleChecked()}>Select All</a> */}
                            </div>
                        )}
                        <div className="prolistnavinp">
                            <img className="image-fluid" src="assets/images/search.svg" alt="" />
                            <SearchFormik
                                name="getRequestFilter"
                                onChange={(values) => {
                                    let search = common.getRequestFilter();
                                    search.page = 1;
                                    search.searchString = values.searchString;
                                    const queryString = new URLSearchParams(search).toString();
                                    navigate(`/vendor-request?` + queryString);
                                }}
                            />
                        </div>

                        <FilterNewOldCheck navigatePath={"vendor-request"} resetPath="/vendor-request" name="getRequestFilter" />
                        <ActiveRequestFilter navigatePath={"vendor-request"} resetPath="/vendor-request" />
                        {/* <FilterNewOldCheck />
                        <div className="filterwrpprolist">
                            <ul>
                                <li className={filter == "search" ? "active" : ""}>
                                    {imgChange ? (
                                        <div className="prolistnav ml-3" onClick={() => setImgChange(false)}><img onClick={() => Tabbing("search")} className='image-fluid filterclickprolist' src="assets/images/listmenu.svg" alt="image" /></div>

                                    ) : (
                                        <div className="prolistnav ml-3" onClick={() => setImgChange(true)}><img onClick={() => Tabbing("search")} className='image-fluid filterclickprolist' src="assets/images/filterchange.svg" alt="image" /></div>

                                    )}                        <div className='filterprolist'>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="categorycmodelhead">Filter</div>
                                            <div className="loginsinphone catemodal">
                                                <div className="pendingdate">
                                                    <div className="filtercategory mb-3">

                                                        <DatePicker
                                                            selected={startDate}
                                                            minDate={new Date()}
                                                            onChange={(date) => handleStartDate(date)}
                                                            timeClassName={handleColor}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="From Date"
                                                            {...formik.getFieldProps("fromDate")}
                                                        />
                                                        <img className='image-fluid' src="assets/images/dateicon.svg" alt="" />
                                                        <ErrorMessage formik={formik} name="fromDate" />
                                                    </div>
                                                </div>
                                                <div className="pendingdate mb-3">
                                                    <DatePicker
                                                        selected={startDate}
                                                        minDate={new Date()}
                                                        onChange={(date) => handleStartDate(date)}
                                                        timeClassName={handleColor}
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText="End Date"
                                                        {...formik.getFieldProps("endDate")}
                                                    />
                                                    <img className='image-fluid' src="assets/images/dateicon.svg" alt="" />
                                                    <ErrorMessage formik={formik} name="endDate" />
                                                </div>
                                                <div className="filtercategory mb-3">
                                                    <select {...formik.getFieldProps("category")} className="form-select" aria-label="Default select example">
                                                        <option selected>Primary Category</option>
                                                        <option value="1">Category</option>
                                                    </select>
                                                    <ErrorMessage formik={formik} name="category" />
                                                </div>
                                                <div className="filtercategory mb-3">
                                                    <select    {...formik.getFieldProps("status")} className="form-select" aria-label="Default select example">
                                                        <option selected>Nature of Business</option>
                                                        <option value="1">Product Status</option>
                                                    </select>
                                                    <ErrorMessage formik={formik} name="status" />
                                                </div>
                                                <div className="filtercategory">
                                                    <select    {...formik.getFieldProps("city")} className="form-select" aria-label="Default select example">
                                                        <option selected>City</option>
                                                        <option value="1">Product Status</option>
                                                    </select>
                                                    <ErrorMessage formik={formik} name="city" />
                                                </div>
                                            </div>
                                            <div className="filterprolistbtncon">
                                                <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Reset</button></div>
                                                <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Apply</button></div>
                                            </div>

                                        </form>
                                    </div>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div className="prolisttableWRP">
                    <table className="table">
                        <thead className="prolistthead">
                            <tr>
                                <th className="align-middle">
                                    <div className="checkinp">
                                        <input type="checkbox" className="form-check-input" checked={pageData?.list?.filter((d) => d.isChecked === false)?.length > 0 ? false : (pageData?.list?.length > 0 ? true : false)} onChange={() => handleToggleChecked()} />
                                        <div className="tablechecktxtpic"> Picture </div>
                                    </div>
                                </th>
                                <th className="align-middle" >Company Name</th>
                                <th className="align-middle" >Nature of Business</th>
                                <th className="align-middle">Primary Category</th>
                                <th className="align-middle" >City </th>
                                <th className="align-middle" >Date </th>
                                <th className="align-middle" >Rating </th>
                                <th className="align-middle" >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageData?.list?.map((d, k) => (
                                <tr key={k}>
                                    <td className="align-middle" data-th="Picture  -">
                                        <div className="checkinp">
                                            <input type="checkbox" className="form-check-input" checked={d?.isChecked || false} onChange={() => handleChecked(d?.isChecked, d?._id)} />
                                            <div className="tablechecktxt">
                                                {" "}
                                                <img className="img-fluid" src={d?.profileImage?.length > 0 ? d?.profileImage[0]?.fullUrl : "/assets/images/default_user.jpg"} alt="image" />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle activename" data-th="Company-Name  -">
                                        {" "}
                                        <Link to={`/vendor/view/${d?._id}`}>{d?.companyName}</Link>
                                    </td>
                                    <td className="align-middle" data-th="Nature-of-Business  -">{d?.natureOfBusiness[0]?.title}</td>
                                    <td className="align-middle " data-th="Primary-Category  -">
                                        <View data={d?.primaryCategory?.map((d)=>d?.title) || []} />
                                    </td>
                                    <td className="align-middle" data-th="City  -">{d?.city}</td>
                                    <td className="align-middle" data-th="Date  -"><DateFormate>{d?.requestCreatedAt}</DateFormate></td>
                                    <td className="align-middle" data-th="Rating  -">
                                        
                                        {d?.averageRatings > 0 ? (
                                            <div className="d-flex align-items-center">
                                                <img src="/assets/images/singlestar.svg" alt="img" />
                                                <div> {d?.averageRatings} </div>{" "}
                                            </div>
                                        ):(
                                            <div className="d-flex align-items-center">-</div>
                                        )}
                                    </td>

                                    <td className="align-middle" data-th="Action  -">
                                        <div className="actionimg">
                                            {/* <img className="image-fluid mr-1" src="/assets/images/greenright.svg" alt="image" />
                                            <img className="image-fluid mr-1" src="/assets/images/redcross.svg" alt="image" /> */}
                                            <ConfirmModal title="Are you sure ?" method="GET" renderHtml={<img className="image-fluid mr-1" src="assets/images/greenright.svg" alt="image" />} url={`/c/manageVendors/acceptInvitation/${d?.requestId}`} action={() =>{ 
                                                getPageData();
                                                getStoreCounts();
                                            }} submitBtnName="Confirm" />
                                            <ConfirmModal title="Are you sure ?" method="GET" renderHtml={<img className="img-fluid" src="assets/images/redcross.svg" alt="image" />} url={`/c/manageVendors/rejectInvitation/${d?.requestId}`} action={() =>{ 
                                                getPageData();
                                                getStoreCounts();
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
