import React from 'react'
import Moment from 'react-moment'

export default function CustomerBusiness({userInfo, type}) {
    // console.log(userInfo)
  return (
      <>
            <div className="cuspromoterWRp">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cusprometerGrp">
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Company Name</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.companyName || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">No of Employees</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.noOfEmployees || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Year of Establishment</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.companyEstYear ? <Moment format="YYYY">{userInfo?.companyEstYear}</Moment> : 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">GST</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.gstTreatment || 'N/A'}</div>
                            </div>
                            
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Website</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.website || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">GST No. / PAN No.</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.gstPanNo || 'N/A'}</div>
                            </div>
                            {type === "vendor" && (
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Annual Turnover</div>
                                    <span>:</span>
                                    <div className="cusnamehead cusname">{userInfo?.turnOver || 'N/A'}</div>
                                </div>
                            )}
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Nature of Business</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.natureOfBusiness?.title || 'N/A'}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Business Work Area</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.businessWorkArea || 'N/A'}{(userInfo?.businessWorkArea !=='Pan India' && userInfo?.businessWorkArea !=='') && userInfo?.businessState?.length > 0 && `(${userInfo?.businessState?.join(", ")})`}</div>
                            </div>
                            <div className="cuspromoterlist">
                                <div className="cusnamehead">Primary Category</div>
                                <span>:</span>
                                <div className="cusnamehead cusname">{userInfo?.primaryCategory?.map((d)=>d?.title)?.join(" | ") || 'N/A'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {type === "vendor" && (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cusnamehead cusname headBxTx mt-3 mb-1">Bank Account Details</div>
                        </div>
                        <div className="col-lg-12">
                            <div className="cusprometerGrp">
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Account Name</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.accountName || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Account Number</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.accountNumber || 'N/A'}</div>
                                </div>
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">IFSC Code</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.ifscCode || 'N/A'}</div>
                                </div>                                    
                                <div className="cuspromoterlist">
                                    <div className="cusnamehead">Branch City</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname">{userInfo?.branch || 'N/A'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}             
            </div>
      </>
  )
}
