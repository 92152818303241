import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import ImageDownload from '../../components/common/ImageDownload';
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';
import Faq from "../faq";
import FaqFooter from '../faqFooter';


export default function Home() {
    const [resData, setResData] = useState({});

    async function getData() {
        common.loader(true);
        await UnauthAxios({
            method: "GET",
            url: `/c/information/customerHome/getAll`,
        }).then((res) => {
            setResData(res?.data?.data || {})
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);
    return (
        <>
            <div className="homemainWRP">
                <div className="homebannerimgcon">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className="homeheadtxtcon mbhome">
                                    <div className="homeheadtxt">
                                        <h1 className='fw-bold mb-lg-3 mb-md-1'><div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.heading1 }} /></h1>
                                    </div>
                                    <div className="homeheadtxt">
                                        <p className='fw-normal'><div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.description1 }} /></p>
                                    </div>
                                </div>
                                <div className="homeheadtxtcon">
                                    <div className="homeheadtxt">
                                        <h1 className='fw-bold mb-3'><div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.heading2 }} /></h1>
                                    </div>
                                    <div className="homeheadtxt">
                                        <p className='fw-normal'><div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.description2 }} /></p>
                                    </div>
                                </div>
                                <div className="homebannerbtmtxt">
                                    <h3> <div className='editorWrp pt-0' dangerouslySetInnerHTML={{ __html: resData?.section?.description3 }} /> </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="homefeaturewrpmain">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className="homecusfeatutxtcon">
                                    <div className="homecusfeaturetxt"><h1 className='fw-bold'>Buyer’s Feature</h1></div>
                                    {/* <div className="homecusfeaturetxt"><h1 className='fw-bold'>Customer’s Feature</h1></div> */}
                                    {/* <div className="homecusfeaturetxt maxshow"><h1 className='fw-bold'>Vendor’s Feature</h1></div> */}
                                    <div className="homecusfeaturetxt maxshow"><h1 className='fw-bold'>Seller’s Feature</h1></div>
                                </div>
                                <div className="homefeaturewrp">

                                    {/* <div className="homecusfeaturecon">
                                        <div className="homediscoverWRP">
                                            <div className="homediscovercon">
                                                <div className='dishandinnerimg'><img className='image-fluid' src="assets/images/discovervendor.svg" alt="image" /></div>
                                                <div className="homedisimgcon">  Discover Vendors</div>
                                            </div>
                                            <div className="homediscovercon bg">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/acceptrejectinvite.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Accept / Reject Invite</div>
                                            </div>
                                            <div className="homediscovercon pg">
                                                <div className='dishandinnerimg'><img className='image-fluid' src="assets/images/addproductcart.svg" alt="image" /></div>
                                                <div className="homedisimgcon"> Add Products to Cart</div>
                                            </div>
                                            <div className="homediscovercon Ng">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/customerimg.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Request for Inquiry</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="homecusfeaturecon">
                                        <div className="homediscoverWRP">
                                            <div className="homediscovercon">
                                                <div className='dishandinnerimg'><img className='image-fluid' src="assets/images/discovervendor.svg" alt="image" /></div>
                                                <div className="homedisimgcon">Discover New Verified Sellers</div>
                                            </div>
                                            <div className="homediscovercon bg">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/acceptrejectinvite.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Accept/Reject Seller Invitations</div>
                                            </div>
                                            <div className="homediscovercon pg">
                                                <div className='dishandinnerimg'><img className='image-fluid' src="assets/images/addproductcart.svg" alt="image" /></div>
                                                <div className="homedisimgcon">Mark Favorite to Various Suppliers & Products</div>
                                            </div>
                                            <div className="homediscovercon Ng">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/customerimg.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Manage Suppliers on a Single Platform</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="homesearchwrp">
                                        <div className="homesearchcon">
                                            <div className='dishandinnerimg img'><img className='img-fluid' src="assets/images/searchquestion.svg" alt="image" /></div>
                                        </div>
                                    </div>


                                    <div className="homecusfeaturecon">
                                        <div className="homediscoverWRP ">

                                            <div className="homecusfeaturetxt maxhide"><h1 className='fw-bold'>Seller’s Feature</h1></div>

                                            <div className="homediscovercon">
                                                <div className='dishandinnerimg '><img className='image-fluid' src="assets/images/createstore.svg" alt="image" /></div>
                                                <div className="homedisimgcon">Creating  a Digital Store</div>
                                            </div>
                                            <div className="homediscovercon bg">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/addcatalogue.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Invite Buyers with Private Store Access</div>
                                            </div>
                                            <div className="homediscovercon pg">
                                                <div className='dishandinnerimg '><img className='image-fluid' src="assets/images/twocustomer.svg" alt="image" /></div>
                                                <div className="homedisimgcon">Accept/Reject New Buyer Invitations</div>
                                            </div>
                                            <div className="homediscovercon Ng">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/customerimg.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Manage Buyers on a Single platform</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="homecusfeaturecon">
                                        <div className="homediscoverWRP ">

                                            <div className="homecusfeaturetxt maxhide"><h1 className='fw-bold'>Vendor’s Feature</h1></div>

                                            <div className="homediscovercon">
                                                <div className='dishandinnerimg '><img className='image-fluid' src="assets/images/createstore.svg" alt="image" /></div>
                                                <div className="homedisimgcon"> Create Store</div>
                                            </div>
                                            <div className="homediscovercon bg">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/addcatalogue.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Add Products & Catalogues</div>
                                            </div>
                                            <div className="homediscovercon pg">
                                                <div className='dishandinnerimg '><img className='image-fluid' src="assets/images/twocustomer.svg" alt="image" /></div>
                                                <div className="homedisimgcon">Invite Customers</div>
                                            </div>
                                            <div className="homediscovercon Ng">
                                                <div className='dishandinnerimg dicimg'><img className='image-fluid' src="assets/images/customerimg.svg" alt="image" /></div>
                                                <div className="homedisimgcon ">Accept Customer Requests</div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homesitecountswrp">
                    <div className="container">
                        <ul className="homeRoundBx">
                            <li>
                                <div className="homecountwrp">   
                                    <div className="homecounttxt">1,000 +<br />
                                        Sellers </div>
                                </div>
                            </li>
                            <li>
                                <div className="homecountwrp">
                                    <div className="homecounttxt">5,000 + <br />
                                    Buyers </div>
                                </div>
                            </li>
                            <li>
                                <div className="homecountwrp">
                                    <div className="homecounttxt">10,000 + <br />
                                    Products </div>
                                </div>
                            </li>
                            <li>
                                <div className="homecountwrp">
                                    <div className="homecounttxt">10,000 + <br />
                                    Catalogues </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="homestorewrp text-center">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>                            
                                <div className='homecenterimgcon'>
                                    <ImageDownload id={resData?.section?.image} className="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="homefaqWRP pt-0" id='FAQS'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className="homecusfeaturetxt faq">
                                    <h1 className='fw-bold'>FAQ'S</h1>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-md-12">
                                <div className="homefaqaccorwrp">
                                    <FaqFooter />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-md-12">
                                <div className="homefaqcolimg faqgrpbx">
                                    <img className='image-fluid' src="assets/images/faqhomeimg.svg" alt="image" />
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="homefaqaccorwrp">
                                    <div className="homefaqviewbtn">
                                        <Link to="/faqs">View More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
