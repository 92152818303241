import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import VideoPlayerPopup from '../../components/common/videoPlayerPopup';
import authAxios from '../../services/authAxios';
import common from '../../services/common';

export default function QuickTutorial() {

    const [resData, setResData] = useState([]);

    async function getData() {
        common.loader(true);
        await authAxios({
            method: "GET",
            url: `/c/information/tutorial/getAll`,
        }).then((res) => {
            setResData(res?.data?.data || [])
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>

            <div className="dashheadercon">
                <div className="dashheadtext"><h1>Quick Tutorials</h1></div>
            </div>
            <div className="quicktutorialwrp ">
                <div className="row">
                    {resData?.map((d, k) => (
                        d &&
                        <div className="col-lg-4 col-md-4 col-sm-6" key={k}>
                            <div className="gallmainwrpcon">
                                <div className="compangallimgwrp">
                                    <VideoPlayerPopup id={d?.url?.split('watch?v=')?.[1]} />
                                    <img className='img-fluid' src={`https://img.youtube.com/vi/${d?.url?.split('watch?v=')?.[1]}/0.jpg`} alt="image" />
                                   
                                </div>
                                <div className='companyproimgtxt'>{d?.title}</div>
                            </div>
                        </div>                        
                    ))}
                </div>

            </div>
        </>
    )
}
