import React from 'react'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UserContext } from '../../context/theme'
import authAxios from '../../services/authAxios'
import common from '../../services/common'

const ManageVendorCard = ({data, action}) => {
    const {getStoreCounts} = useContext(UserContext)
    const navigate = useNavigate()

    const submitForm = async (email) => {
        common.loader(true);
        let postValue = {
            "email": email,
            "subject": "Invitation",
            "description": "Invitation to join sale desk"
        }
        await authAxios({
            method: "POST",
            url: `/c/manageVendors/invite`,
            data: postValue,
        }).then((res) => {
            toast.success(res?.data?.message)
            action()
            getStoreCounts()
        }).catch((error) => {
            common.error(error)
        });
        common.loader(false);
    }
  return (
    <>
        <div className="ratingcon discover">

            <div className="ratimgimgwrp discver">
                <div className="ratimgimgcon">
                    <div className="discovrRatinUsrImg">
                        <img className='image-fluid' src={data?.profileImage?.fullUrl || "/assets/images/default_user.jpg"} alt="image" />
                    </div>
                    <div className="ratingimgheadcon">
                        <Link to={`/vendor/view/${data?._id}`}><div className="ratingimghead"><h2>{data?.companyName}</h2></div></Link>
                        <div className="ratingimgpara p"> 
                            <p><span>Nature of Business</span> <span>:</span> <span>{data?.natureOfBusiness?.title}</span></p>
                            <p><span>Primary Category</span> <span>:</span> <span>{data?.primaryCategory?.map((d)=>d?.title)?.join(", ")}</span></p>
                            <p><span>Address</span> <span>:</span> <span>{data?.storeId?.address} </span></p>
                        </div>
                    </div>
                </div>
                <div className="loginsignbtncon">
                    <div className="loginsignbtn discover">
                        {data?.alreadyPending ? (
                            <button type='button' className='theme-btn-1'>Requested</button>
                        ):(
                            <button type='button' onClick={()=>submitForm(data?.primaryEmail)} className='theme-btn-1'>Request to Connect</button>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ManageVendorCard