import React from "react";
import Fancybox from "../../components/fancyBox/fancyBox";

export default function CustomerGallary({ userInfo, type }) {
    return (
        <>
            <div className="cuspromoterWRp ">
                <div className="row">
                    <Fancybox options={{ infinite: false }}>
                        {userInfo?.galleryId?.images?.map((d, k) => (
                            <div className="col-lg-4 col-md-4 col-sm-6" key={k} data-fancybox="gallery" data-src={`${d?.url?.fullUrl}`}>
                                <div className="gallmainwrpcon">
                                    <div className="compangallimgwrp">
                                        <img className="img-fluid" src={d?.url?.fullUrl} alt="image" />
                                    </div>
                                    <div className="companyproimgtxt pt-0">{d?.title}1</div>
                                </div>
                            </div>
                        ))}
                    </Fancybox>
                </div>

            </div>
        </>
    );
}
