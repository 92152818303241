import { convert } from 'html-to-text';
import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import common from "../services/common";
import UnauthAxios from "../services/unauthAxios";


export default function Faq() {

    const [faqs, setFaqs] = useState([]);
    function getData() {
        // common.loader(true);
        UnauthAxios({
            method: "GET",
            url: `/c/information/faqs/getAll`,
        }).then((res) => {
            setFaqs(res?.data?.data || []);
        }).catch((error) => {
            common.error(error)
        });
        // common.loader(false);
    }
    useEffect(() => {
        getData()
    }, []);

    return (
        <>

            <div className="logincontentCON terms faqPags">
                <div className="container">
                    <div className="termsleafwrp custerms">
                        <img className='img-fluid mr-2' src="./assets/images/onlyleaf.svg" alt="image" />
                        <div className="termsheadtex"><h1>Faq's</h1></div>
                        <span className='ml-3'></span>
                    </div>
                    <div className="faqaccorcon faq">
                        <Accordion>
                            {faqs.map((faq, k) => (
                                <Accordion.Item eventKey={k} key={k}>
                                    <Accordion.Header className='faqacchead faq'><p>{faq?.question}</p></Accordion.Header>
                                    <Accordion.Body className='faqpara'>
                                        <div className='editorWrp p-0' dangerouslySetInnerHTML={{ __html:faq?.answer }} />
                                       
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}
