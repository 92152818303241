
import authAxios from "../services/authAxios";
import UnauthAxios from "../services/unauthAxios";
import ROOT_URL from "./api-url";

const apiFunc = {
  
};

export default apiFunc;
