import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SearchFormik from '../../components/common/searchFormik'
import PageModule from '../../components/pagination/pagination'
import WishlistBtn from '../../components/store/wishlistBtn'
import { UserContext } from '../../context/theme'
import authAxios from '../../services/authAxios'
import common from '../../services/common'

export default function FavCatalogue() {
    const navigate = useNavigate()
    const history = useLocation()
    const {employData, getStoreCounts} = useContext(UserContext)
    const commonData = common.getFavCatelouge()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 12,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "12",
        searchString: ""
    }
    const getPageData = async(isCheckPage) => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/productsCatalogue/pagin/favourite`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount
            })
            getStoreCounts()
            if(isCheckPage){
                let isNavChange = common.paginAfterChange('getFavCatelouge', 10, res?.data?.data?.length)
                if(isNavChange) {
                    navigate(isNavChange)
                }
            }
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getFavCatelouge();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/favorite/catalogue?${queryString}`);
        }
      }

      useEffect(()=>{
        getPageData()
    },[history])

    return (
        <>
            <div className="dashheadercon active">
                <div className="dashheadtext "><h1>My Favorite Catalogue</h1></div>
                <div className="dashheaadbtncon">
                    <div className="prolistnavinp ml-3">
                        <img className='image-fluid ' src="/assets/images/search.svg" alt="" />
                        <SearchFormik name="getFavCatelouge" onChange={(values)=>{
                            let search = common.getFavCatelouge();
                            search.page = 1
                            search.searchString = values.searchString
                            const queryString = new URLSearchParams(search).toString();
                            navigate(`/favorite/catalogue?` + queryString)
                        }}  />
                    </div>
                </div>
            </div>
            <div className="storeprocard">
                    <div className="row">
                        {pageData?.list?.map((d,k)=>(
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={k}>
                                <div className="cataloglistcardcon res">
                                    <div className="cataloglistcardimg">
                                        {/* <input type="checkbox" checked={d?.isChecked || false} onChange={()=>handleChecked(d?.isChecked, d?._id)}  /> */}
                                        <img className="img-fluid" src={d?.image?.fullUrl || "/assets/images/backimgbig.svg"} alt="image" />
                                        <div className='wishlistImg'>
                                        <WishlistBtn 
                                            addUrl={`/c/productsCatalogue/favourite/${d?._id}`}
                                            removeUrl={`/c/productsCatalogue/removeFavourite/${d?._id}`}
                                            productData={d} 
                                            isDisabled={((employData?.role ==='sm' && employData?.role !== 'rm') || employData?.role === 'dr' || employData?.role === undefined)}
                                            action={()=>{
                                                getPageData(true)
                                            }}
                                        />
                                        </div>
                                    </div>
                                    
                                    <div className="cataloglisttitle">{d?.title}</div>
                                    <div className="cataloglisttitlecon prolistnavdelete">
                                        <a href={d?.link} target="_blank"><img className="img-fluid" src={`/assets/images/${d?.type}.svg`} alt="image" /></a>
                                        <div className="favcatasaletxt"> {d?.userId?.companyName}</div>
                                        {/* <ConfirmModal 
                                            title="Are you sure ?"
                                            method="POST"
                                            postData={{
                                                "catalogueIds": [`${d?._id}`]
                                            }}
                                            renderHtml={<img className='img-fluid' src="/assets/images/delete.svg" alt="image" />} 
                                            url={`/v/productCatalogue/delete`} 
                                            action={()=>getPageData()} 
                                            submitBtnName="Confirm"
                                        /> */}
                                        {/* <a><img className="img-fluid" src="/assets/images/delete.svg" alt="image" /></a> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-3">
                            <Link to="/vendor/product/view/:productId"> <div className="aboutproimgwrp">
                                <div className="aboutproimgcon store mb-1">
                                    <img className='image-fluid storebigimg' src="assets/images/backimgbig.svg" alt="image" />
                                    <div className="favcataimg">
                                        <img className='image-fluid storeproimgps' src="assets/images/favgreen.svg" alt="image" />
                                    </div>
                                </div>
                                <div className="storeproheadtxt">Product Name</div>
                                <div className="storebtn">
                                    <img className='image-fluid' src="assets/images/crosspdf.svg" alt="image" />
                                    <div className="favcatasaletxt"> Saledesk PVT. <br /> LTD </div>
                                </div>
                            </div>
                            </Link>
                        </div> */}
                    </div>

                {pageData?.totalItemsCount > 12 && (
                        <div className="pagienwrparp">
                            <PageModule
                                totalItems={pageData?.totalItemsCount}
                                itemsPerPage={pageData?.itemsCountPerPage}
                                currentPage={pageData?.activePage}
                                range={3}
                                theme="paging-4"
                                pageChange={(page) => {
                                    pageHasChanged(page);
                                }}
                            />
                        </div>
                    )}
            </div>
        </>
    )
}
