import React, { useEffect, useState } from 'react'
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';

const ImageDownload = ({id, className}) => {
    const [ image, setImage] = useState("")
    const getImage = (id)=>{
        UnauthAxios({
            method: "GET",
            url: `/imageBucket/download/${id}`,
        }).then((res) => {
            setImage(res?.data?.data || {})
        }).catch((error) => {
            console.log(error)
            // common.error(error)
        });
    }
    useEffect(() => {
        id && getImage(id)
    }, [id]);
  return (
    <>
        {image?.fullUrl && (
            <img className={`img-fluid ${className || ''}`} src={image?.fullUrl} alt={image?.name} />
        )}
    </>
  )
}

export default ImageDownload