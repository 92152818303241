import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import DateFormate from '../components/common/dateFormate';
import PageModule from '../components/pagination/pagination';
import authAxios from '../services/authAxios';
import common from '../services/common';

export default function ReviewRating() {
    const {ratingId} = useParams()
    let navigate= useNavigate();
    const history = useLocation()
    const commonData = common.getReviewRating()
    const [pageData, setPageData] = useState({
        list: [],
        activePage: parseInt(commonData?.page) || 1,
        itemsCountPerPage: 10,
        totalItemsCount: 0,
    });
    let postData = {
        page: "1",
        perPage: "10",
        searchString: ""
    }
    const getPageData = async() => {
        postData.page = commonData?.page || 1
        postData.searchString = commonData?.searchString || ''

        common.loader(true);
        await authAxios({
            method: "POST",
            url: `/c/products/ratingReview/pagin/${ratingId}`,
            data: postData
        }).then((res)=>{
            let resData = res?.data?.data
            setPageData({
                ...pageData,
                list:resData,
                activePage:Number(commonData?.page),
                totalItemsCount:res?.data?.totalCount
            })
        }).catch((error) => {
            common.error(error);
        })
        common.loader(false);
    }
    
    function pageHasChanged(pageNumber) {
        if (pageNumber !== pageData.activePage) {
            setPageData({
            ...pageData,
            activePage: pageNumber,
            list: [],
          });
          let search = common.getActiveFilter();
          search.page = pageNumber
          const queryString = new URLSearchParams(search).toString();
          navigate(`/review-rating/${ratingId}?${queryString}`);
        }
    }
    useEffect(()=>{
        getPageData()
    },[history])
    return (
        <>
            <div className="dashmainWRP">
                <div className="dashheadercon">
                    <div className="dashheadtext "><h1>Reviews & Rating</h1>  </div>
                </div>
                <div className="ratingwrp ratingbore">
                    {pageData?.list?.map((d,k)=>(
                         <div className="ratingcon " key={k}>
                            <div className="ratimgimgwrp d-flex">
                                <div className="ratimgimgcon">
                                    <div className="ratinViewImg">
                                        <img className='image-fluid' src={d?.user?.profileImage[0]?.fullUrl || '/assets/images/default_user.jpg'} alt="image" />
                                    </div>
                                    <div className="ratingimgheadcon revieRatinBx">
                                        <div className='ratincontbx'>
                                            <Link to="/profile">  <div className="ratingimghead"><h2>{d?.user?.contactName}</h2></div></Link>
                                            <div className="ratingimgpara">
                                                <DateFormate>{d?.createdAt}</DateFormate>
                                            </div>
                                        </div>
                                        <div className="ratingstar">{d?.rating} <img className='image-fluid' src="/assets/images/singlestar.svg" alt="image" /></div>
                                    </div>
                                </div>                                
                            </div>
                            <div className="ratingimgpara p">{d?.review}</div>
                        </div>   
                    ))}
                    

                </div>
                {pageData?.totalItemsCount > 10 && (
                    <div className="pagienwrparp">
                        <PageModule
                            totalItems={pageData?.totalItemsCount}
                            itemsPerPage={pageData?.itemsCountPerPage}
                            currentPage={pageData?.activePage}
                            range={3}
                            theme="paging-4"
                            pageChange={(page) => {
                                pageHasChanged(page);
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
