import React, { useState } from 'react'
import * as Yup from "yup"
import { useFormik } from 'formik';
import { ErrorMessage } from '../../components/formik/errorMessage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import common from '../../services/common';
import UnauthAxios from '../../services/unauthAxios';
import authAxios from '../../services/authAxios';
import ReactInputMask from 'react-input-mask';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);


export default function FavFilters({navigatePath, resetPath, maxPrice}) {
    // const [startDate, setStartDate] = useState();
    // const [endDate, setEndDate] = useState();
    const [filterApplied, setIsFilterApplied] = useState(false);
    const [filter, setFilter] = useState(false)
    const [resDataNOB, setResDataNOB] = useState([])
    const [resDataCity, setResDataCity] = useState([])
    const [resDataPC, setResDataPC] = useState([])
    const navigate = useNavigate()
    const history = useLocation()
    const [priceMax, setPriceMax] = useState(0)

    useEffect(()=>{
        let prc = Number(maxPrice) > 0 ? Number(maxPrice) : 100000
        // console.log(prc);
        setPriceMax(prc)
   },[maxPrice])

    const formik = useFormik({
        initialValues: {
            primaryCategory: "",
            typeOfAvailability: "",
            minOrderQt: "",
            priceRange: [0, 100000],

        },
        validationSchema: Yup.object({
            
        }),

        onSubmit: values => {
            let min = values?.priceRange[0]
            let max = values?.priceRange[1]
            let urlValues = common.getActiveFilter();
            urlValues.page = 1
            urlValues.primaryCategory = values.primaryCategory  || ''
            urlValues.typeOfAvailability = values.typeOfAvailability  || ''
            urlValues.minOrderQt = values.minOrderQt  || ''
            urlValues.priceRange = min + '-'+ max  || ''
            const queryString = new URLSearchParams(urlValues).toString();
            setFilter(false)
            navigate(`/${navigatePath}?` + queryString)
            if(urlValues?.primaryCategory || urlValues?.typeOfAvailability || urlValues?.minOrderQt){
                setIsFilterApplied(true)
            }else{
                setIsFilterApplied(false)
            }
        },
    });

    function loadNatureOfBusiness() {
        UnauthAxios({
            method: "GET",
            url: `/c/dropdowns/natureOfBusiness`,
        }).then((res) => {
            setResDataNOB(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    function loadPrimaryCategory() {
        authAxios({
            method: "GET",
            url: `/c/dropdowns/primaryCategory`,
        }).then((res) => {
            setResDataPC(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    function loadCity() {
        authAxios({
            method: "GET",
            url: `/c/manageVendors/city`,
        }).then((res) => {
            setResDataCity(res?.data?.data)
        }).catch((error) => {
            common.error(error)
        });
    }

    useEffect(()=>{
        let values = common.getProductFilter();
        const params = new URLSearchParams(window.location.search);
        let primaryCategory = params.get("primaryCategory");
        formik.setFieldValue('primaryCategory', primaryCategory || '')
        formik.setFieldValue('typeOfAvailability', values?.typeOfAvailability || '')
        formik.setFieldValue('minOrderQt', values?.minOrderQt || '')
        formik.setFieldValue('priceRange', values?.priceRange || '')
        let priceRange = values?.priceRange?.split('-')
        if(priceRange?.length <= 1){
            priceRange =[0,priceMax]
        }else{
            priceRange = priceRange?.map((d)=>Number(d))
            priceRange[1] = priceRange[1] <= priceMax ? priceRange[1] : priceMax
        }
        if(primaryCategory || values?.typeOfAvailability || values?.brandName || values?.minOrderQt || (priceRange[0] > 0) || (priceRange[1] < priceMax) ){
            setIsFilterApplied(true)
        }else{
            setIsFilterApplied(false)
        }
        formik.setFieldValue('priceRange',  priceRange )
	},[history, priceMax])
    
    useEffect(()=>{
        loadPrimaryCategory();
	},[])


    return (
        <>
            <div className="filterwrpprolist">
                <ul>
                    <li className={filter  ? "active" : ""}>
                    <div onClick={() =>setFilter(false)} className='filtebkbox'></div>
                    <div className="prolistnav ml-lg-3 ml-md-2 ml-sm-2 ml-2" onClick={() => setFilter(!filter)}>
                        {filter ? (
                            <img  className=' img-fluid ' src="/assets/images/listmenu.svg" alt="image" />
                        ): (
                            <img  className=' img-fluid ' src="/assets/images/filterchange.svg" alt="image" />
                        )}  
                        {filterApplied && <span className={`${filterApplied ? 'reddot':''}`}></span>}
                    </div>
                    
                    <div className='filterprolist'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="categorycmodelhead">Filter</div>
                                <div className="loginsinphone catemodal">
                                    
                                    {/* <div className="filtercategory mb-3">
                                        <select {...formik.getFieldProps("primaryCategory")} className="form-select" aria-label="Default select example">
                                            <option value="">Select Primary Category</option>
                                            {resDataPC?.map((d, k) => (
                                                <option value={d?._id} key={k}>{d?.title}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage formik={formik} name="primaryCategory" />
                                    </div> */}
                                    <div className="filtercategory mb-3">
                                        <select    {...formik.getFieldProps("typeOfAvailability")} className="form-select" aria-label="Default select example">
                                            <option value="">Select Type of Product Availiblity </option>
                                            <option value="onDemand">On Demand Manufacturing</option>
                                            <option value="ready">Readily Stock</option>
                                        </select>
                                        <ErrorMessage formik={formik} name="typeOfAvailability" />
                                    </div>
                                    <div className="filtercategory mb-3">
                                        <ReactInputMask mask="9999999999" maskChar={null} {...formik.getFieldProps("minOrderQt")} type="text" className="form-control" placeholder="Min. Order Quantity" />
                                        <ErrorMessage formik={formik} name="minOrderQt" />
                                    </div>
                                    <div className="filtercategory mb-5">
                                        <div className="prorangetext">Price Range</div>
                                        <div className='priceRangeWrp'>
                                            <div className='rangeBoxwrp'>
                                                <div className='rangeInput'>
                                                    <input type="text" readOnly value={`₹ ${formik.values.priceRange[0]}`} />
                                                </div>
                                                <div className='rangeInput'>
                                                    <input type="text" readOnly value={`₹ ${formik.values.priceRange[1]}`} />
                                                </div>
                                            </div>
                                            <Range
                                                min={0}
                                                max={priceMax}
                                                // step={formik.values.priceRange[1] > 1000 ? parseInt(formik.values.priceRange[1]/100): 10}
                                                value={formik.values.priceRange}
                                                // tipFormatter={(value) => `${value}!`}
                                                onChange={(e)=>formik.setFieldValue('priceRange', e || [0, priceMax])}
                                            />
                                            <ErrorMessage formik={formik} name="priceRange" />
                                        </div>
                                    </div>


                                </div>
                                <div className="filterprolistbtncon">
                                    <div className="loginsignbtn filterreset"><button type='button' className='theme-btn-1'
                                            onClick={()=>{
                                                setIsFilterApplied(false)
                                                navigate(resetPath);
                                                setFilter(false)
                                            }}
                                        >Reset</button></div>
                                    <div className="loginsignbtn filterreset"><button type='submit' className='theme-btn-1'>Apply</button></div>
                                </div>

                            </form>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
