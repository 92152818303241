import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../context/theme";
import authAxios from "../../services/authAxios";
import common from "../../services/common";
import ImageDownload from "../common/ImageDownload";

const StoreBox = ({action}) => {
    const {vendorId} = useParams()
    const [userInfo, setUserInfo] = useState([])
    const [storeData, setStoreData] = useState([])
    const [counts, setCounts] = useState({})
    const {setIsConnected}= useContext(UserContext)

    const getVendorInfo = async () => {
		await authAxios({
			method: "GET",
			url: `/c/manageVendors/vendorProfile/${vendorId}`
		}).then((res) => {
			setUserInfo(res?.data?.data || [])
			setStoreData(res?.data?.data?.storeId || {})
            setIsConnected(res?.data?.data?.isConnected || false)
            if(action){
                action(res?.data?.data?.storeId?.brands)
            }
		}).catch((error) => {
			common.error(error)
		});
	}
    const productCount = async () => {
		await authAxios({
			method: "GET",
			url: `/c/manageVendors/productCount/${vendorId}`
		}).then((res) => {
			setCounts(res?.data?.data || [])
		}).catch((error) => {
			common.error(error)
		});
	}

    useEffect(()=>{
        vendorId && productCount()
        vendorId && getVendorInfo()
    },[vendorId])
    // console.log(storeData);
    // console.log(userInfo);
	
    return (
        <>
            <div className="ratingwrp">
                    <div className="ratingcon store">
                        <div className="ratimgimgwrp store  d-flex">
                            <div className="ratimgimgcon productImgRatingBX">  
                            <div className="producImgContImg">
                                {userInfo?.profileImage?.fullUrl ? (
                                    <img src={`${userInfo?.profileImage?.fullUrl}`} className="img-fluid" />
                                ) : (
                                    <div className="dashprofiletext profile prodectListFile">{common.getFirstLetter(userInfo?.contactName)}</div>
                                )}
                                    {/* <img className='image-fluid mr-3' src="assets/images/imgbackground.svg" alt="image" /> */}
                            </div>                       
                                <div className="ratingimgheadcon productRatinList">
                                    <div className="storeprontxt ">{userInfo?.natureOfBusiness?.title}</div>
                                    <div className="ratingimghead"><Link to={`/vendor/view/${userInfo?._id}`}>{userInfo?.companyName}</Link></div>
                                    <div className="ratingimgpara mb-1">{storeData?.address}</div>
                                    <div className="ratingimgpara">{userInfo?.primaryCategory?.map((d)=>d?.title)?.join(" | ")}
                                    </div>
                                </div>                                
                              </div> 
                                <div className="storeproratecon">
                                    <div className="storeprocountcon mb-2">
                                        <div className="storeprocount">{counts?.productCount || 0} Product </div>
                                        <div className="storeprocount">{counts?.productCatalogueCount || 0} Catalouge</div>
                                    </div>
                                    <div className="storeprologo stropordeRetibx mb-2">
                                        {storeData?.brands?.map((d,k)=>(
                                            d?.brandLogo &&
                                            // <img className='image-fluid mr-3' key={k} src={d?.brandLogo?.fullUrl ||"/assets/images/default_img.jpg"} alt="image" />
                                            <ImageDownload id={d?.brandLogo} key={k} className="image-fluid" />
                                        ))}
                                        {/* <img className='image-fluid mr-3' src="/assets/images/nike.svg" alt="image" />
                                        <img className='image-fluid mr-3' src="/assets/images/adidas.svg" alt="image" />
                                        <img className='image-fluid ' src="/assets/images/imgtiger.svg" alt="image" /> */}
                                    </div>
                                    {userInfo?.totalReviews > 0 ? (
                                        <Link to={`/review-rating/${vendorId}`}> <div className="dashprofilerating mb-0"><img src="/assets/images/singlestar.svg" alt="img" /> {userInfo?.averageRatings} <span>{userInfo?.totalReviews} Reviews</span> </div></Link>
                                    ): (
                                        <div className="dashprofilerating mb-0"> No Reviews</div>
                                    )}
                                    
                                </div>
                           
                           
                        </div>
                    </div>

                </div>
        </>
    );
};

export default StoreBox;
