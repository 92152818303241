import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Scrollbars } from 'react-custom-scrollbars';
import common from '../../services/common';
import authAxios from '../../services/authAxios';
import { useContext } from 'react';
import { UserContext } from '../../context/theme';


ChartJS.register(ArcElement, Tooltip, Legend);



function Piechart({heading, route, type, total}) {
    const { counts } = useContext(UserContext)
    const [resData, setResData] = useState([])
    const [isLoading, setIsLoading] = useState(true)    
    const [scrollHeight, setScrollHeight] = useState(260)
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: '# of Votes',
                data: [],                
                backgroundColor: [
                    '#3C8D66',
                    '#97EFC4',
                    '#70C79D',
                    '#54A980',
                    '#27704D',
                ],
                borderColor: [
                    '#27704D',
                    '#27704D',
                    '#27704D',
                    '#27704D',
                    '#27704D',
                ],
                borderWidth: 1,
            },
        ],
    })
    async function getData() {
        common.loader(true);
        await authAxios({
            method: "GET",
            url:route ,
        }).then((res) => {
            let resData = res?.data?.data
            setResData(resData || [])
            let topFive = resData?.filter((d)=>d._id !== null)?.slice(0, 5);
            let cData = chartData
            if(type == 'category'){
                cData.labels = topFive?.map((d)=>d?.vendor?.contactName)
                cData.datasets[0].data = topFive?.map((d)=>d.productsCount)
            }else{
                cData.labels = topFive?.map((d)=>d._id)
                cData.datasets[0].data = topFive?.map((d)=>d.count)
            }
            setChartData(cData)
            setIsLoading(false)
            setIsLoading(false)
            if(resData?.length <= 5 && window.innerWidth <= 767){
                setScrollHeight(52 * resData?.length)
            }else{
                setScrollHeight(260)
            }
        }).catch((error) => {
            console.log(error);
            // common.error(error)
        });
        common.loader(false);
    }
    useEffect(() => {
        route && getData()
    }, [route]);
    
// v/reportsAndAnalytics/customersOfTopCities
    return <>
       
                <div className="piewrpmain">
                    <div className="piewrp">
                        <div className="pieheadcon">
                            <div className="piecustxt">
                                {heading} <br /> 
                                {type !== 'category' ? (
                                    <span>({chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}/{counts?.activeVendorsCount})</span>
                                ):(
                                    <span>({chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)}/{counts?.favouriteProductsCount||0})</span>
                                )}
                            </div>
                        </div>
                        <div className="piewbox1">
                            {isLoading ? 'Loading' : <Pie data={chartData} />}
                        </div>
                    </div>
                    <div className="piestatedatawrp">
                        <Scrollbars style={{ height:scrollHeight }}>
                            {resData?.map((d,k)=>(
                                type == 'category' ? (
                                    <div className="piestatedatacon" key={k}>
                                        <div className="piestatetxt">{d?.vendor?.contactName}</div>
                                        <div className="piestatecount">{d?.productsCount}</div>
                                    </div>
                                ):(
                                    <div className="piestatedatacon" key={k}>
                                        <div className="piestatetxt">{d?._id}</div>
                                        <div className="piestatecount">{d?.count}</div>
                                    </div>
                                )
                                
                            ))}
                        </Scrollbars>
                    </div>
                </div>
            </>
}

export default Piechart