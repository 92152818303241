import React from 'react'
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/theme';
import authAxios from '../../services/authAxios';
import common from '../../services/common';

const CartBtn = ({action, renderHtml, productData, isDisabled}) => {
  const {getCart} = useContext(UserContext);
  const addToCart = async (id) => {
    if(isDisabled){
    common.loader(true)
		await authAxios({
			method: "GET",
			url: `/c/cart/addToCart/${id}`
		}).then((res) => {
			toast.success(res?.data?.message || [])
      getCart()
      if(action){
        action()
      }
		}).catch((error) => {
			common.error(error)
		});
    common.loader(false)
  }
	}
  const removeToCart = async (id) => {
    if(isDisabled){
      common.loader(true)
      await authAxios({
        method: "GET",
        url: `/c/cart/removeFromCart/${id}`
      }).then((res) => {
        toast.success(res?.data?.message || [])
        getCart()
        if(action){
          action()
        }
      }).catch((error) => {
        common.error(error)
      });
      common.loader(false)
    }
	}
  return (
    <>
    <div className='cardImg'>
        {renderHtml ? (
          productData?.addedToCart ? (
            <span onClick={()=>removeToCart(productData?._id)} className={`${!isDisabled ? 'disabled' : ''}`}>{renderHtml}</span>
          ) : (
            <span onClick={()=>addToCart(productData?._id)} className={`${!isDisabled ? 'disabled' : ''}`}>{renderHtml}</span>
          )
            
        ):(
          productData?.addedToCart ? (
            <img className='image-fluid' onClick={()=>removeToCart(productData?._id)} src={`/assets/images/${isDisabled?'greencart.svg':'disable.cart.svg'}`} alt="image" />
          ) : (
            <img className='image-fluid' onClick={()=>addToCart(productData?._id)} src={`/assets/images/${isDisabled?'cartwhite.svg':'disable.cart.svg'}`} alt="image" />
          )
            
        )}
      </div>
        {/* <img className='image-fluid' src="/assets/images/greencart.svg" alt="image" /> */}
    </>
  )
}

export default CartBtn