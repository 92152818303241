import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterUnAuth() {
    return (
        <>
            <footer className='footerwrp'>
                <div className="container-fluid">
                    <div className="footerwrpcon">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="footerlogo">
                                    <img className='img-fluid' src="/assets/images/img1.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-xl-3 col-md-12 col-sm-12'>
                                <div className="footerUlwrp">
                                    <ul className='footerulFrt'>
                                        <li className='footerheadtxt'>About Us</li>
                                        <li><p className='m-0'>We are a group of committed 
                                            individuals with a mission to 
                                            build innovative solutions to 
                                            solve B2B commerce problems. 
                                            </p></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-xl-9 col-md-12 col-sm-12'>
                                <div className="footerUlwrp">
                                    <ul>
                                        <li className='footerheadtxt'>Contact Us</li>
                                        <li><h6>For general query</h6>
                                            <a href="mailto:contact@saledesk.io">
                                            {/* <span><img className='img-fluid' src="/assets/images/img5.svg" alt="" /></span> */}
                                            <p>contact@saledesk.io</p></a ></li>
                                        <li><h6>For customer query</h6>
                                            <a href="mailto:customer@saledesk.io">
                                            {/* <span><img className='ftrimg img-fluid' src="/assets/images/img6.svg" alt="" /></span> */}
                                            <p>customer@saledesk.io</p></a ></li>
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt'>Information</li>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        {/* <li><Link target="_blank" to={process.env.REACT_APP_REDIRECT_URL} >Become a Buyer</Link></li> */}
                                        <li><a target="_blank" href={process.env.REACT_APP_REDIRECT_URL} >Become a Seller</a></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt'>Helpful Link’s</li>
                                        <li><Link to="/faqs">FAQ’s</Link></li>
                                        <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                        <li><Link to="privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt follow'>Follow Us :</li>
                                        <div className="followUsimg">
                                            <li><a href="https://www.linkedin.com/company/saledeskindia" target="_blank"> <img className='img-fluid' src="/assets/images/img7.svg" alt="image" /> </a></li>
                                            <li><a href="https://www.instagram.com/saledesk.io/" target="_blank"><img className='img-fluid' src="/assets/images/img2.svg" alt="image" /></a></li>
                                            <li><a href="https://www.facebook.com/saledeskindia" target="_blank" ><img className='img-fluid' src="/assets/images/img3.svg" alt="image" /></a></li>
                                            {/* <li><a href="https://www.pinterest.com" target="_blank"><img className='img-fluid' src="/assets/images/img4.svg" alt="image" /></a></li> */}
                                            <li><a href="https://www.youtube.com/@saledesk" target="_blank"><img className='img-fluid' src="/assets/images/youtube.png" alt="image" /></a></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footercpcon">©Copyright Saledesk</div>
            </footer>
        </>
    )
}
