import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import common from '../../services/common';

export default function FilterNewOldCheck({name, navigatePath, resetPath}) {
    const navigate = useNavigate()
    let urlValues = common?.[name]();
    const [active, setActive] = useState(false)


    const sortingFilter = (v) => {
        // console.log(v)
        urlValues.page = 1
        urlValues.sorting = v  || ''
        const queryString = new URLSearchParams(urlValues).toString();
        setActive(false)
        navigate(`/${navigatePath}?` + queryString)
    }
    return (
        <>
            <div className="filterwrpprolist">
                <ul>
                    <li className={active ? "active" : ""}>
                        <div className='filtebkbox' onClick={() => setActive(!active)}></div>
                        <div className="prolistnav ml-lg-3 ml-md-2 ml-sm-2 ml-2">
                            <img  onClick={() => setActive(!active)}  className=' img-fluid slectimg' src="/assets/images/updown.svg" alt="image" />
                            <img onClick={() => setActive(!active)}  className=' img-fluid unslectimg' src="/assets/images/unsalect.svg" alt="image" />                                                  
                        </div>
                        <div className='filterprolist catalogue'>
                            <div className='productcheckcon vegProduct catalogue'>
                                <div className="form-check mb-2">
                                    <label className="formGrouplist form-check-label" >
                                        <input className=" form-check-input" type="radio" name="flexRadioDefault" value="new" checked={urlValues?.sorting == 'new'}  onChange={(e)=>sortingFilter(e?.target?.value)} />
                                        <span> Newest </span>
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <label className="formGrouplist form-check-label" >
                                        <input className=" form-check-input" type="radio" name="flexRadioDefault" value="old" checked={urlValues?.sorting == 'old'}  onChange={(e)=>sortingFilter(e?.target?.value)} />
                                        <span> Oldest  </span>
                                    </label>
                                </div>
                                <div className="form-check mb-2">
                                    <label className="formGrouplist form-check-label" >
                                        <input className=" form-check-input" type="radio" name="flexRadioDefault" value="low" checked={urlValues?.sorting == 'low'}  onChange={(e)=>sortingFilter(e?.target?.value)} />
                                        <span> Rating (Low to High)  </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="formGrouplist form-check-label" >
                                        <input className=" form-check-input" type="radio" name="flexRadioDefault" value="high" checked={urlValues?.sorting == 'high'}  onChange={(e)=>sortingFilter(e?.target?.value)} />
                                        <span> Rating (High to Low)  </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
