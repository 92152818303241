import React from 'react'
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';

const WareHouseAddress = ({data}) => {
    const [open, setOpen] = useState(false);
  return (
    <>
            <div className="row">
                {data?.map((d,k)=>(
                    k === 0 && (
                        <div className="col-lg-12" key={k}>
                            <div className="viewstoretabwrp mt-3">
                                <div className="cusnamehead cusname headBxTx mt-3 mb-2">Warehouse/ Factory Addresses </div>
                                <div className="cuspromoterlist addBoxMobl w-100">
                                    <div className="cusnamehead">Address {k+1}</div>
                                    <span >:</span>
                                    <div className="cusnamehead cusname w-100">{d || 'N/A'}</div>
                                </div>
                            </div>
                        </div>
                    )
                ))}
                {data?.length > 1 && (
                    <Collapse in={open}>
                        <div>
                            {data?.map((d,k)=>(
                                k > 0 && (
                                    <div className="col-lg-12" key={k}>
                                        <div className="viewstoretabwrp">
                                            <div className="cuspromoterlist addBoxMobl w-100">
                                                <div className="cusnamehead">Address {k+1}</div>
                                                <span >:</span>
                                                <div className="cusnamehead cusname w-100">{d || 'N/A'}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </Collapse>
                )}
                
                {!open && data?.length > 1 && (
                    <div className="col-lg-12">
                        <div className="blurarrowcon mt-3">  <img className='img-fluid' src="/assets/images/blurdownarrow.svg" alt="image" onClick={() => setOpen(!open)} /></div>
                    </div>
                )}
                {open && data?.length > 1 && (
                    <div className="col-lg-12">
                        <div className="blurarrowcon rotatimg mt-3">  <img className='img-fluid' src="/assets/images/blurdownarrow.svg" alt="image" onClick={() => setOpen(!open)} /></div>
                    </div>
                )}
            </div>
    </>
  )
}

export default WareHouseAddress